import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const MessagesComponent = ({ message }) => (
  <Grid container className="message__container" alignItems="center" justify="center">
    <Grid item xs={12} md={12}>{message}</Grid>
  </Grid>
);

MessagesComponent.propTypes = {
  message: PropTypes.string,
};

MessagesComponent.defaultProps = {
  message: '',
};

export default MessagesComponent;