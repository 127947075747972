import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { ExportToCsv } from 'export-to-csv';
import Button from '../../Inputs/Button';

const DownloadCsvComponent = (props) => {
  const { answers, name } = props;

  const handlerDownloadCsv = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: false,
      showTitle: true,
      title: name,
      useTextFile: false,
      useBom: true,
      filename: name,
    };

    const data = answers.map(answer => ({
      [name]: answer,
    }));
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  return (
    <Box mt={3} mb={3}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={handlerDownloadCsv}
      >
        Download Answers
      </Button>
    </Box>
  );
};

DownloadCsvComponent.propTypes = {
  answer: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
};

DownloadCsvComponent.defaultProps = {
  answer: [],
};

export default DownloadCsvComponent;

