import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ResponsivePie } from '@nivo/pie';
// import data from './data';

const PieChartComponent = props => (
  <Fragment>
    <div className="pie__container">
      <ResponsivePie
        data={props.data}
        margin={{
          top: 0,
          right: 0,
          bottom: 0,
          left: 5,
        }}
        sliceLabel={d => `${d.value}${props.unit}`}
        innerRadius={0}
        padAngle={0}
        colors={props.colors}
        cornerRadius={0}
        borderWidth={0}
        borderColor="inherit:darker(0.2)"
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        Pie={{
          from: 'color',
        }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#ffffff"
        animate
        motionStiffness={90}
        motionDamping={15}
        sortByValue
        enableRadialLabels={false}
        /* legends={[
          {
            anchor: 'left',
            direction: 'column',
            translateY: 0,
            translateX: -130,
            itemsSpacing: 2,
            itemWidth: 500,
            itemHeight: 10,
            itemTextColor: '#999',
            symbolSize: 10,
            symbolShape: 'circle',
          },
        ]} */
        fill={[
          {
            match: {
              id: 'python',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'lisp',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'rust',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'stylus',
            },
            id: 'dots',
          },
          {
            match: {
              id: 'make',
            },
            id: 'lines',
          },
        ]}
      />
    </div>
  </Fragment>
);

PieChartComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  unit: PropTypes.string,
};

PieChartComponent.defaultProps = {
  colors: ['#261a7c', '#fdc321', '#f18701', '#1155cc', '#ffd966', '#3c78d8', '#ff9900', '#6d9eeb', '#ffe599', '#f6b26b'],
  unit: '',
};

export default PieChartComponent;
