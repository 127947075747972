import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { Paper, DotsMenu } from '../';
import TypeChart from '../TypeChart';
import configurationKindsCharts from './utils';

const ChartContainer = ({
  dataChart,
  numberCard,
  visualizationOptions,
  taskId,
  colsizes,
  iconDefault,
  isMilliseconds,
}) => {
  const measure = dataChart.result_type;
  let paperRef = null;
  const dataChartName = dataChart.name ? dataChart.name.replace(/\s/g, '').toLowerCase() : 'default';
  const configChart = configurationKindsCharts[dataChartName];
  const configuration = configChart || { colsizes, icon: iconDefault };
  const [colsValues, setColsValues] = useState({ ...configuration.colsize });
  const [widthChart, setWidthChart] = useState(0);
  const [visualization, setVisualization] = useState(dataChart.visualization.toLowerCase());

  const handlerGrid = (col) => {
    switch (col) {
      case 'Default':
        setColsValues({ ...configuration.colsize });
        setVisualization(dataChart.visualization.toLowerCase());
        break;
      case 'Small':
        setColsValues({ xs: 4, sm: 4, md: 4 });
        break;
      case 'Medium':
        setColsValues({ xs: 6, sm: 6, md: 6 });
        break;
      case 'Large':
        setColsValues({ xs: 12, sm: 12, md: 12 });
        break;
      case 'Pie':
        setVisualization('pie');
        break;
      case 'Bar':
        setVisualization('bar');
        break;
      case 'Column':
        setVisualization('column');
        break;
      default:
        setColsValues({ ...configuration.colsize });
        setVisualization(dataChart.visualization.toLowerCase());
    }
  };

  useEffect(() => {
    if (paperRef) setWidthChart(paperRef.clientWidth);
  }, [colsValues]);

  let chartValue = '-1';
  if (dataChart.value) {
    chartValue = dataChart.value.toString();
  }

  return (
    <Grid
      item
      xs={colsValues.xs}
      sm={colsValues.sm}
      md={colsValues.md}
    >
      <Box ref={(ref) => { paperRef = ref; }}>
        <Paper
          simpleTitle={
            !numberCard && chartValue !== '-1' ?
            `${dataChart.name || dataChart.questionTitle}: ${parseFloat(chartValue).toFixed(1)}${measure === 'points' ? 'pts' : '%'}` :
            dataChart.name
          }
          simpleIcon={configuration.icon}
          smallCard={dataChart.visualization === visualization ? numberCard : false}
        >
          {visualizationOptions && visualizationOptions.length > 0 ?
            <Box className="menudot" position="absolute" top={0} right={0} >
              <DotsMenu
                handleMenu={handlerGrid}
                default="Default"
                options={visualizationOptions}
              />
            </Box> : null
          }
          <TypeChart
            taskId={taskId}
            dataChart={dataChart}
            isMilliseconds={isMilliseconds}
            widthChart={widthChart}
            visualization={visualization}
          />
        </Paper>
      </Box>
    </Grid>
  );
};

ChartContainer.propTypes = {
  dataChart: PropTypes.shape({}).isRequired,
  iconDefault: PropTypes.string,
  colsizes: PropTypes.shape({}),
  isMilliseconds: PropTypes.bool,
  nameValue: PropTypes.bool,
  numberCard: PropTypes.bool,
  visualizationOptions: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

ChartContainer.defaultProps = {
  iconDefault: 'person_pin',
  colsizes: { xs: 3, sm: 3, md: 3 },
  isMilliseconds: false,
  nameValue: false,
  numberCard: false,
  visualizationOptions: ['Default', 'Small', 'Medium', 'Large', 'Pie', 'Bar', 'Column'],
};

export default ChartContainer;
