import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Tooltip } from '@material-ui/core';
import Icon from '../../IconComponent';
import { IconStatus } from '../../../utils/common';

const Icons = ({
  isEmpty,
  campaign: {
    platform, tasks, language, country, status,
  },
}) => {
  const getMediaCampaign = () => {
    if (tasks.length === 0) return { mediaIcon: '', mediaName: '' };
    const mediaIcon = tasks[0].task_type.name === 'IMAGES' ? 'photo' : 'play_circle_filled_white';
    const mediaName = `${tasks[0].task_type.name.charAt(0)}${tasks[0].task_type.name.toLowerCase().substr(1)}`;
    return { mediaIcon, mediaName };
  };

  let platformName = '';
  if (platform.id !== '') {
    platformName = platform.id === '1' ? 'Desktop' : 'Mobile Phone';
  }

  return (
    <Box py={2}>
      <Grid container justify="space-between" alignItems="center">
        <Tooltip
          arrow
          title={platformName}
        >
          <Grid item xs={2}>
            <Icon
              isEmpty={isEmpty}
              iconName={platform.id === '1' ? 'desktop_mac' : 'phone_android'}
            />
          </Grid>
        </Tooltip>
        <Tooltip title={getMediaCampaign().mediaName} arrow>
          <Grid item xs={2}>
            <Icon
              isEmpty={isEmpty || tasks.length === 0}
              iconName={getMediaCampaign().mediaIcon}
            />
          </Grid>
        </Tooltip>
        <Tooltip arrow title={language.name} >
          <Grid item xs={2}>
            <Icon
              isEmpty={isEmpty}
              text={language.short.toUpperCase()}
              type="iconText"
            />
          </Grid>
        </Tooltip>
        <Tooltip arrow title={country.name} >
          <Grid item xs={2}>
            <Icon
              isEmpty={isEmpty}
              imageName={country.abbreviation}
              type="iconImage"
            />
          </Grid>
        </Tooltip>
        <Tooltip
          arrow
          title={status.name.toLowerCase().split('_').map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`).join(' ')}
        >
          <Grid item xs={2}>
            <Icon
              isEmpty={isEmpty}
              iconName={IconStatus[status.name] ? IconStatus[status.name].icon : ''}
              color={IconStatus[status.name] ? IconStatus[status.name].color : ''}
            />
          </Grid>
        </Tooltip>
      </Grid>
    </Box>
  );
};

Icons.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  campaign: PropTypes.shape({
    platform: PropTypes.shape({
      id: PropTypes.string,
    }),
    tasks: PropTypes.arrayOf(PropTypes.shape({
      task_type: PropTypes.shape({
        name: PropTypes.string,
      }),
    })),
    language: PropTypes.shape({
      name: PropTypes.string,
      short: PropTypes.string,
    }),
    country: PropTypes.shape({
      name: PropTypes.string,
      abbreviation: PropTypes.string,
    }),
    status: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }),
};

Icons.defaultProps = {
  campaign: {
    platform: '',
    tasks: [],
    language: {
      name: '',
      short: '',
    },
    country: {
      name: '',
      abbreviation: '',
    },
    status: {
      name: '',
    },
  },
};

export default Icons;