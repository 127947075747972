export default {
  translations: {
    notAuthorized: {
      message: 'Este usuario no tiene acceso a esta aplicación.',
      button: 'Regresa a Login',
    },
    mainMenu: {
      titleCampaigns: 'Campaigns',
      titleOrganization: 'Organization',
      titleOrganizationClosed: 'org',
      titleCampaignsClosed: 'camps',
      menuSectionCampaigns: {
        itemOne: 'Manager',
        itemTwo: 'New',
      },
      menuSectionUser: 'Users',
      menuSectionClient: 'Clients',
      menuSectionTags: 'Tags',
      logout: 'Log Out',
    },
    login: {
      btnLogin: 'LOG IN',
      titleWelcome: 'Welcome!',
      subtitleWelcome: 'Enter your username/email address and password to continue to Synapbox platform',
      labelEmail: 'Email address',
      labelPassword: 'Password',
      emailPlaceholder: 'Write an email here',
      passwordPlaceholder: '****',
      titleRightOne: 'Not yet measuring',
      titleRightTwo: 'emotions with Synapbox?',
      subtitleRight: 'Unlock the Power Behind your Customers Smiles',
      lastText: 'LEARN HOW TODAY',
    },
    general: {
      pageName: 'Mi Campaña',
      errorGeneral: 'Error inesperado',
      questionName: 'Pregunta',
      taskName: 'Tarea',
      testing: 'Dispositivo de prueba:',
      buttons: {
        exportQuestions: 'EXPORTAR TODAS LAS PREGUNTAS Y RESPUESTAS',
        next: 'SIGUIENTE:',
        back: 'ATRÁS',
        save: 'GUARDAR',
        backHome: 'VOLVER INICIO',
        select: 'seleccionar',
        edit: 'EDITAR',
        duplicate: 'DUPLICAR',
        delete: 'ELIMINAR',
        addQuestion: 'pregunta',
        add: 'Agregar',
        update: 'Actualizar',
        addElement: 'Agregar elemento',
        addRow: 'Agregar fila',
        addColumn: 'Agregar columna',
        addAnswer: 'Agregar respuesta',
        addOther: 'Agregar la opción OTRO',
        or: 'o',
        textCampaign: 'campaña de prueba',
        deleteCampaign: 'ELIMINAR CAMPAÑA',
        exportCampaing: 'EXPORTAR CAMPAÑA',
        duplicateCampaign: 'DUPLICAR CAMPAÑA',
        updateTask: 'ACTUALIZAR TAREA',
        addTask: 'AGREGAR TAREA',
        launch: 'Lanzar enlace a campo',
        cancel: 'CANCELAR',
        close: 'SALIR',
        modifyRedirects: 'Modificar Redirecciones',
      },
    },
    errorPage: {
      title: '¡Estar perdido no es tan malo!',
      titleHeader: 'Página de error - Codigo de error 404',
      errorTitle: 'CÓDIGO DE ERROR',
      errorCode: '404',
      errorContent: 'La página fue removida, renombrada o no existe.',
      errorBtnBack: 'Volver a mis CAMPAÑAs',
      errorGeneralBtnBack: 'Atrás',
      errorGeneralTitle: 'Disculpa',
      errorGeneralText: 'Parece que algo anda mal.',
    },
    dashboard: {
      title: 'Campaign manager',
      titles: {
        allCampaign: 'Todas las campañas',
        launch: 'Lanzar enlace a campo',
        complete: 'Completar la guía de tareas',
        what: '¿Qué es una campaña?',
        draft: 'COMPLETE CAMPAIGN',
        in_progress: 'TRACK LINK',
        paused: 'TRACK LINK',
        completed: 'SEE RESULTS',
      },
      status: {
        draft: 'Draft',
        in_progress: 'In progress',
        completed: 'Completed',
        paused: 'Paused',
      },
      menu: {
        name: 'campaña',
        create: 'Crear',
        all: 'Todas',
        draft: 'Borradores',
        in_progress: 'En Progreso',
        completed: 'Completadas',
      },
      search: {
        placeholder: 'Buscar',
        button: 'Buscar',
      },
      resultSearch: {
        title: 'Resultados de búsqueda',
        result: 'Tenemos 0 resultados de acuerdo a tu búsqueda',
        message: 'Prueba buscando por tu marca o por el tipo de contenido como videos o imágenes',
      },
      initialView: {
        title: '¡Bienvenid@!',
        message: 'Comencemos creando tu primera campaña',
      },
    },
    createCampaign: {
      title: 'Campaign definition',
      subtitle: 'New campaign / ',
    },
    steps: {
      pageName: 'Mi Campaña',
      step1: {
        breadcrumb: 'Creador de campañas: Paso 1 - Definición de campaña',
        title: '1. Definición de campaña',
      },
      step2: {
        breadcrumb: 'Creador de campaña: Paso 2: Vista previa de la campaña',
        title: '2. Tareas y preguntas',
      },
      step3: {
        breadcrumb: 'Creador de campañas: Paso 3: Lanzamiento de la campaña',
        title: '3. Selección de participantes',
      },
    },
    summary: {
      campaignId: 'ID de campaña:',
      objective: 'Objetivo: ',
      description: 'Descripción:',
      biometrical: 'Tipo de analisis',
      biometricSurvey: 'Biometrico + Survey',
      survey: 'Survey',
    },
    modal: {
      deleteTag: {
        title: 'Información',
        message: 'Seguro que quieres eliminar el tag seleccionado',
      },
      deleteCategoryTag: {
        title: 'Información',
        message: 'Seguro que quieres eliminar la categoria de tag seleccionada',
      },
      deleteTester: {
        title: 'Información',
        message: 'Seguro que quieres eliminar los testers seleccinados',
      },
      closessesion: {
        title: 'Información',
        message: 'Seguro que quieres cerrar sesión',
      },
      strCampaign: {
        title: 'Estás a punto de eliminar la campaña. ¿Seguro que quieres eliminarla?',
        message: 'Toda la información relacionada con esta campaña será eliminada.',
      },
      duplicateCampaign: {
        title: 'Estás a punto de duplicar la campaña. ¿Seguro que quieres duplicarla?',
        message: 'Toda la información relacionada con esta campaña será duplicada.',
      },
      strQuestion: {
        title: 'Estás a punto de eliminar una pregunta ¿Seguro que quieres eliminarla?',
        message: 'Toda la información relacionada con esta pregunta será eliminada.',
      },
      strTask: {
        title: 'Estás a punto de eliminar una tarea ¿Seguro que quieres eliminarla?',
        message: 'Toda la información relacionada con esta tarea será eliminada.',
        titleDuplicate: 'Estás a punto de duplicar una tarea ¿Seguro que quieres Duplicarla?',
        messageDuplicate: 'Toda la información relacionada con esta tarea será copiara.',
      },
      buttons: {
        deleteQuestion: 'ELIMINAR PREGUNTA',
        deleteTask: 'ELIMINAR TAREA',
        duplicateTask: 'DUPLICAR TAREA',
      },
      closeCampaign: {
        title: 'Estas a punto de cerrar esta campaña',
        message: 'Estas seguro de hacerlo',
      },
    },
    campaignDefinition: {
      sectionCampaingGeneral: '1. Asigna un nombre y describe tu campaña',
      sectionCampaingDevice: '2. Selecciona el dispositivo que se usará para aplicar el estudio',
      sectionCampaingLanguages: '3. Selecciona el lenguaje del estudio',
      sectionCampaingOptions: '4. Selecciona el análisis que quieres recibir',
      sectionQuota: '5. Selecciona los participantes mínimos por estímulo',
      title: 'Título de la campaña',
      objective: 'Objetivo(s)',
      description: 'Descripción',
      buttons: {
        nextTitle: 'Tareas y Preguntas',
      },
    },
    campaignPreview: {
      title: 'Setup',
      subtittle: 'Editing campaign / ',
      typeQuestion: 'pregunta',
      typeTask: 'tarea',
      panels: {
        first: '1. Crea la primera tarea o pregunta para tu campaña',
        notQuestion: 'Aún no se ha creado ninguna pregunta',
        profile: {
          title: 'Preguntas de Perfil',
        },
        task: {
          title: 'Tareas y Preguntas',
          notTask: 'Aún no se ha creado ninguna tarea',
          notQuestion: 'Aún no se ha creado ninguna pregunta',
        },
        exit: {
          title: 'Preguntas de Salida',
        },
      },
      buttons: {
        createTask: 'CREAR TAREA',
        createQuestion: 'CREAR PREGUNTA',
        nextTitle: 'Selección de participantes',
        addProfile: 'Agregar pregunta de perfil',
        addTask: 'Agregar tarea',
        addExit: 'Agregar pregunta de salida',
        addQuestion: 'Agregar pregunta',
      },
      btnYes: 'SI',
      btnNo: 'NO',
      labelRandom: 'Ver las tareas aleatorias',
    },
    campaingLaunch: {
      title: '¡Ingresa la información de los participantes y después estaremos listos para probar tu campaña!',
      panels: {
        participants: {
          label: 'Participipantes mínimos por estímulo',
          tooltip: '*A partir de 5.',
        },
        panel: {
          title: '6. Seleccione el panel que desea usar',
          organic: 'Invitación orgánica',
        },
      },
      countries: {
        title: '2. Seleccione el pais que desea usar',
        placeholder: 'Selección de país',
        noSelected: 'Seleccione un país',
        currentLiving: 'Actualmente viven en ',
        total: 'Total',
        participants: 'Participantes',
      },
      clients: {
        title: '1. Seleccione el cliente que desea usar',
        placeholder: 'Selección de cliente',
        noSelected: 'Seleccione un cliente',
      },
    },
    questionnaires: {
      title: 'Pregunta a la campaña',
      panels: {
        question: {
          title: '1. Define tu pregunta',
          label: 'Pregunta',
          rational: 'Rational metric',
        },
        answer: {
          title: '2. Define el tipo de pregunta y respuestas',
          label: 'Tipo de pregunta',
        },
      },
      menu: {
        open: 'Open',
        openSubtitle: 'Textbox',
        multiOption: 'Multiple Option',
        multiOptionSubtitle: 'Radio or checkbox',
        scale: 'Rating scale',
        scaleSubtitle: 'Dragable slider along a range',
        matrix: 'Radio button grid',
        matrixSubtitle: 'Select one option for each row',
        importance: 'Importance',
        importanceSubtitle: 'Drag & drop to rank a list of items',
      },
      titles: {
        create: 'Create Question',
        edit: 'Edit Question',
      },
    },
    answers: {
      answer: 'Respuesta',
      open: {
        short: 'Respuesta corta Max. 140 caracteres',
        long: 'Respuesta larga Max. 280 caracteres',
        messageValidation: 'Para continuar deberias seleccionar una opción',
      },
      multiple: {
        mustTitle: '  Requerido',
        terminateTitle: 'Terminal',
        placeholder: 'Escribe la respuesta...',
        placeholderQuota: 'Cuota',
        messageValidation: 'Para continuar debes adicionar al menos dos respuestas',
        logicTitle: 'Lógica de respuestas',
        loadFile: 'CARGAR IMAGEN',
        uploadFile: 'SUBIR IMAGEN',
        uploadFilePlaceHolder: 'Selecciona una imagen…',
        answerSimple: 'One Answer',
        answerMultiple: 'Multiple Answer',
        none: 'None',
        must: 'Must',
        terminate: 'Terminate',
      },
      strScale: {
        label: 'Rango de la escala',
        from: 'desde',
        to: 'hasta',
        placeholder: 'Agregar etiqueta...',
        messageValidation: 'Para continuar debes agregar un número valido mayor que cero, menor que 10 y diferencia de 2 en el rango desde - hasta.',
      },
      strMatrix: {
        label: 'Establece las filas y columnas',
        rows: 'FILAS',
        columns: 'COLUMNAS',
        row: 'FILA',
        column: 'COL',
        placeholder: 'Agregar etiqueta...',
        messageValidation: 'Para continuar debes adicionar al menos dos columnas y dos filas',
      },
      strImportance: {
        element: 'Elemento',
        title: 'Ordenar elementos',
        label: 'Establece las opciones',
        placeholder: 'Agregar etiqueta...',
        messageValidation: 'Para continuar debes adicionar al menos dos elementos para ordenar',
      },
    },
    tasks: {
      bar: {
        page: 'Mis Campañas',
        breadcrumb: {
          first: 'Creador de campañas',
          second: 'Creador de tareas',
        },
      },
      menu: {
        image: 'Imágenes',
        video: 'Videos',
        survey: 'Survey',
        web: 'Pagina Web',
        mobile: 'Prototipo Web de Aplicación Móvil',
      },
      panels: {
        conent: {
          title: '1. Selecciona el tipo de contenido que deseas probar en esta tarea',
        },
        images: {
          title: '2. Carga tus imágenes',
          label: 'Imagen',
          upload: 'CARGAR IMAGEN',
          change: 'CAMBIAR',
          add: 'Agregar imagen',
          placeholder: 'Seleccione la imagen...',
          tooltips: {
            max: 'Máx. número de imágenes:',
            format: 'Formatos:',
            size: 'Tamaño máximo de cada imágen:',
          },
        },
        strVideos: {
          title: '2. Carga tus videos',
          label: 'Video',
          upload: 'CARGAR VIDEO',
          change: 'CAMBIAR',
          add: 'Agregar video',
          placeholder: 'Selecciona el video...',
          tooltips: {
            max: 'Máx. número de videos:',
            format: 'Formatos:',
            size: 'Tamaño máx. de cada video:',
          },
        },
        strTask: {
          title: '3. Asigna un nombre a la tarea y escribe las instrucciones',
          labels: {
            name: 'Nombre de tarea',
            instruction: 'Instrucciones de la tarea',
            duration: 'Duración total de la tarea',
            select: 'Seleccione la duración',
          },
        },
        createCreateTask: {
          title: 'Create Task',
        },
      },
      errors: {
        imageNeed: 'Necesitas tener al menos una imagen',
        videoNeed: 'Necesitas tener al menos un video',
        imageSize: 'ERROR: Tamaño de imagen',
        videoSize: 'ERROR: Tamaño de video',
        videoFormat: 'ERROR: Formato de video',
        imageFormat: 'ERROR: Formato de imagen',
        mediaFormat: 'Error: Formato de archivo multimedia',
        mediaSize: 'Error: Tamaño de archivo multimedia',
        noImage: 'Sin imagen',
      },
    },
    previewExport: {
      campaignID: 'ID de campaña:',
      objective: 'Objetivo(s):',
      description: 'Descripción:',
      kindStudy: 'Tipo de estudio:',
      device: 'Dispositivo:',
      code: 'Código de estudio:',
      language: 'Idioma:',
      quota: 'Quota:',
      Panel: 'Panel:',
      kind: 'Tipo de estudio:',
      question: 'Pregunta:',
      answer: 'Respuesta:',
      name: 'Nombre:',
      instruction: 'Instrucción:',
      kindContent: 'Tipo de contenido:',
      timeDuration: 'Duración:',
      urlContent: 'URL del contenido:',
      noOption: 'No opciones',
      title: {
        profile: 'Preguntas de Perfil',
        task: 'Tareas y preguntas',
        exit: 'Preguntas de salida',
      },
    },
    tracker: {
      modal: {
        subtitle: 'Modifica las Urls aqui',
        buttonCancel: 'Cancelar',
        buttonChange: 'Cambiar',
        helperText: 'Cambia el parametro de tester ID por cuatro X (XXXX)',
        validationIdentifier: 'Parametro de tester id invalido, usa XXXX',
        validationUrl: 'Url no valido',
        defaultInput: 'Url',
        warningText: 'Si envía un campo vacío, se utilizará la URL predeterminada en su lugar.',
      },
      bar: {
        page: 'Mi Campaña',
        breadcrumb: {
          first: 'Rastreador de campaña: enlace de prueba listo para lanzar',
        },
      },
      tabs: {
        test: 'Enlace de vista previa',
        campaign: 'Enlace de Campaña',
        about: 'Sobre la campaña',
      },
      link: {
        test: 'Link Preeliminar',
        production: 'Links de la campaña',
        copy: 'COPIAR LINK',
      },
      components: {
        cuotaParticipants: {
          sampleSize: 'Tamaño',
          participantsLeft: 'Participantes faltantes',
          participantsCompleted: 'Participantes completados',
        },
        timeParticipants: {
          title: 'PROMEDIO DE TIEMPO PARA COMPLETAR EL ESTUDIO',
          min: 'min',
          sec: 'seg',
          days: 'Dias',
          hour: 'hrs',
          titleDays: 'DIAS PARA COMPLETAR LA CAMPAÑA',
          completionRate: 'TASA DE COMPLETADOS',
          bounceRate: 'TASA DE REBOTE',
          daysToComplete: 'DÍAS PARA COMPLETAR EL CAMPO',
        },
        testerPie: {
          byStatus: 'Testers por estado',
          rejected: 'Distribución de testers rechazados',
        },
        buttons: {
          pause: 'Pausar Campaña',
          resume: 'Continuar Campaña',
          close: 'Cerrar Campaña',
        },
      },
      copied: 'Copiado',
      linkLaunched: 'Enlace lanzado',
      titleChars: 'Rendimiento del enlace',
      titleTesters: 'Información de testers',
      titleAllAnswers: 'Todas las respuestas',
      attentionScoreTitle: 'Attention Score',
      emotionsTitle: 'Emociones',
      taskPlaceholder: 'Tareas',
      charts: {
        bar: {
          title: 'Filtrados:',
        },
        sankey: {
          title: 'Actividad por usuario:',
        },
      },
      all: 'Todos',
    },
    question: {
      label: 'Imagen de la pregunta (opcional)',
      upload: {
        placeholder: 'Por favor seleccione un archivo multimedia...',
        load: 'cargar archivo',
        change: 'cambiar archivo',
      },
    },
    dataTable: {
      previousText: 'Anterior',
      nextText: 'Siguiente',
      pageText: 'Página',
      noDataText: 'No hay registros',
      ofText: 'de',
      rowsText: 'registros',
    },
    visualization: {
      menu: ['Tracker', 'Profile', 'Individual result', 'General results'],
      about: {
        answerYes: 'Si',
        answerNo: 'No',
        optionsTable: {
          name: 'Nombre de campaña /\n Atributos de campaña',
          objective: 'Objetivos',
          description: 'Descripción',
          biometrical_analysis: 'Análisis biométrico',
          language: 'Idioma',
          quota: 'Cuota',
          id: 'Identificador',
        },
      },
      sample: {
        titleRationalQuestions: 'Preguntas racionales',
        titleQuestion: 'Pregunta #',
        titleClosed: 'Respuesta cerrada',
        averageTitle: 'Promedio: ',
      },
      multipleChart: {
        answersTitle: 'Respuestas',
        tableTitle: 'Datos en tabla',
      },
    },
    user: {
      title: 'Users manager',
      createUser: {
        title: 'Creating user',
        subtitle: 'Users manager / ',
      },
      editUser: {
        title: 'Edit user',
        subtitle: 'Users manager / ',
      },
      labels: {
        firstName: 'Nombre',
        lastName: 'Apellidos',
        email: 'Correo electrónico',
        password: 'Contraseña',
        passwordConfirm: 'Repetir contraseña',
        userName: 'username',
      },
      noSelected: 'Select a role',
      passwordnotmatch: 'Passwords do not match',
      takenEmail: 'Email aready taken, choose another one.',
      invalidPassword: 'the password must be at least six characters and should be a mix of uppercase and lowercase characters, and optionally numbers and punctuation',
      deleteModal: {
        title: 'Borrar usuario',
        subtitle: '¿Está seguro de eliminar el usuario seleccionado?',
        content: 'no podrás deshacer esta acción',
        actionText: 'Borrar',
      },
      updateUser: {
        title: 'Editar usuario',
        subtitle: 'User manager / ',
      },
    },
    Organization: {
      title: 'Organizations manager',
      invalidCreation: 'your organization is invalid',
      createOrganization: {
        title: 'Creating Organization',
        subtitle: 'Organization manager / ',
      },
      labels: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        password: 'Password',
        passwordConfirm: 'Confirm Password',
        useName: 'username',
      },
      deleteModal: {
        title: 'Delete Organization',
        subtitle: 'Are you sure to delete the selected Organization?',
        content: 'you will not be able to undo this action',
        actionText: 'Delete',
      },
      updateOrganization: {
        title: 'Edit Organization',
        subtitle: 'Organization manager / ',
      },
    },
    clientPage: {
      title: 'Clients manager',
      createClient: {
        title: ' Creating client',
        subtitle: 'Clients manager /',
      },
    },
    tag: {
      title: 'Tags manager',
      createTag: {
        title: 'Creating tag',
        subtitle: 'Tags manager / ',
      },
      updateTag: {
        title: 'Edit tag',
        subtitle: 'Tags manager / ',
      },
      labels: {
        name: 'Name',
        category: 'Category',
      },
      noSelected: 'Select a category',
    },
    category: {
      title: 'category manager',
      createCategory: {
        title: 'Creating Category',
        subtitle: 'Tag manager / ',
      },
      labels: {
        name: 'Name',
        category: 'Category',
      },
      noSelected: 'Select a category',
    },
    snapshot: 'Download the current frame displayed on the player.',
    'min-fix-time': 'The minimum time participants took to fixate on the area of interest.',
    fixation: 'The percentage of visual fixations concentrated on an area of interest during exposure time.',
    participants: 'The percentage of participants who observed the area of interest in their exposure time.',
    'escale-emotional-arousal': 'Emotional Arousal',
    'scale-atention-rational': 'Rational Metrics',
    'metrics-tooltip': 'See and analyze the result of all metrics (rational and biometric) included in the study evaluation.',
    emotional: 'Deep understanding of your stimulus\' second by second visual and emotional data performance.',
    valance: 'Positive & Negative',
    'attention-score': 'Emotional Arousal & Attention Score',
    'emotional-arousal': 'Emotional Arousal',
    'aois-title': 'Areas Of Interest',
    heatmaps: 'Heatmaps',
  },
};
