import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

const [rootReducers, rootInitialState] = reducers;

export const Context = createContext(rootInitialState);
const { Provider } = Context;

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducers, rootInitialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export default Context;