import { gql } from '@apollo/client';

const ERROR_QUERY = gql`
  query error {
    error @client {
      open
      type
      message
    }
  }
`;

const GET_MAIN_MENU = gql`
  query getMainMenu {
    mainMenu @client {
      isOpen
    }
  }
`;


const INFO_USER = gql`
  query user {
    user {
      id
      email
      firstName
      lastName
      authenticationToken
      organizations {
        role {
          active
          description
          name
          permissions
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

const STATUS_DIALOG = gql`
  query opendialogFiltersVisualization {
    opendialogFiltersVisualization @client
  }
`;

const GET_STUDY_CAMPAIGNS = gql`
  query getStudy{
    study @client {
      name
      campaignIds
      product
      organization
      showExitResults
      users
    }
  }
`;

const GET_STATUS_CAMPAIGN = gql`
  query campaign {
    campaign @client {
      id
      status
    }
  }
`;

const SHOW_SIGN_OUT = gql`
  query campaign {
    showSignOut @client
  }
`;

export {
  ERROR_QUERY,
  GET_MAIN_MENU,
  INFO_USER,
  STATUS_DIALOG,
  GET_STUDY_CAMPAIGNS,
  GET_STATUS_CAMPAIGN,
  SHOW_SIGN_OUT,
};