import React from 'react';
import PersonPin from '@material-ui/icons/PersonPin';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import TrendingUp from '@material-ui/icons/TrendingUp';
/* import Chat from '@material-ui/icons/Chat';
import Book from '@material-ui/icons/Book';
import ColorLens from '@material-ui/icons/ColorLens'; */
import Build from '@material-ui/icons/Build';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import Sort from '@material-ui/icons/Sort';
/* import Subscriptions from '@material-ui/icons/Subscriptions'; */

const analyzeItems = [
  {
    title: 'Profile',
    icon: <PersonPin />,
    path: 'profiling',
  },
  {
    title: 'Summary',
    icon: <LibraryBooks />,
    path: 'individual-results/summary',
  },
  /* {
    title: ' Brand Performance',
    icon: <TrendingUp />,
    path: 'individual-results/brand-performance',
  },
  {
    title: 'Intent / Message Performance ',
    icon: <Chat />,
    path: 'individual-results/message-performance',
  },
  {
    title: 'Engagement & Storytelling ',
    icon: <Book />,
    path: 'individual-results/engagement-storytelling',
  },
  {
    title: 'Style & format',
    icon: <ColorLens />,
    path: 'individual-results/style-format',
  },
  {
    title: 'Scenes',
    icon: <Subscriptions />,
    path: 'scenes',
  }, */
  {
    title: 'Individual Results',
    icon: <TrendingUp />,
    path: 'individual-results',
  },
];
const editItems = [
  {
    title: 'Setup',
    icon: <Build />,
    path: {
      create: 'campaign/create',
      edit: 'campaign/edit',
    },
  },
  {
    title: 'Tasks and Questions',
    icon: <PlaylistAddCheck />,
    path: 'campaign/preview',
  },
  {
    title: 'summary',
    icon: <Sort />,
    path: 'summary',
  },
];

export {
  analyzeItems,
  editItems,
};