import React, { useState, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InsertChart from '@material-ui/icons/InsertChart';

const styles = () => ({
  mainListItem: {
    display: 'flex',
    justifyContent: 'start',
    textTransform: 'uppercase',
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: '1.6rem',
    margin: 0,
  },
  navLink: {
    padding: '1.6rem',
    '& > div > svg, span': {
      color: '#919098',
    },
    '&.Mui-selected': {
      color: '#FFFFFF',
      backgroundColor: '#0F28A9',
      '&:hover': {
        backgroundColor: '#0F28A9',
      },
      '& > div > svg, span': {
        color: '#FFFFFF',
      },
    },
  },
  icon: {
    width: '2.4rem',
    height: '2.4rem',
    minWidth: 0,
    marginRight: '0.5rem',
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
});

const AnalyzeMenu = (props) => {
  const [open, useOpen] = useState(props.open);
  return (
    <Fragment>
      <ListItem
        button
        onClick={() => useOpen(!open)}
        selected={props.selected}
        className={props.classes.navLink}
      >
        <ListItemIcon className={props.classes.icon} >
          <InsertChart />
        </ListItemIcon >
        <ListItemText
          disableTypography
          primary={props.listItemText}
          className={props.classes.mainListItem}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </Fragment>
  );
};

AnalyzeMenu.propTypes = {
  classes: PropTypes.shape({
    nested: PropTypes.string,
    icon: PropTypes.string,
    mainListItem: PropTypes.string,
    navLink: PropTypes.string,
  }).isRequired,
  listItemText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AnalyzeMenu);
