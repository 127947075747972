import { validArray } from './validations';
import { getStrings } from './i18n';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  transform: draggableStyle.transform,
  transition: draggableStyle.transform,
});

const IconStatus = {
  DRAFT: {
    icon: 'assignment',
    color: '#0F28A9',
  },
  IN_PROGRESS: {
    icon: 'history',
    color: '#FF6B00',
  },
  COMPLETED: {
    icon: 'done_all',
    color: '#00CB31',
  },
  PAUSED: {
    icon: 'pause_circle_outline',
    color: '#EC2A2A',
  },
};


const getIcon = (type) => {
  const icons = {
    OPEN: 'ic_question_openLong',
    OPEN_SHORT: 'ic_question_openShort',
    OPEN_LONG: 'ic_question_openLong',
    MULT_SINGLE: 'ic_question_multiSingle',
    MULT_MULTIPLE: 'ic_question_multMultiple',
    RATING_SCALE: 'ic_question_scale',
    MATRIX: 'ic_question_matrix',
    IMPORTANCE: 'ic_question_importance',
    IMAGES: 'ic_task_image',
    VIDEO: 'ic_task_video',
    UX_DESKTOP: 'ic_task_web',
    UX_MOBILE: 'ic_task_mobile',
  };
  return icons[type];
};

const getIconMaterialize = (type) => {
  const icons = {
    OPEN: 'notes',
    OPEN_SHORT: 'short_text',
    OPEN_LONG: 'notes',
    MULT_SINGLE: 'radio_button_checked',
    MULT_MULTIPLE: 'check_box',
    RATING_SCALE: 'linear_scale',
    MATRIX: 'view_module',
    IMPORTANCE: 'format_list_numbered',
    IMAGES: 'insert_photo',
    VIDEO: 'play_circle_filled',
    UX_DESKTOP: 'ic_task_web',
    UX_MOBILE: 'ic_task_mobile',
  };
  return icons[type];
};

const validationQuestion = (data) => {
  switch (data.type) {
    case 'OPEN_SHORT':
    case 'OPEN_LONG':
      if (data.id != null
        && data.order != null
        && data.title != null
        && data.type != null
        && data.open != null
      ) {
        return true;
      }
      return false;
    case 'MULT_SINGLE':
      if (data.id != null
        && data.order != null
        && data.title != null
        && data.type != null
        && data.multiple != null
      ) {
        if (data.multiple.options.length > 0) {
          const labels = data.multiple.options.filter(i => i.label === '');
          if (labels.length === 0) {
            return true;
          }
        }
      }
      return false;
    case 'MULT_MULTIPLE':
      if (data.id != null
        && data.order != null
        && data.title != null
        && data.type != null
        && data.multiple != null
      ) {
        if (data.multiple.options.length > 0) {
          const labels = data.multiple.options.filter(i => i.label === '');
          if (labels.length === 0) {
            return true;
          }
        }
      }
      return false;
    case 'RATING_SCALE':
      if (data.id != null
        && data.order != null
        && data.title != null
        && data.type != null
        && data.scale != null
      ) {
        if (data.scale.scale_data.length > 0) {
          const from = data.scale.scale_data.filter(i => i.type === 'FROM');
          const to = data.scale.scale_data.filter(i => i.type === 'TO');
          if (from.length > 0 && to.length > 0) {
            return true;
          }
        }
      }
      return false;
    case 'MATRIX':
      if (data.id != null
        && data.order != null
        && data.title != null
        && data.type != null
        && data.matrix != null
      ) {
        if (data.matrix.columns.length > 0 && data.matrix.rows.length) {
          return true;
        }
      }
      return false;
    case 'IMPORTANCE':
      if (data.id != null
        && data.order != null
        && data.title != null
        && data.type != null
        && data.importance != null
      ) {
        if (data.importance.elements.length > 0) {
          return true;
        }
      }
      return false;
    default:
      return false;
  }
};

const getTypeQs = (type, question) => {
  switch (type) {
    case 'OPEN':
      return { label: 'Open old', optionObj: question.open };
    case 'OPEN_SHORT':
      return { label: 'Open Short', optionObj: question.open };
    case 'OPEN_LONG':
      return { label: 'Open Long', optionObj: question.open };
    case 'MULT_SINGLE':
      return { label: 'Multiple Single', optionObj: question.multiple };
    case 'MULT_MULTIPLE':
      return { label: 'Multiple Multiple', optionObj: question.multiple };
    case 'RATING_SCALE':
      return { label: 'Scale', optionObj: question.scale };
    case 'MATRIX':
      return { label: 'Matrix', optionObj: question.matrix };
    case 'IMPORTANCE':
      return { label: 'Importance', optionObj: question.importance };
    default:
      return '';
  }
};

const sortArray = (arrayValues) => {
  const list = arrayValues.concat([]);
  return list.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });
};

const getClassStatusCampaign = status => status.replace(' ', '_').toLowerCase();

const addEllipsisText = (text, long) => {
  if (text.length > long) {
    return `${text.substring(0, long)}...`;
  }
  return text;
};

const pullishDataCampaign = (data) => {
  const profile = data.questionnaires.filter(q => q.type === 'PROFILE');
  const exit = data.questionnaires.filter(q => q.type === 'EXIT');
  const newData = {
    ...data,
    profile: validArray(profile) ? profile[0] : {},
    exit: validArray(exit) ? exit[0] : {},
    panel: validArray(data.panels) ? data.panels[0] : {},
  };
  return newData;
};

const pullishDataTask = (data) => {
  const { task_media, task_type } = data; //eslint-disable-line
  const type = task_type.id;
  const typeName = task_type.name;
  const newTaskMedia = task_media.map(item => ({
    ...item,
    error: false,
    errorMessage: '',
    contentType: typeName,
    file: { name: item.mediaUrl },
  }));
  const newData = {
    ...data,
    type: type.toString(),
    task_media: newTaskMedia,
  };
  return newData;
};

const calculateOrderQuestion = (arrayValues) => {
  const list = arrayValues.concat([]);
  return list.map((v, i) => {
    const item = Object.assign({}, v);
    item.order = i + 1;
    return item;
  });
};

const calculateKeyQuestionSecuence = (arrayValues, key) => {
  const list = arrayValues.concat([]);
  return list.map((v, i) => {
    const item = Object.assign({}, v);
    item[key] = i + 1;
    return item;
  });
};

const parseError = (error) => {
  const general = getStrings('general', { returnObjects: true });
  let msg = general.errorGeneral;
  let finalErrors = [];
  let { errors, graphQLErrors } = error;
  const { message } = error;
  errors = errors || [];
  graphQLErrors = graphQLErrors || [];
  finalErrors = errors.concat(graphQLErrors);
  if (finalErrors.length > 1) {
    msg = '';
    finalErrors.forEach((errorIterator) => {
      msg = `${msg}${errorIterator.message},`;
    });
  } else if (finalErrors.length === 1) {
    msg = `${msg}${finalErrors[0].message}`;
  } else {
    msg = message;
  }
  return { message: msg };
};

/**
 * Function to set a cookie.
 * @param {*} cname key of the cookie
 * @param {*} cvalue value to save
 * @param {*} exdays expires time in days.
 */
const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};path=/`;
};

/**
 * Function to het any cookie by name
 * @param {*} cname key of the cookie
 */
const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const formatTime = (value) => {
  if (value <= 9) {
    return `0${value}`;
  }
  return `${value}`;
};

const formatterTip = v => `00:${formatTime(v)}`;

const msToTime = (durationMs) => {
  let milliseconds = Number((durationMs % 1000) / 100).toFixed(0);
  let seconds = Math.floor((durationMs / 1000) % 60);
  let minutes = Math.floor((durationMs / (1000 * 60)) % 60);
  let hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? `0${hours}` : hours;
  minutes = (minutes < 10) ? `0${minutes}` : minutes;
  seconds = (seconds < 10) ? `0${seconds}` : seconds;
  milliseconds = (milliseconds < 10) ? `0${milliseconds}` : milliseconds;

  return {
    milliseconds,
    seconds,
    minutes,
    hours,
  };
};

const formatterTimeImage = (totalsec) => {
  const { minutes, seconds } = msToTime(totalsec * 1000);
  return `${minutes}:${seconds}`;
};

const formatterTotalTime = (totalsec) => {
  const { minutes, seconds } = msToTime(totalsec * 1000);
  return `${minutes}:${seconds}`;
};

const formatterTotalTimeMillisecond = (time) => {
  const { minutes, seconds, milliseconds } = msToTime(time);
  return `${minutes}:${seconds}:${milliseconds}`;
};

export {
  reorder,
  getItemStyle,
  getIcon,
  getIconMaterialize,
  validationQuestion,
  sortArray,
  getClassStatusCampaign,
  addEllipsisText,
  pullishDataCampaign,
  pullishDataTask,
  getTypeQs,
  calculateOrderQuestion,
  parseError,
  calculateKeyQuestionSecuence,
  setCookie,
  getCookie,
  IconStatus,
  formatTime,
  formatterTip,
  formatterTotalTime,
  formatterTotalTimeMillisecond,
  formatterTimeImage,
};