import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

class BarChartGrupedComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      keys: props.keys,
      groupMode: props.groupMode,
    };
  }

  setStateValue(state) {
    this.setState(state);
  }

  render() {
    const { data, keys } = this.state;
    return (
      <Fragment>
        <div className="barchart__title">{this.props.title}</div>
        <div className="bar__container">
          <ResponsiveBar
            data={data}
            keys={keys}
            indexBy="tag"
            margin={{
              top: 50,
              right: 130,
              bottom: 50,
              left: 60,
            }}
            padding={0.2}
            colorBy="id"
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: 36,
            }}
            borderColor="inherit:darker(1.6)"
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="inherit:darker(1.6)"
            animate
            layout={this.props.layout || 'horizontal'}
            groupMode={this.state.groupMode || 'stacked'}
            colors={this.props.colors || 'nivo'}
            motionStiffness={90}
            motionDamping={15}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </Fragment>
    );
  }
}

BarChartGrupedComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  groupMode: PropTypes.string,
  layout: PropTypes.string.isRequired,
  colors: PropTypes.string.isRequired,
};

BarChartGrupedComponent.defaultProps = {
  groupMode: 'stacked',
};

export default BarChartGrupedComponent;
