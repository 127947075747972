/** Module utils */
export const getArrayType
  = (data, type) => data.filter(questionnaire => questionnaire.type === type);

export const validationNextStep = (campaign) => {
  const hasQuestions = campaign.questionnaires.filter(q => q.questions.length > 0);

  const allTasks = [...campaign.tasks];

  let statusTasks = false;
  allTasks.forEach((task) => {
    if (task.questionnaire === null) return statusTasks;
    statusTasks = !!(task.questionnaire.questions.length > 0);
    return statusTasks;
  });
  const hasQuestionProfile = hasQuestions.find(q => q.type === 'PROFILE');
  const questionnaireExit = campaign.questionnaires.find(q => q.type === 'EXIT');

  if (questionnaireExit) {
    if (questionnaireExit.questions.length === 0) return false;
    return hasQuestionProfile.questions.length > 0 && statusTasks;
  }

  if (!hasQuestionProfile || hasQuestionProfile?.length === 0) return false;
  return hasQuestionProfile.questions.length > 0 && statusTasks;
};