import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
});

const MediaCard = (props) => {
  const classes = useStyles();
  const {
    mediaType, name, src, children, buttons, handleOnClick,
  } = props;
  return (
    <Card className={classes.card} onClick={handleOnClick}>
      <CardActionArea>
        <CardMedia
          component={mediaType}
          alt={name}
          src={src}
          title={name}
          crossorigin="anonymous"
        />
        <CardContent>
          {children}
        </CardContent>
      </CardActionArea>
      {
        buttons !== null && (
          <CardActions>
            {buttons}
          </CardActions>
        )
      }
    </Card>
  );
};

MediaCard.propTypes = {
  mediaType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.node,
  handleOnClick: PropTypes.func.isRequired,
};

MediaCard.defaultProps = {
  buttons: null,
};

export default MediaCard;