import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';
import NumberChartComponent from '../NumberChartComponent';
import useStyle from './styles';

const IndicatorsChartsComponent = ({
  value, name, dataChart, taskId, ...props
}) => {
  const classes = useStyle();

  if (props.data_type === 'discrete') {
    return (
      <Box mt={3} mb={3}>
        <NumberChartComponent value={value} />
      </Box>
    );
  }

  const dataIndicators = JSON.parse(dataChart);
  const listIndicators = Object.keys(dataIndicators);

  return (
    <Box pt={5}>
      <Grid container spacing={2}>
        {listIndicators.map(indicator => (
          <Grid item xs={4} key="list indicator">
            <Box mb={5}>
              <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                <Box>
                  {dataIndicators[indicator] <= 39 && (
                    <Typography variant="h1" component="span" align="center" className={classes.lowPerformance}>
                      {dataIndicators[indicator]}
                    </Typography>
                  )}
                  {(dataIndicators[indicator] > 29 && dataIndicators[indicator] <= 79) && (
                    <Typography variant="h1" component="span" align="center" className={classes.mediumPerformance}>
                      {dataIndicators[indicator]}
                    </Typography>
                  )}
                  {dataIndicators[indicator] > 79 && (
                    <Typography variant="h1" component="span" align="center" className={classes.highPerformance}>
                      {dataIndicators[indicator]}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box width="100%" display="flex" justifyContent="center">
                <Typography variant="h3" color="textSecondary">
                  {
                    indicator !== undefined || null ? indicator : 'Other'
                  }
                </Typography>
              </Box>
            </Box>
          </Grid>))}
      </Grid>
    </Box>
  );
};

IndicatorsChartsComponent.propTypes = {
  dataChart: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.string.isRequired,
};

export default IndicatorsChartsComponent;
