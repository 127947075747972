export default {
  root: {
    width: '100%',
    margin: '.8rem 0',
    '& > label': {
      color: '#919098',
      fontSize: ' 1.2rem',
      lineHeight: '1.9rem',
    },
    '& > div': {
      fontSize: ' 1.6rem',
      lineHeight: '1.9rem',
    },
  },
};
