import React from 'react';
import PropTypes from 'prop-types';
import { ContainerContentMedia, Video } from './styled';

const ViewVideo = props => (
  <ContainerContentMedia>
    <Video src={props.src} type="video/mp4" crossOrigin="anonymous" controls />
  </ContainerContentMedia>
);

ViewVideo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ViewVideo;