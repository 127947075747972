import { TASK_MEDIA_LOAD, TASK_MEDIA_SIZE, TASK_MEDIA_CLEAN } from './actions/taskMedia.action';
import { RESET } from './actions/general.action';

const initialState = {
  isMedia: false,
  type: 'campaign',
  taskMedia: {
    id: null,
    task_media: null,
    questionnaire: {
      id: null,
    },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case TASK_MEDIA_LOAD: return { ...state, ...action.payload };
    case TASK_MEDIA_SIZE: return { ...state, ...action.payload };
    case TASK_MEDIA_CLEAN: return { isMedia: false, type: 'campaign' };
    case RESET: return initialState;
    default: return state;
  }
};

export default [reducer, initialState];
