import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutocompleteComponent = ({
  options, defaultValue, name, ...props
}) => {
  const [value, setValue] = useState(defaultValue);
  const handleChange = (e, item) => {
    e.preventDefault();
    if (item === null) return;
    setValue(item);
    props.handleChange({
      target: {
        name,
        value: item.id,
      },
    });
  };

  const handleTextField = (e) => {
    if (props.handleText) {
      props.handleText(e.target.name);
    }
  };

  return (
    <Autocomplete
      autoComplete
      defaultValue={value}
      options={options}
      onChange={handleChange}
      getOptionLabel={option => option.title}
      renderInput={params => (
        <TextField {...params} onChange={handleTextField} label={props.label} margin="normal" variant="outlined" fullWidth />
      )}
    />
  );
};

AutocompleteComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultValue: PropTypes.shape({}).isRequired,
};

export default AutocompleteComponent;