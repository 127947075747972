import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { getStrings } from '../../utils/i18n';

const { buttons: { select } } = getStrings('general', { returnObjects: true });

const CardButtonComponent = (props) => {
  const styles = {
    row: {
      cardButton: {
        icon: 'cardButton__primary--icon',
        name: 'cardButton__primary--title',
        select: 'cardButton__primary--select',
        image: 'cardButton__secundary--image',
      },
      cardButtonLabel: {
        icon: 'cardButtonLabel__primary--icon',
        name: 'cardButtonLabel__primary--title',
        select: 'cardButtonLabel__primary--select',
      },
      cardButtonWithoutIcon: {
        icon: '',
        name: 'cardButtonWithoutIcon__primary--title',
        select: 'cardButtonWithoutIcon__primary--select',
      },
    },
    column: {
      cardButton: {
        icon: '',
        name: 'cardButton__secundary--title',
        select: 'cardButton__secundary--select',
        image: 'cardButton__secundary--image',
      },
      cardButtonIcon: {
        icon: 'cardButtonIcon__secundary--icon',
        name: 'cardButtonIcon__secundary--title',
        label: 'cardButtonIcon__secundary--label',
        select: 'cardButtonIcon__secundary--select',
      },
      cardButtonIconLg: {
        icon: 'cardButtonIcon__secundary--lg--icon',
        name: 'cardButtonIcon__secundary--lg--title',
        select: 'cardButtonIcon__secundary--lg--select',
      },
    },
    actived: 'cardButtonIcon__button-icon--actived',
  };
  return (
    <div className={`${props.type} ${props.styles}`}>
      {props.iconMu &&
        (
          <div className={props.actived === props.value ? `${styles[props.direction][props.type].icon} ${styles.actived}` : `${styles[props.direction][props.type].icon}`}>
            <Icon>
              {props.iconMu}
            </Icon>
          </div>
        )
      }
      {props.icon &&
        (
          <div className={props.actived === props.value ? `${styles[props.direction][props.type].icon} ${props.icon} ${styles.actived}` : `${styles[props.direction][props.type].icon} ${props.icon}`} />
        )
      }
      {
        props.image &&
        (
          <img src={props.image} className={styles[props.direction][props.type].image} alt="logo" />
        )
      }
      {
        props.name && (
          <div className={props.actived === props.value ? `${styles[props.direction][props.type].name} ${styles.actived}` : styles[props.direction][props.type].name}>
            {props.name}
          </div>)
      }
      {
        props.label && (
          <div className={props.actived === props.value ? `${styles[props.direction][props.type].label} ${styles.actived}` : styles[props.direction][props.type].label}>
            {props.label}
          </div>)
      }
      <div className={props.actived === props.value ? `${styles[props.direction][props.type].select} ${styles.actived}` : styles[props.direction][props.type].select}>
        {props.actived === props.value ? (<div className="ic_general_check form__ic_question_multMultiple" />) : select}
      </div>
      {props.children}
    </div>
  );
};

CardButtonComponent.propTypes = {
  name: PropTypes.string,
  direction: PropTypes.string,
  value: PropTypes.string,
  styles: PropTypes.string,
  icon: PropTypes.string,
  actived: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  image: PropTypes.node,
  iconMu: PropTypes.string,
  label: PropTypes.string,
};

CardButtonComponent.defaultProps = {
  name: '',
  direction: 'row',
  value: '',
  styles: '',
  icon: '',
  iconMu: '',
  actived: '',
  children: '',
  type: '',
  image: '',
  label: '',
};

export default CardButtonComponent;

