import { FILTERS_UPDATE } from './actions/filters.action';
import { RESET } from './actions/general.action';

const initialState = {
  filters: [],
  timeFilter: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case FILTERS_UPDATE: return { ...state, ...action.payload };
    case RESET: return initialState;
    default: return state;
  }
};

export default [reducer, initialState];
