import styled from 'styled-components';

const ContainerContentMedia = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E7EBEF;
`;

const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 150px;
  object-fit: cover;
  outline: none;
`;


export {
  Image,
  ContainerContentMedia,
};