import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, Grid, Paper, Box, Typography } from '@material-ui/core';
import styles from './styles';

const useStyle = makeStyles(styles);

const PaperComponent = ({ smallCard, ...props }) => {
  const classes = useStyle();
  return (
    <Paper elevation={0} className={classes.paper} style={smallCard ? { height: '182px' } : { height: 'auto' }}>
      <Grid container spacing={2} className={classes.profileCard} >
        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-start">
                { props.icon !== null && (
                  <Box className={classes.iconContainer}>
                    <Icon className={classes.icon}>{props.icon}</Icon>
                  </Box>
                )}
                {props.simpleIcon !== null && (
                  <Icon className={classes.simpleIcon}>{props.simpleIcon}</Icon>
                )}
                { props.title !== '' && (
                  <Box>
                    <Typography variant="subtitle2" color="primary" className={classes.title}>
                      {props.title}
                    </Typography>
                    {props.subtitle &&
                      <Typography variant="subtitle1" color="primary" className={classes.subtitle}>
                        Tasks will display randomly
                        </Typography>
                      }
                    </Box>
                  )}
                {props.simpleTitle !== '' && (
                  <Box pl={2}>
                    <Typography variant="subtitle2" className={classes.simpleTitle}>
                      {props.simpleTitle}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </Paper>
  );
};

PaperComponent.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string,
  simpleTitle: PropTypes.string,
  simpleIcon: PropTypes.string,
  subtitle: PropTypes.string,
};

PaperComponent.defaultProps = {
  title: '',
  subtitle: '',
  simpleTitle: '',
  icon: null,
  simpleIcon: null,
};

export default PaperComponent;