import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { Grid, Box } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import { SAMPLE_DISTRIBUTION } from '../graphql';

import ProgressQuota from '../../Feedbacks/ProgressQuota';

const Progress = ({ id }) => (
  <Query
    query={SAMPLE_DISTRIBUTION}
    variables={{
      id: Number(id),
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Box height={60} p={3}><Skeleton height={20} /></Box>;
      if (error) return `Error: ${error.message}`;
      const { sampleDistribution } = data;
      const value = sampleDistribution !== null ? sampleDistribution.testers_completed : 0;
      const total = sampleDistribution !== null ? sampleDistribution.sample_size : 0;
      let percentage = 0;
      if (value !== 0 && total !== 0) {
        percentage = (value * 100) / total;
      }
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box p={2}>
              <ProgressQuota percentage={percentage} value={value} total={total} />
            </Box>
          </Grid>
        </Grid>
      );
    }}
  </Query>
);

Progress.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Progress;