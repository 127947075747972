import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { getStrings } from '../../utils/i18n';
import { CheckboxComponent } from '../../components';

const { multiple, answer } = getStrings('answers', { returnObjects: true });

const OptionFileComponent = ({
  option,
  index,
  provided,
  isTerminate,
  handleInput,
  handleDeleteItem,
  handleInputFile,
  conditional,
  handledeleteImage,
  handleFixedOption,
  fixedChecked,
  nameCheckbox,
  randomized,
  uniqueChecked,
  handleUniqueOption,
  isMultiple,
}) => (
  <div className="inputLabel__container">
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <div className={`optionfile__container ${option.label === '' && 'optionfile__inputLabel-danger'}`}>
        <div className="optionfile__inputLabel">
          <div className="inputLabel__label" >{answer} #{option.id}</div>
          <input
            className="optionfile__inputLabel-input"
            placeholder={multiple.placeholder}
            value={option.label}
            disabled={option.disabled}
            name="label"
            onChange={e => handleInput(e, 'label', index)}
            maxLength="300"
          />
          <div className="inputLabel__checkboxes-container">
            {
              conditional === 'MUST' &&
              <div className="inputLabel__terminate">
                <div className="inputLabel__terminate-label">{multiple.mustTitle}</div>
                <button
                  className={!option.required ? 'inputLabel__terminate-ckeck' : 'inputLabel__terminate-ckeck ic_question_multMultiple'}
                  onClick={e => isTerminate(e, index)}
                />
              </div>
            }
            {
              conditional === 'TERMINATE' &&
              <div className="inputLabel__terminate">
                <div className="inputLabel__terminate-label">{multiple.terminateTitle}</div>
                <button
                  className={!option.terminate ? 'inputLabel__terminate-ckeck' : 'inputLabel__terminate-ckeck ic_question_multMultiple'}
                  onClick={e => isTerminate(e, index)}
                />
                {
                  !option.terminate &&
                  <input
                    className="inputLabel__terminate-quota"
                    placeholder={multiple.placeholderQuota}
                    value={option.quota}
                    onChange={e => handleInput(e, 'quota', index)}
                    type="number"
                    name="quota"
                    max={200}
                    min={0}
                    maxLength="2"
                  />
                }
              </div>
            }
            {
              randomized ? (
                <CheckboxComponent
                  handleChange={e => handleFixedOption(e, index)}
                  label="Fixed"
                  checked={fixedChecked}
                  name={nameCheckbox}
                  labelPlacement="start"
                />) : null
            }
            {isMultiple &&
              <>
                <div style={{ width: '10px' }} />
                <CheckboxComponent
                  handleChange={e => handleUniqueOption(e, index)}
                  label="Unique"
                  checked={uniqueChecked}
                  name="unique"
                  labelPlacement="start"
                />
              </>}
            <div className="ic_general_trash optionfile__inputLabel-delete" role="presentation" onClick={() => handleDeleteItem(index)} />
          </div>
        </div>
        <div className="optionfile__separator" />
        <div className="optionfile__inputLabel">
          <div className="optionfile__inputLabel-attached" >
            {
              option.image_url ?
                <img src={option.image_url} alt="preview.jpg" className="optionfile__inputLabel-preview" /> :
                multiple.loadFile
            }
          </div>
          <div className="optionfile__inputLabel--file-container optionfile__inputLabel--container-draggable">
            {
              option.error ?
                <div className="inputFile__input-name inputFile__input-name--danger">{option.errorMessage}</div> :
                <div className={`${option.filename ? 'inputFile__input-name--load' : 'inputFile__input-name'}`}>{option.filename || multiple.uploadFilePlaceHolder}</div>
            }
            {
              option.image_url ?
                <IconButton
                  key={option.id}
                  onClick={() => handledeleteImage(index)}
                  className="ic_general_trash optionfile__inputLabel-delete optionfile_-delete-image"
                /> :
                <>
                  <div className="optionfile__inputLabel-upload">{multiple.uploadFile}</div>
                  <input
                    className="inputFile__input"
                    placeholder={multiple.uploadFilePlaceHolder}
                    value=""
                    type="file"
                    onChange={e => handleInputFile(e, index)}
                  />
                </>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
);

OptionFileComponent.propTypes = {
  option: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  conditional: PropTypes.string.isRequired,
  provided: PropTypes.shape({}).isRequired,
  isTerminate: PropTypes.func.isRequired,
  handleInput: PropTypes.func.isRequired,
  handleInputFile: PropTypes.func,
  handleDeleteItem: PropTypes.func.isRequired,
  randomized: PropTypes.bool,
  uniqueChecked: PropTypes.bool,
  isMultiple: PropTypes.bool,
  handleUniqueOption: PropTypes.func.isRequired,
};

OptionFileComponent.defaultProps = {
  handleInputFile: () => { },
  randomized: false,
  uniqueChecked: false,
  isMultiple: false,
};

export default OptionFileComponent;