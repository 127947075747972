import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  NavLink,
  withRouter,
  Redirect,
} from 'react-router-dom';
import List from '@material-ui/core/List';
import { Typography, Box, Grid, Avatar, Button } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import { ApolloConsumer } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { useAuth0 } from '@auth0/auth0-react';

import styles from './styles';
import { getStrings } from '../../../utils/i18n';
import logo from '../../../../images/box.png';
import { INFO_USER, GET_MAIN_MENU, GET_STATUS_CAMPAIGN } from '../../../Apollo/queries.gql';
import SignOut from '../SignOut';
import { MENU_ORGANIZATION, MENU_CAMPAIGNS } from './menu';

const strMenu = getStrings('mainMenu', { returnObjects: true });

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <Button onClick={() => logout({ returnTo: window.location.origin })}>
      <Box py={1} display="flex">
        <Typography variant="overline" display="block" color="primary">{strMenu.logout}</Typography>
      </Box>
    </Button>
  );
};
class MenuSideBar extends React.Component {
  constructor(props) {
    super(props);
    const selectedIndex = 4;
    const { location: { pathname } } = props;
    const itemSelected = MENU_ORGANIZATION.concat(MENU_CAMPAIGNS)
      .filter(item => item.route === pathname).shift();
    this.state = {
      selectedIndex: itemSelected ? itemSelected.id : selectedIndex,
    };
  }
  handleListItemClick = (_event, index) => {
    this.setState({ selectedIndex: index });
  };
  render() {
    const { classes, isOpen } = this.props;
    return (
      <Box className={isOpen ? classes.mainmenuContainer : classes.mainmenuContainer} >
        <Box className={classes.constainerGeneral}>
          <Box>
            <Query query={INFO_USER}>
              {({ data, loading, error }) => {
                if (loading) return 'loading...';
                if (error) return `Error: ${error.message}`;

                if (data.user) {
                  const ORGANIZATION_ALLOWED =
                       (process.env.ALLOWED_ORGANIZATION_IDS || '').split(',');

                  const organization = data.user?.organizations[0]?.organization?.id;

                  if (!ORGANIZATION_ALLOWED.includes(organization)) {
                    return <Redirect push to="/not-authorized" />;
                  }
                }

                return (
                  <>
                    <Box>
                      {
                        isOpen ? (
                          <Grid container className={classes.headerMenuMainContainer}>
                            <Grid container className={classes.headerMenuPrimaryContainer} >
                              <Grid item xs={2} className={classes.headerMenuAvatarContainer}>
                                <Avatar className={classes.headerMenuAvatar}>
                                  {data.user.firstName.substring(0, 1).toUpperCase()}
                                  {data.user.lastName.substring(0, 1).toUpperCase()}
                                </Avatar>
                              </Grid>
                              <Grid item xs={8} className={classes.headerMenuNameContainer} >
                                <Typography variant="body2" color="primary">
                                  {data.user.firstName.toLowerCase()}
                                  {data.user.lastName.toLowerCase()}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} className={classes.headerMenuPointsList}>
                                {
                                  data.showSignOut && <SignOut />
                                }
                              </Grid>
                            </Grid>
                            <Grid container >
                              <Typography variant="caption" color="primary" className={classes.headerMenuCompanyInfo} >
                                {data.user.organizations[0].organization.name} / {data.user.organizations[0].role.name.toLowerCase().replace('_', ' ')}
                              </Typography>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container className={classes.headerMenuMainContainerAvatarClosed}>
                            <Grid item xs={12} className={classes.headerMenuAvatarContainer}>
                              <Avatar className={classes.headerMenuAvatar}>
                                {data.user.firstName.substring(0, 1).toUpperCase()}
                                {data.user.lastName.substring(0, 1).toUpperCase()}
                              </Avatar>
                            </Grid>
                          </Grid>
                        )
                      }
                    </Box>
                  </>
                );
              }}
            </Query>
            <Divider className={classes.divider} />
            <ApolloConsumer>
              {client => (
                <Fragment>
                  { MENU_ORGANIZATION.map(menu => (
                    <NavLink
                      onClick={() =>
                          client.writeQuery({
                            query: GET_MAIN_MENU,
                            data: { mainMenu: { isOpen: menu.isOpen } },
                          })
                        }
                      to={menu.route}
                      className={this.props.classes.link}
                      key={menu.id}
                    >
                      <ListItem
                        button
                        selected={this.state.selectedIndex === menu.id}
                        onClick={event => this.handleListItemClick(event, menu.id)}
                        className={classes.listItemIcon}
                      >
                        <ListItemIcon className={classes.iconContainer}>
                          {
                            menu.organization && !isOpen
                            ? <ListItem className={classes.listItemText} >
                              <Typography
                                variant="overline"
                                display="block"
                                color="primary"
                              >
                                {strMenu.titleOrganizationClosed}
                              </Typography>
                            </ListItem>
                            : <Icon className={classes.icon}>{menu.icon}</Icon>
                          }
                        </ListItemIcon>
                        {
                            isOpen
                              ? <ListItemText
                                primary={menu.text}
                                className={classes.openMenuOptions}
                                disableTypography
                              />
                              : null
                          }
                      </ListItem>
                    </NavLink >))
                    }
                </Fragment>
                )}
            </ApolloConsumer>
            <List
              subheader={
                isOpen ?
                  <Typography
                    variant="overline"
                    display="block"
                    className={classes.mainPadding}
                    color="primary"
                  >
                    {strMenu.titleCampaigns}
                  </Typography>
                :
                  <ListItem className={classes.listItemText}>
                    <Typography
                      variant="overline"
                      display="block"
                      color="primary"
                    >
                      {strMenu.titleCampaignsClosed}
                    </Typography>
                  </ListItem>
              }
            >
              <ApolloConsumer>
                {client => (
                  <Fragment>
                    { MENU_CAMPAIGNS.map(menu => (
                      <NavLink
                        onClick={() => {
                          client.writeQuery({
                            query: GET_MAIN_MENU,
                            data: { mainMenu: { isOpen: menu.isOpen } },
                          });
                          client.writeQuery({
                            query: GET_STATUS_CAMPAIGN,
                            data: {
                              campaign: {
                                id: null,
                                status: 'DRAFT',
                              },
                            },
                          });
                        }
                        }
                        to={menu.route}
                        className={this.props.classes.link}
                        key={menu.id}
                      >
                        <ListItem
                          button
                          selected={this.state.selectedIndex === menu.id}
                          onClick={event => this.handleListItemClick(event, menu.id)}
                          className={classes.listItemIcon}
                        >
                          <ListItemIcon className={classes.iconContainer}>
                            <Icon className={classes.icon}>{menu.icon}</Icon>
                          </ListItemIcon>
                          {
                            isOpen
                              ? <ListItemText
                                variant="button"
                                className={classes.openMenuOptions}
                                primary={menu.text}
                                disableTypography
                              />
                              : null
                          }
                        </ListItem>
                      </NavLink >))
                    }
                  </Fragment>
                )}
              </ApolloConsumer>
            </List>
          </Box>
          <Box className={classes.containerFooter}>
            <LogoutButton />
            <img className={classes.imageFooter} src={logo} alt="Logo" />
          </Box>
        </Box>
      </Box>
    );
  }
}

MenuSideBar.propTypes = {
  classes: PropTypes.shape({
    divider: PropTypes.string,
    toolbar: PropTypes.string,
    link: PropTypes.string,
    drawerHeader: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(styles)(MenuSideBar));