import { createMuiTheme } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const customShadow = [
  'none',
  '0px 2px 3px rgba(0, 0, 0, 0.15)',
  '0px 10px 20px rgba(0, 0, 0, 0.15)',
  '0px 15px 30px rgba(0, 0, 0, 0.25)',
];

const shadows = customShadow.concat(Array(21).fill('none'));

const theme = createMuiTheme({
  spacing: 8,
  palette,
  typography,
  overrides,
  shadows,
});

export default theme;
