import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

import { LoaderLogoComponent, Layout } from '../../components/';
import { MyApolloProvider } from '../../Apollo/my-apollo-provider';

const PrivateRoute = (props) => {
  const { path, exact, component } = props;
  const { isLoading, isAuthenticated } = useAuth0();


  if (isLoading) return <LoaderLogoComponent />;
  if (!isAuthenticated) {
    return <Redirect push to="/login" />;
  }

  // if (process.env.ALLOWED_ORGANIZATION_IDS !== undefined) {
  //   const ORGANIZATION_ALLOWED =
  //     (process.env.ALLOWED_ORGANIZATION_IDS || '').split(',');
  //   const {
  //     id: organizationId,
  //   } = JSON.parse(user).organizations[0].organization;

  //   if (!ORGANIZATION_ALLOWED.includes(organizationId)) {
  //     return <Redirect push to="/not-authorized" />;
  //   }
  // }

  return (
    <MyApolloProvider>
      <Layout>
        <Route
          path={path}
          exact={exact}
          component={component}
        />
      </Layout>
    </MyApolloProvider>
  );
};

PrivateRoute.propTypes = {
  path: PropTypes.string,
  exact: PropTypes.bool,
  component: PropTypes.func,
};

PrivateRoute.defaultProps = {
  path: '',
  exact: true,
  component: () => { },
};

export default PrivateRoute;