import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { render } from 'react-dom';

import '../styles/main.css';
import App from './App';
import { StateProvider } from './store';
import theme from './theme';
import ability from './utils/ability';

window.ability = ability;

const { SENTRY_DSN, SENTRY_ENV } = process.env;

window.onerror = (msg, url, lineNo, columnNo, error) => {
  Sentry.captureException(error, {
    fingerprint: ['general'],
    tags: {
      name: 'GeneralError',
      component: 'Root',
    },
    extra: {
      message: `${msg}, Error: Line: ${lineNo}, Column: ${columnNo}`,
      projectRoot: url,
    },
    environment: SENTRY_ENV,
  });
};

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: SENTRY_ENV,
  enabled: SENTRY_ENV !== 'development',
});

const Root = () => (
  <StateProvider>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </MuiThemeProvider>
  </StateProvider>
);

render(<Root />, document.getElementById('app'));
