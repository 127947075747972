
import { gql } from '@apollo/client';

const QUESTIONS_RESULTS_BY_ID = gql`
  query questionResults($id: ID!){
    questionResults(question_id: $id) {
      answers
      data
    }
  }
`;

const QUESTIONS_RESULTS_WITH_METRIC_ID = gql`
query questionResults($id: ID!, $filters: [SegmentFilterInput!]){
  questionResults(question_id: $id, segment_filters: $filters) {
    answers
    data
    rational_metric
    result_type
  }
}
`;

export {
  QUESTIONS_RESULTS_BY_ID,
  QUESTIONS_RESULTS_WITH_METRIC_ID,
};