import React from 'react';
import PropTypes from 'prop-types';

import { getStrings } from '../../utils/i18n';

const ErrorGeneral = ({ message }) => {
  const errorPage = getStrings('errorPage', { returnObjects: true });
  return (
    <div className="error__general-page">
      <h1 className="error__general-page--title">{errorPage.errorGeneralTitle}</h1>
      <div className="error__general-page--container">
        <h2 className="error__general-page--text">{errorPage.errorGeneralText}</h2>
        <h2 className="error__general-page--text">{message}</h2>
      </div>
      <a href="/" className="btn btn--primary-white">
        {errorPage.errorGeneralBtnBack}
      </a>
    </div>
  );
};

ErrorGeneral.propTypes = {
  message: PropTypes.string.isRequired,
};

ErrorGeneral.defaultValues = {
  message: '',
};

export default ErrorGeneral;
