import React from 'react';
import PropTypes from 'prop-types';

const InputBtnRadioComponent = props => (
  <input
    className="form__radio"
    value={props.value}
    type={props.type}
    onClick={props.handleClick}
    disabled={props.disabled}
    name={props.name}
  />
);

InputBtnRadioComponent.propTypes = {
  value: PropTypes.string,
  handleClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

InputBtnRadioComponent.defaultProps = {
  handleClick: () => {},
  type: '',
  value: '',
  disabled: false,
  name: '',
};

export default InputBtnRadioComponent;