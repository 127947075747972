import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Auth0Provider } from '@auth0/auth0-react';
import routes from './routes';
import { i18n } from './utils/i18n';
import { PrivateRoute, ErrorBundary } from './components';
import SignInContainer from './containers/SignInContainer';
import RedirectPage from './pages/RedirectPage';
import CallbackPage from './pages/CallbackPage';
import './app.css';

const { AUTH0_DOMAIN, AUTH0_CLIENT_ID } = process.env;

const RouterApp = () => (
  <Auth0Provider
    domain={`${AUTH0_DOMAIN}.auth0.com`}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={`${window.location.origin}/callback`}
    audience={`https://${AUTH0_DOMAIN}.auth0.com/api/v2/`}
    responseType="token id_token"
  >
    <I18nextProvider i18n={i18n}>
      <ErrorBundary>
        <Router>
          <div className="content">
            <Switch>
              <Route
                exact
                path="/"
                component={SignInContainer}
              />
              <Route
                exact
                path="/login"
                component={SignInContainer}
              />
              <Route
                exact
                path="/not-authorized"
                component={RedirectPage}
              />
              <Route
                exact
                path="/callback"
                component={CallbackPage}
              />
              <Switch>
                {
                  routes.map(route => (
                    <PrivateRoute
                      key={route.key}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                ))}
              </Switch>
            </Switch>
          </div>
        </Router>
      </ErrorBundary>
    </I18nextProvider>
  </Auth0Provider>
);

export default RouterApp;