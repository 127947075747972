import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import styles from './styles';

const IconComponent = ({
  classes, color, iconName, isEmpty, text, type, imageName,
}) => {
  if (isEmpty) return <Box className={`${classes.Icon} ${classes.IconEmpty}`}><Icon>crop_free</Icon></Box>;
  let IconType = <Icon style={{ color }}>{iconName}</Icon>;
  if (type === 'iconText') {
    IconType = <Box className={classes.IconText}>{text}</Box>;
  }
  if (type === 'iconImage') {
    const flag = `https://hatscripts.github.io/circle-flags/flags/${imageName}.svg`;

    if (flag) {
      IconType = (<img
        className={classes.IconImage}
        src={flag}
        alt="flag country"
      />);
    } else {
      IconType = <Box className={classes.IconText}>{imageName.toUpperCase() || '-'}</Box>;
    }
  }
  return (
    <Box className={classes.Icon}>
      {IconType}
    </Box>
  );
};

IconComponent.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  type: PropTypes.string,
  text: PropTypes.string,
  imageName: PropTypes.string,
  iconName: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.shape({
    Icon: PropTypes.string,
    IconEmpty: PropTypes.string,
    IconText: PropTypes.string,
    IconImage: PropTypes.string,
  }).isRequired,
};

IconComponent.defaultProps = {
  type: 'icon',
  text: '',
  iconName: 'crop_free',
  imageName: '',
  color: '',
};

export default withStyles(styles)(IconComponent);
