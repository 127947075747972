import pallete from '../palette';

export default {
  tooltip: {
    fontFamily: 'roboto',
    fontSize: '1.4rem',
    color: pallete.white,
    backgroundColor: pallete.primary.main,
  },
  arrow: {
    color: pallete.primary.main,
  },
};