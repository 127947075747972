const drawerWidth = 200;
const drawerWidthLittle = 60;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    zIndex: '1',
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerClosed: {
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthLittle,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    overflowY: 'inherit',
    padding: 0,
  },
  drawerPaperLittle: {
    width: drawerWidthLittle,
    overflowY: 'inherit',
    boxSizing: 'border-box',
    padding: 0,
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    width: 'calc(100% - 200px)',
  },
  contentShadow: {
    flexGrow: 1,
    position: 'relative',
    zIndex: 1,
    width: 'calc(100% - 200px)',
    '& > div': {
      height: 'calc(100% - (-40px))',
      minHeight: '100vh',
      width: '100%',
    },
  },
});

export default styles;