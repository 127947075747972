import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, TextField } from '@material-ui/core';

const useStyles = makeStyles(({ palette }) => ({
  typography: {
    marginBottom: '0',
  },
  icon: {
    height: '2.4rem',
    width: '2.4rem',
    fontSize: '2.4rem',
    display: 'block',
  },
  inputTitle: {
    fontSize: '3.75rem',
    color: palette.primary.main,
  },
}));

const ContainerTitle = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '2.4rem 0',
  },
})(Box);

const ModalTitle = withStyles({
  root: {
    margin: 0,
  },
})(ContainerTitle);

const TextInput = withStyles(({ palette }) => ({
  root: {
    marginBottom: '0',
    maxWidth: 600,
    color: palette.primary.main,
    fontSize: '1.6rem',
  },
}))(TextField);

export {
  useStyles,
  ContainerTitle,
  TextInput,
  ModalTitle,
};