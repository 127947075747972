import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  title: {
    fontSize: '60px',
  },
});

const NumberChartComponent = (props) => {
  const {
    value,
    emoji,
    isMilliseconds,
    classes,
    measure,
  } = props;
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={3}>
      {emoji && (
        <Box fontSize={40} mr={1}>
          <span aria-label="metric" role="img">{emoji}</span>
        </Box>
      )}
      <Box>
        <Typography variant="h1" align="center" color="primary" display="inline" className={classes.title}>
          {isMilliseconds ?
            parseFloat(value).toFixed(1) / 1000 :
            parseFloat(value).toFixed(1)
          }
        </Typography>
        {
          measure === 'points' ?
            <Typography variant="h3" display="inline" color="primary" >
              { measure === 'points' ? 'PTS' : '%' }
            </Typography> :
            <Typography variant="h3" display="inline" color="primary" >
              { measure === 'seconds' ? ' seconds' : '%' }
            </Typography>
        }
      </Box>
    </Box>
  );
};

NumberChartComponent.propTypes = {
  value: PropTypes.string.isRequired,
  emoji: PropTypes.string,
  isMilliseconds: PropTypes.bool.isRequired,
  classes: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

NumberChartComponent.defaultProps = {
  emoji: null,
};

export default withStyles(styles)(NumberChartComponent);
