import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useQuery } from '@apollo/client';
import ErrorSnackbarContent from './component/ErrorSnackbarContent';
import { ERROR_QUERY } from '../../Apollo/queries.gql';
import useStateManagment from '../../hooks/useStateManagment';

const ErrorComponent = () => {
  const { data, loading, error } = useQuery(ERROR_QUERY);
  if (loading) return 'loading...';
  if (error) return `Error: ${error.message}`;
  const { open, type, message } = data.error;
  const { setError } = useStateManagment();

  const handleClose = () => {
    setError({ error: { open: false, type, message: '' } });
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <ErrorSnackbarContent
        onClose={handleClose}
        variant={type}
        message={message}
      />
    </Snackbar>
  );
};

export default ErrorComponent;