const styles = theme => ({
  paper: {
    position: 'relative',
    minHeight: '100%',
    '& .menudot': {
      display: 'none',
    },
    '&:hover .menudot': {
      display: 'block',
    },
  },
  profileCard: {
    height: '100%',
  },
  iconContainer: {
    minWidth: '4.8rem',
    height: '4.8rem',
    width: '4.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    marginRight: '1.6rem',
    borderRadius: '1rem',
    boxShadow: theme.shadows[1],
  },
  icon: {
    height: '2.4rem',
    width: '2.4rem',
    fontSize: '2.4rem',
    display: 'block',
    color: theme.palette.primary.main,
  },
  simpleIcon: {
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.black,
    wordBreak: 'break-word',
  },
  simpleTitle: {
    color: theme.palette.black,
  },
  subtitle: {
    fontSize: '1.2rem',
    lineHeight: '1.4rem',
    fontWeight: 'normal',
  },
});

export default styles;