import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import emojiTrim from 'emoji-trim';
import TextField from '@material-ui/core/TextField';

const useStyle = makeStyles({
  disabled: {
    '&.MuiTextField-root > div > input': {
      cursor: 'not-allowed',
    },
  },
});

const InputFieldComponent = (props) => {
  const [value, useValue] = useState(props.value);
  const valueLength = props.value ? props.value.length : 0;
  const [leng, useLeng] = useState(props.maxlength - valueLength);

  useEffect(() => {
    useValue(props.value);
    useLeng(props.maxlength - valueLength);
  }, [props.value]);

  const classes = useStyle();
  return (
    <TextField
      label={props.label}
      placeholder={props.placeholder}
      maxLength={props.maxlength}
      className={props.disabled ? classes.disabled : props.classes.textField}
      type={props.type}
      value={typeof value === 'string' ? value.substring(0, props.maxlength) : value}
      name={props.name}
      max={props.max}
      min={props.min}
      onChange={(e) => {
        const text = e.target.value;
        e.target.value = emojiTrim(text);
        useValue(e.target.value);
        useLeng(props.maxlength - e.target.value.length);
        props.handleChange(e);
      }}
      margin="normal"
      disabled={props.disabled}
      autoFocus={props.autofocus}
      multiline={props.multiline}
      rows={props.rows}
      required={props.required}
      variant="outlined"
      helperText={props.helperText}
      onInput={(e) => {
        e.target.value = e.target.value.substring(0, props.maxlength);
      }}
      error={props.error}
      InputProps={{
        endAdornment: (
          <Box>
            {props.withoutCounter && (
              <Box position="end">
                {
                  leng < props.maxlength ?
                    (<Box
                      className={
                        leng <= 50
                        ? 'form__input-character-number--actived form__input-character-number--danger'
                        : 'form__input-character-number--actived'
                      }
                    >
                      {props.disabled ? null : leng}
                    </Box>) :
                    (leng && <Box className="form__input-character-number--actived">{props.disabled ? null : leng}</Box>)
                }
              </Box>
            )}
          </Box>
        ),
      }}
    />);
};

InputFieldComponent.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  maxlength: PropTypes.number,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  autofocus: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  classes: PropTypes.shape({
    textField: PropTypes.string,
  }),
  withoutCounter: PropTypes.bool,
  error: PropTypes.bool,
};

InputFieldComponent.defaultProps = {
  maxlength: 0,
  required: false,
  placeholder: '',
  label: '',
  handleChange: () => { },
  disabled: false,
  max: 0,
  min: 0,
  rows: 1,
  autofocus: false,
  multiline: false,
  classes: {},
  withoutCounter: true,
  helperText: null,
  error: false,
};

export default InputFieldComponent;