import STUDY from './actions/study.action';
import { RESET } from './actions/general.action';

const initialState = {
  study: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case STUDY: return { ...state, ...action.payload };
    case RESET: return initialState;
    default: return state;
  }
};

export default [reducer, initialState];
