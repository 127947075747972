import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveLine } from '@nivo/line';

class LineChartGeneralComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
  }

  setStateValue(state) {
    this.setState(state);
  }

  render() {
    const { data } = this.state;
    return (
      <Fragment>
        <div className="bar__container">
          <ResponsiveLine
            data={data}
            margin={{
              top: 50,
              right: 10,
              bottom: 60,
              left: 60,
            }}
            xScale={{
              type: 'point',
            }}
            yScale={{
              type: 'linear',
              stacked: true,
              min: 'auto',
              max: 'auto',
            }}
            curve="natural"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'count',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            enableDots={false}
            dotSize={10}
            dotColor="inherit:darker(0.3)"
            dotBorderWidth={2}
            dotBorderColor="#ffffff"
            enableDotLabel
            dotLabel="y"
            dotLabelYOffset={-12}
            animate
            motionStiffness={90}
            motionDamping={15}
            enableGridX={false}
            enableGridY={false}
            colors="#261a7c"
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 60,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 10,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </Fragment>
    );
  }
}

LineChartGeneralComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default LineChartGeneralComponent;
