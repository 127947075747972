import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '../Button';

const ButtonsGeneral = props => (
  <Grid container spacing={1}>
    <Grid item xs={12} md={3}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        fullWidth
        onClick={props.handleEventBack}
      >
        {props.textBack}
      </Button>
    </Grid>
    <Grid item xs={12} md={9}>
      <Button
        variant="contained"
        color="inherit"
        extend="positive"
        size="small"
        fullWidth
        onClick={props.handleNextStep}
        disabled={props.disabled}
        loading={props.loading}
        type={props.type}
      >
        {props.textBold} {props.textNext}
      </Button>
    </Grid>
  </Grid>
);

ButtonsGeneral.propTypes = {
  handleEventBack: PropTypes.func.isRequired,
  textBack: PropTypes.string.isRequired,
  textNext: PropTypes.string.isRequired,
  textBold: PropTypes.string,
  handleNextStep: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

ButtonsGeneral.defaultProps = {
  textBold: '',
  loading: false,
  type: 'button',
  handleNextStep: () => {},
};

export default ButtonsGeneral;