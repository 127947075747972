import combineReducers from 'react-combine-reducers';
import filtersReducer from './reducers/filters.reducer';
import taskMediaReducer from './reducers/taskMedia.reducer';
import studyReducer from './reducers/study.reducer';

const reducers = combineReducers({
  filtersCtx: filtersReducer,
  taskMediaCtx: taskMediaReducer,
  studyCtx: studyReducer,
});

export default reducers;