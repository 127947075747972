export default {
  translations: {
    notAuthorized: {
      message: 'This user can not have access to this app.',
      button: 'BACK TO LOGIN',
    },
    mainMenu: {
      titleCampaigns: 'Campaigns',
      titleOrganization: 'Organization',
      titleOrganizationClosed: 'org',
      titleCampaignsClosed: 'camps',
      menuSectionCampaigns: {
        ItemCampaigns: 'Campaigns',
        ItemStudies: 'Studies',
        ItemNewCampaign: 'New campaign',
      },
      menuSectionUser: 'Users',
      menuSectionClient: 'Clients',
      menuSectionTags: 'Tags',
      logout: 'Log Out',
    },
    login: {
      btnLogin: 'LOG IN',
      titleWelcome: 'Welcome!',
      subtitleWelcome: 'Enter your username/email address and password to continue to Synapbox platform',
      labelEmail: 'Email address',
      labelPassword: 'Password',
      emailPlaceholder: 'Write an email here',
      passwordPlaceholder: '****',
      titleRightOne: 'Not measuring your consumer\'s',
      titleRightTwo: 'reactions with Synapbox yet?',
      subtitleRight: 'Unlock the Power Behind your Customers Smiles',
      lastText: 'LEARN HOW TODAY',
    },
    general: {
      pageName: 'My Campaign',
      errorGeneral: 'Unexpected error',
      questionName: 'Question',
      taskName: 'Task',
      testing: 'Testing device:',
      buttons: {
        exportQuestions: 'EXPORT ALL QUESTIONS ANSWERS',
        next: 'NEXT:',
        back: 'BACK',
        save: 'SAVE',
        backHome: 'BACK HOME',
        select: 'select',
        edit: 'EDIT',
        delete: 'DELETE',
        duplicate: 'DUPLICATE',
        addQuestion: 'question',
        add: 'Add',
        update: 'Update',
        addElement: 'Add element',
        addRow: 'Add row',
        addColumn: 'Add column',
        addAnswer: 'Add answer',
        addOther: 'Add the option OTHER',
        or: 'or',
        textCampaign: 'test campaign',
        deleteCampaign: 'DELETE CAMPAIGN',
        duplicateCampaign: 'DUPLICATE CAMPAIGN',
        exportCampaing: 'EXPORT CAMPAIGN',
        updateTask: 'EDIT TASK',
        addTask: 'ADD TASK',
        launch: 'Launch link to field',
        cancel: 'CANCEL',
        close: 'LOGOUT',
        closeCampaign: 'CLOSE',
        modifyRedirects: 'Modify Redirects',
      },
    },
    errorPage: {
      title: 'Sometimes getting lost isn\'t so bad',
      titleHeader: 'Error page - Error code 404',
      errorTitle: 'ERROR CODE',
      errorCode: '404',
      errorContent: 'The page you requested was removed, renamed or doesn\'t exist.',
      errorBtnBack: 'Back to my campaigns',
      errorGeneralBtnBack: 'Back',
      errorGeneralTitle: 'Sorry',
      errorGeneralText: 'Looks like something went wrong.',
    },
    dashboard: {
      title: 'Campaign manager',
      titles: {
        launch: 'Launch link to field',
        complete: 'Complete task guide',
        what: 'What is a campaign?',
        draft: 'COMPLETE CAMPAIGN',
        in_progress: 'TRACK LINK',
        paused: 'TRACK LINK',
        completed: 'SEE RESULTS',
      },
      status: {
        draft: 'Draft',
        in_progress: 'In progress',
        completed: 'Completed',
        paused: 'Paused',
      },
      menu: {
        name: 'campaing',
        create: 'Create',
        all: 'All',
        draft: 'Draft',
        in_progress: 'InProgress',
        completed: 'Completed',
      },
      search: {
        placeholder: 'Search',
        button: 'Search',
      },
      resultSearch: {
        title: 'Search results',
        result: 'We got 0 results according to your search',
        message: 'Try looking for your brand name or by type of content like videos or images',
      },
      initialView: {
        title: 'Welcome!',
        message: 'Let\'s begin by creating your first campaign',
      },
    },
    createCampaign: {
      title: 'Campaign definition',
      subtitle: 'New campaign / ',
    },
    editCampaign: {
      tasks: {
        title: 'Setup',
        subtitle: 'New campaign / ',
      },
    },
    steps: {
      pageName: 'My Campaign',
      step1: {
        breadcrumb: 'Campaign creator: Step 1 - Campaign definition',
        title: '1. Campaign definition',
      },
      step2: {
        breadcrumb: 'Campaign creator: Step 2 - Campaign preview',
        title: '2. Tasks & Questions',
      },
      step3: {
        breadcrumb: 'Campaign creator: Step 3 - Campaign launch',
        title: '3. Participants selection',
      },
    },
    summary: {
      campaignId: 'Campaign ID',
      objective: 'Objective',
      description: 'Description',
      biometrical: 'Analysis type',
      biometricSurvey: 'Biometric + Survey',
      survey: 'Survey',
    },
    modal: {
      deleteTag: {
        title: 'Information',
        message: 'Are you sure to delete the selected tag',
      },
      deleteCategoryTag: {
        title: 'Information',
        message: 'Are you sure to delete the selected category tag',
      },
      deleteTester: {
        title: 'Information',
        message: 'Are you sure to delete the select testers',
      },
      closessesion: {
        title: 'Information',
        message: ' Are you sure you want to log out?',
      },
      strCampaign: {
        title: 'You are about to delete the campaign. Are you sure you want to delete it?',
        message: 'All the information related to this campaign will be deleted.',
      },
      duplicateCampaign: {
        title: 'You are about to duplicate the campaign. Are you sure you want to do it?',
        message: 'All the information related to this campaign will be duplicated.',
      },
      strQuestion: {
        title: 'You are about to delete a question. Are you sure you want to delete it?',
        message: 'All the information related to this question will be deleted.',
      },
      strTask: {
        title: 'You are about to delete a task. Are you sure you want to delete it?',
        message: 'All the information related to this task will be deleted.',
        titleDuplicate: 'Your are about to duplicate a task. Are you sure you want to duplicate it?',
        messageDuplicate: 'All the information related to this task will be copy.',
      },
      buttons: {
        deleteQuestion: 'DELETE QUESTION',
        deleteTask: 'DELETE TASK',
        duplicateTask: 'DUPLICATE TASK',
      },
      closeCampaign: {
        title: 'Your are about to close this campaign',
        message: 'Are you sure to close it',
      },
    },
    campaignDefinition: {
      sectionCampaingGeneral: '1. Name and describe your campaign',
      sectionCampaingDevice: '2. Select the device that will be used to apply this study',
      sectionCampaingLanguages: '3. Select the language of the study',
      sectionCampaingOptions: '4. Select the type of analysis you want to receive',
      sectionQuota: '5. Select the minimum participants by stimuli',
      title: 'Campaign title',
      objective: 'Objective(s)',
      description: 'Description',
      buttons: {
        nextTitle: 'Tasks & Questions',
      },
    },
    campaignPreview: {
      title: 'Tasks and Questions',
      subtitle: 'Editing campaign / ',
      typeQuestion: 'question',
      typeTask: 'task',
      panels: {
        first: '1. Create the first task or question for your campaign',
        notQuestion: 'No question has been created yet',
        profile: {
          title: 'Filter in Profile',
        },
        task: {
          title: 'Tasks and Questions',
          notTask: 'No task has been created yet',
          notQuestion: 'No question has been created yet',
        },
        exit: {
          title: 'Exit Questions',
        },
      },
      buttons: {
        createTask: 'CREATE TASK',
        createQuestion: 'CREATE QUESTION',
        nextTitle: 'Participants selection',
        addProfile: 'Add profile question',
        addTask: 'Add task',
        addExit: 'Add exit question',
        addQuestion: 'Add question',
      },
      btnYes: 'YES',
      btnNo: 'NO',
      labelRandom: 'Display tasks randomly',
    },
    campaingLaunch: {
      title: 'Setup your participants and we should be ready to test your campaign!',
      panels: {
        participants: {
          label: 'Minimum participants by stimuli',
          tooltip: '*Starting from 5.',
        },
        panel: {
          title: '6. Select the panel you would like to use',
          organic: 'Organic invitation',
        },
      },
      countries: {
        title: '2. Select the country you would like to use',
        placeholder: 'Select countries',
        noSelected: 'Select a country',
        currentLiving: 'Currently living in ',
        total: 'Total',
        participants: 'Participants',
      },
      clients: {
        title: '1. Select the client you would like to use',
        placeholder: 'Select client',
        noSelected: 'Select a client',
      },
    },
    questionnaires: {
      title: 'Question to campaign',
      panels: {
        question: {
          title: '1. Define your question',
          label: 'Question',
          rational: 'Rational metric',
        },
        answer: {
          title: '2. Define the question type and answers',
          label: 'Type of question',
        },
      },
      menu: {
        open: 'Open',
        openSubtitle: 'Textbox',
        multiOption: 'Multiple Option',
        multiOptionSubtitle: 'Radio or checkbox',
        scale: 'Rating Scale',
        scaleSubtitle: 'Dragable slider along a range',
        matrix: 'Matrix',
        matrixSubtitle: 'Select one option for each row',
        importance: 'Importance',
        importanceSubtitle: 'Drag & drop to rank a list of items',
      },
      titles: {
        create: 'Create Question',
        edit: 'Edit Question',
      },
    },
    answers: {
      answer: 'Answer',
      open: {
        short: 'Short answer Max. 140 charac.',
        long: 'Long answer Max. 280 charac.',
        messageValidation: 'To continue you should select one option',
      },
      multiple: {
        mustTitle: 'Must',
        terminateTitle: 'Terminate',
        placeholder: 'Write the answer...',
        placeholderQuota: 'Quota',
        messageValidation: 'To continue you should add at least two answers',
        logicTitle: 'Answers logic',
        loadFile: 'ATTACH IMAGE',
        uploadFile: 'UPLOAD IMAGE',
        uploadFilePlaceHolder: 'Please select an image file…',
        answerSimple: 'Simple Answer',
        answerMultiple: 'Multiple Answers',
        none: 'None',
        must: 'Must',
        terminate: 'Terminate',
      },
      strScale: {
        label: 'Scale range',
        from: 'from',
        to: 'to',
        placeholder: 'Add label...',
        messageValidation: 'To continue you must add a valid number greater than zero, less than 10 and a difference of 2 in the range FROM - TO.',
      },
      strMatrix: {
        label: 'Set rows and columns',
        rows: 'ROWS',
        columns: 'COLUMNS',
        row: 'ROW',
        column: 'COL',
        placeholder: 'Add label...',
        messageValidation: 'To continue you should add at least two columns and two rows',
      },
      strImportance: {
        element: 'Element',
        title: 'Elements to order',
        label: 'Set your options',
        placeholder: 'Add label...',
        messageValidation: 'To continue you should add at least two answers to order',
      },
    },
    tasks: {
      bar: {
        page: 'My Campaigns',
        breadcrumb: {
          first: 'Campaign creator',
          second: 'Task creator',
        },
      },
      menu: {
        image: 'Images',
        video: 'Videos',
        survey: 'Survey',
        web: 'Web Page',
        mobile: 'Mobile App Web Proptotype',
      },
      panels: {
        strType: {
          title: 'Content Type',
        },
        content: {
          title: 'Select Content',
        },
        images: {
          title: 'Duration',
          label: 'Image',
          upload: 'UPLOAD FILE IMAGE',
          change: 'CHANGE',
          add: 'Add image',
          placeholder: 'Select the image...',
          tooltips: {
            max: 'Max. number of images:',
            format: 'Formats:',
            size: 'Max. size per file:',
          },
        },
        strVideos: {
          title: 'Duration',
          label: 'Video',
          upload: 'UPLOAD FILE VIDEO',
          change: 'CHANGE',
          add: 'Add video',
          placeholder: 'Select the video...',
          tooltips: {
            max: 'Max. number of videos:',
            format: 'Formats:',
            size: 'Max. size per file:',
          },
        },
        strTask: {
          title: 'Task Details',
          labels: {
            name: 'Task name',
            instruction: 'Task instruction',
            duration: 'Task total length',
            select: 'Select the duration',
          },
        },
        titles: {
          create: 'Create Task',
          edit: 'Edit Task',
        },
      },
      errors: {
        imageNeed: 'You need at least one image',
        videoNeed: 'You need at least one video',
        imageSize: 'ERROR: Image size',
        videoSize: 'ERROR: Video size',
        videoFormat: 'ERROR: Video format',
        imageFormat: 'ERROR: Image format',
        mediaFormat: 'Error: Media file format',
        mediaSize: 'Error: Media file size',
        noImage: 'No image',
      },
    },
    previewExport: {
      campaignID: 'Campaign ID:',
      objective: 'Objective(s):',
      description: 'Description:',
      kindStudy: 'Kind of stydy:',
      device: 'Device:',
      code: 'Study code',
      language: 'Language:',
      quota: 'Quota:',
      Panel: 'Panel:',
      kind: 'Type of study:',
      question: 'Question:',
      answer: 'Answer:',
      name: 'Name:',
      instruction: 'Instruction:',
      kindContent: 'Type of content:',
      timeDuration: 'Time duration:',
      urlContent: 'URL of content:',
      noOption: 'No options',
      title: {
        profile: 'Profile questions',
        task: 'Tasks and questions',
        exit: 'Exit questions',
      },
    },
    tracker: {
      modal: {
        mainButton: 'Modify Redirects',
        subtitle: 'Modify the panel urls here',
        buttonCancel: 'Cancel',
        buttonChange: 'Change',
        helperText: 'Change tester id param to four X (XXXX)',
        validationIdentifier: 'Tester id param invalid, use XXXX',
        validationUrl: 'Url not valid',
        defaultInput: 'Url:',
        warningText: 'If you send an empty field the default Url will be used instead.',
      },
      bar: {
        page: 'My Campaign',
        breadcrumb: {
          first: 'Campaign tracker: Test link ready to launch',
        },
      },
      tabs: {
        test: 'Preview link',
        campaign: 'Campaign link',
        about: 'About the campaign',
      },
      link: {
        test: 'Preview link',
        production: 'Campaign links',
        copy: 'COPY LINK',
      },
      components: {
        cuotaParticipants: {
          sampleSize: 'Sample size',
          participantsLeft: 'Participants left',
          participantsCompleted: 'Participants completed',
        },
        timeParticipants: {
          title: 'AVERAGE TIME TO COMPLETE STUDY',
          min: 'min',
          sec: 'sec',
          days: 'Days',
          hour: 'hrs',
          titleDays: 'DAYS TO COMPLETE CAMPAIGN',
          completionRate: 'COMPLETION RATE',
          bounceRate: 'BOUNCE RATE',
          daysToComplete: 'DAYS TO COMPLETE FIELDING',
        },
        testerPie: {
          byStatus: 'Testers by status',
          rejected: 'Rejected Testers Distribution',
        },
        buttons: {
          pause: 'Pause Campaign',
          resume: 'Resume Campaign',
          close: 'Close Campaign',
        },
      },
      copied: 'Copied',
      linkLaunched: 'LINK LAUNCHED',
      titleChars: 'Link Performance',
      titleTesters: 'Testers Info',
      titleAllAnswers: 'All Answers',
      attentionScoreTitle: 'Attention Score',
      emotionsTitle: 'Emotions',
      taskPlaceholder: 'Tasks',
      answersByTester: 'Answers by Tester',
      attentionScore: 'Attention Score',
      exitQuestions: 'Exit Questions',
      charts: {
        bar: {
          title: 'Filtered:',
        },
        sankey: {
          title: 'Activity funnel:',
        },
      },
      all: 'All',
    },
    question: {
      label: 'Question image (optional)',
      upload: {
        placeholder: 'Please select a media file…',
        load: 'Upload file',
        change: 'change file',
      },
    },
    dataTable: {
      previousText: 'Previous',
      nextText: 'Next',
      noDataText: 'No rows found',
      pageText: 'Page',
      ofText: 'of',
      rowsText: 'rows',
    },
    visualization: {
      menu: ['Tracker', 'Profile', 'Individual result', 'General results'],
      about: {
        answerYes: 'Yes',
        answerNo: 'No',
        optionsTable: {
          name: 'Campaign name /\n Campaign attributes',
          objective: 'Objectives',
          description: 'Description',
          biometrical_analysis: 'Biometrical Analysis',
          language: 'Language',
          quota: 'Quota',
          id: 'Id',
        },
      },
      sample: {
        titleRationalQuestions: 'Rational questions',
        titleQuestion: 'Question #',
        titleClosed: 'Closed answer',
        averageTitle: 'Average: ',
      },
      multipleChart: {
        answersTitle: 'Answers',
        tableTitle: 'Table Data',
      },
    },
    user: {
      title: 'Users manager',
      createUser: {
        title: 'Create user',
        subtitle: 'Users manager / ',
      },
      editUser: {
        title: 'Edit user',
        subtitle: 'Users manager / ',
      },
      labels: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        password: 'Password',
        passwordConfirm: 'Confirm Password',
        userName: 'username',
      },
      noSelected: 'Select a role',
      takenEmail: 'Email aready taken, choose another one.',
      passwordnotmatch: 'Passwords do not match',
      invalidPassword: 'the password must be at least six characters and should be a mix of uppercase and lowercase characters, and optionally numbers and punctuation',
      deleteModal: {
        title: 'Delete user',
        subtitle: 'Are you sure to delete the selected user?',
        content: 'you will not be able to undo this action',
        actionText: 'Delete',
      },
      updateUser: {
        title: 'Edit user',
        subtitle: 'User manager / ',
      },
    },
    Organization: {
      title: 'Organizations manager',
      invalidCreation: 'your organization is invalid',
      createOrganization: {
        title: 'Creating Organization',
        subtitle: 'Organization manager / ',
      },
      labels: {
        name: 'Organization name',
      },
      deleteModal: {
        title: 'Delete Organization',
        subtitle: 'Are you sure to delete the selected Organization?',
        content: 'you will not be able to undo this action',
        actionText: 'Delete',
      },
      updateOrganization: {
        title: 'Edit Organization',
        subtitle: 'Organization manager / ',
      },
    },
    clientPage: {
      title: 'Clients manager',
      createClient: {
        title: ' Creating client',
        subtitle: 'Clients manager /',
      },
    },
    tag: {
      title: 'Tags manager',
      createTag: {
        title: 'Creating tag',
        subtitle: 'Tags manager / ',
      },
      updateTag: {
        title: 'Edit tag',
        subtitle: 'Tags manager / ',
      },
      labels: {
        name: 'Name',
        category: 'Category',
      },
      noSelected: 'Select a category',
    },
    category: {
      title: 'category manager',
      createCategory: {
        title: 'Creating Category',
        subtitle: 'Tag manager / ',
      },
      labels: {
        name: 'Name',
        category: 'Category',
      },
      noSelected: 'Select a category',
    },
    snapshot: 'Download the current frame displayed on the player.',
    'min-fix-time': 'The minimum time participants took to fixate on the area of interest.',
    fixation: 'The percentage of visual fixations concentrated on an area of interest during exposure time.',
    participants: 'The percentage of participants who observed the area of interest in their exposure time.',
    'escale-emotional-arousal': 'Emotional Arousal',
    'scale-atention-rational': 'Rational Metrics',
    'metrics-tooltip': 'See and analyze the result of all metrics (rational and biometric) included in the study evaluation.',
    emotional: 'Deep understanding of your stimulus\' second by second visual and emotional data performance.',
    valance: 'Positive & Negative',
    'attention-score': 'Emotional Arousal & Attention Score',
    'emotional-arousal': 'Emotional Arousal',
    'aois-title': 'Areas Of Interest',
    heatmaps: 'Heatmaps',
  },
};