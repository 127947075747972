import palette from './palette';

export default {
  useNextVariants: true,
  fontSize: 20,
  fontFamily: 'roboto',
  h1: {
    color: palette.text.primary,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '48px',
    lineHeight: '56px',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 'bold',
    fontSize: '34px',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 'bold',
    fontSize: '18px',
    letterSpacing: '0.04em',
    lineHeight: '24px',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.02em',
    lineHeight: '24px',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '24px',
    letterSpacing: '-0.05px',
    lineHeight: '28px',
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 'bold',
    fontSize: '20px',
    letterSpacing: '0.05em',
    lineHeight: '23px',
  },
  body1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '18px',
    letterSpacing: '-0.05px',
    lineHeight: '26px',
  },
  body2: {
    color: palette.text.secondary,
    fontWeight: 'normal',
    fontSize: '16px',
    letterSpacing: '-0.04px',
    lineHeight: '22px',
  },
  button: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  caption: {
    color: palette.text.secondary,
    fontWeight: 'normal',
    fontSize: '12px',
    letterSpacing: '0.33px',
    lineHeight: '14px',
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '10px',
    fontWeight: 900,
    letterSpacing: '0.1em',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
};