import { useApolloClient } from '@apollo/client';
import { ERROR_QUERY, STATUS_DIALOG } from '../Apollo/queries.gql';

const useStateManagment = () => {
  const client = useApolloClient();

  const setError = (data) => {
    client.writeQuery({
      query: ERROR_QUERY,
      data,
    });
  };

  const setDialog = (data) => {
    client.writeQuery({
      query: STATUS_DIALOG,
      data,
    });
  };

  return {
    setError,
    setDialog,
  };
};

export default useStateManagment;