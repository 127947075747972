import { AbilityBuilder } from '@casl/ability';

/**
 * Defines how to detect object's type: https://stalniy.github.io/casl/abilities/2017/07/20/define-abilities.html
 */
const CAMPAIGN_CONTEXT = '_CAMPAIGN_CONTEXT';
const CAMPAIGN_PREVIEW = '_CAMPAIGN_PREVIEW';
const CAMPAIGN_LAUNCH = '_CAMPAIGN_LAUNCH';

const DRAFT_CAMPAIGN_CONTEXT = `DRAFT${CAMPAIGN_CONTEXT}`;
const DRAFT_CAMPAIGN_PREVIEW = `DRAFT${CAMPAIGN_PREVIEW}`;
const DRAFT_CAMPAIGN_LAUNCH = `DRAFT${CAMPAIGN_LAUNCH}`;

const IN_PROGRESS_CAMPAIGN_CONTEXT = `IN_PROGRESS${CAMPAIGN_CONTEXT}`;
const IN_PROGRESS_CAMPAIGN_PREVIEW = `IN_PROGRESS${CAMPAIGN_PREVIEW}`;
const IN_PROGRESS_CAMPAIGN_LAUNCH = `IN_PROGRESS${CAMPAIGN_LAUNCH}`;

const PAUSED_CAMPAIGN_CONTEXT = `PAUSED${CAMPAIGN_CONTEXT}`;
const PAUSED_CAMPAIGN_PREVIEW = `PAUSED${CAMPAIGN_PREVIEW}`;
const PAUSED_CAMPAIGN_LAUNCH = `PAUSED${CAMPAIGN_LAUNCH}`;

const COMPLETED_CAMPAIGN_CONTEXT = `COMPLETED${CAMPAIGN_CONTEXT}`;
const COMPLETED_CAMPAIGN_PREVIEW = `COMPLETED${CAMPAIGN_PREVIEW}`;
const COMPLETED_CAMPAIGN_LAUNCH = `COMPLETED${CAMPAIGN_LAUNCH}`;

export default AbilityBuilder.define((can) => {
  // permision campaing context for status
  can('manage', DRAFT_CAMPAIGN_CONTEXT, ['title', 'objective', 'description', 'language', 'platform', 'biometrical', 'quota', 'panel', 'country', 'client']);
  can('read', [IN_PROGRESS_CAMPAIGN_CONTEXT, PAUSED_CAMPAIGN_CONTEXT], ['language', 'platform', 'biometrical']);
  can('create', [IN_PROGRESS_CAMPAIGN_CONTEXT, PAUSED_CAMPAIGN_CONTEXT], ['title', 'objective', 'description', 'quota', 'panel']);
  can('create', COMPLETED_CAMPAIGN_CONTEXT, ['title', 'country', 'client', 'quota']);
  can('read', COMPLETED_CAMPAIGN_CONTEXT, ['objective', 'description', 'language', 'platform', 'biometrical']);
  // permision campaing preview for status
  // can('manage', DRAFT_CAMPAIGN_PREVIEW);
  can('manage', [PAUSED_CAMPAIGN_PREVIEW, DRAFT_CAMPAIGN_PREVIEW]);
  can('update', IN_PROGRESS_CAMPAIGN_PREVIEW);
  can('read', [PAUSED_CAMPAIGN_PREVIEW, COMPLETED_CAMPAIGN_PREVIEW]);
  // permision campaing launch for status
  can('manage', DRAFT_CAMPAIGN_LAUNCH);
  can('read', [IN_PROGRESS_CAMPAIGN_LAUNCH, PAUSED_CAMPAIGN_LAUNCH, COMPLETED_CAMPAIGN_LAUNCH]);
});

export {
  CAMPAIGN_CONTEXT,
  CAMPAIGN_PREVIEW,
  CAMPAIGN_LAUNCH,
  DRAFT_CAMPAIGN_CONTEXT,
  DRAFT_CAMPAIGN_PREVIEW,
  DRAFT_CAMPAIGN_LAUNCH,
  IN_PROGRESS_CAMPAIGN_CONTEXT,
  IN_PROGRESS_CAMPAIGN_PREVIEW,
  IN_PROGRESS_CAMPAIGN_LAUNCH,
  PAUSED_CAMPAIGN_CONTEXT,
  PAUSED_CAMPAIGN_PREVIEW,
  PAUSED_CAMPAIGN_LAUNCH,
  COMPLETED_CAMPAIGN_CONTEXT,
  COMPLETED_CAMPAIGN_PREVIEW,
  COMPLETED_CAMPAIGN_LAUNCH,
};