import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TitleComponent from '../../Title';

const Transition = React.forwardRef((props, ref) => (<Slide direction="up" ref={ref} {...props} />));

const DialogConfirmation = (props) => {
  const {
    open, icon, title, size, children,
  } = props;
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      fullWidth
      maxWidth={size}
      TransitionComponent={Transition}
      aria-labelledby="dialog-slide-title"
      aria-describedby="dialog-slide-description"
      PaperProps={{ elevation: 0 }}
    >
      <DialogTitle id="dialog-slide-title">
        <TitleComponent icon={icon} title={title} variant="h3" />
      </DialogTitle>
      <DialogContent id="dialog-slide-description">
        {children}
      </DialogContent>
      <DialogActions>
        {props.buttons}
      </DialogActions>
    </Dialog>
  );
};

DialogConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.node.isRequired,
};

export default DialogConfirmation;