import React from 'react';
import loaderLogo from '../../../images/synapbox_loading.gif';

const LoaderLogoComponent = () => (
  <div className="loaderLogo-center">
    <img className="loaderLogo loaderLogo-center" width={10} src={loaderLogo} alt="loader synapbox" />
  </div>
);

export default LoaderLogoComponent;
