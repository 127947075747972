import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { PanelComponent, LoaderImageComponent } from '../';
import GET_CAMPAIGN_BY_ID from './graphql';

export class SummaryComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapse: false,
    };

    this.handleCollapseSummary = this.handleCollapseSummary.bind(this);
  }

  handleCollapseSummary() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  render() {
    return (
      <Query
        query={GET_CAMPAIGN_BY_ID}
        variables={{
          id: Number(this.props.match.params.idCampaign),
        }}
        fetchPolicy="network-only"
      >
        {({ loading, error, data }) => {
          if (error) return <div>{`Error! ${error.message}`}</div>;
          if (loading || !data) return <LoaderImageComponent />;
          return (
            <Grid container>
              <Grid item xs={12}>
                <PanelComponent
                  full
                  summary
                  id={data.allCampaigns[0].code}
                  title={data.allCampaigns[0].name}
                  objective={data.allCampaigns[0].objective}
                  description={data.allCampaigns[0].description}
                  collapse={this.state.collapse}
                  handleCollapse={this.handleCollapseSummary}
                  biometrical={data.allCampaigns[0].biometrical_analysis}
                />
              </Grid>
            </Grid>
          );
        }}
      </Query>
    );
  }
}

SummaryComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      idCampaign: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(SummaryComponent);