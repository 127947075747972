import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import { QUESTIONS_RESULTS_BY_ID } from '../../utils/apollo/queries/question';
import { LoaderLogoComponent, BarChartGeneralComponent } from '..';

const BarChartQuestion = props => (
  <Query
    query={QUESTIONS_RESULTS_BY_ID}
    variables={{
      id: props.id,
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <LoaderLogoComponent />;
      if (error) return `Error: ${error.message}`;
      const answer = JSON.parse(data.questionResults.data);
      const total = Object.values(answer).reduce((t, value) => t + value, 0);
      const dataChart = {};
      Object.keys(answer).map((k) => {
        dataChart[`${k}: ${answer[k]}`] = Number(((answer[k] * 100) / total).toFixed(1));
        return true;
      });
      return (
        <BarChartGeneralComponent
          keys={Object.keys(dataChart)}
          data={[dataChart]}
          title=""
          listColor={props.style.colors}
          layout={props.style.orientation}
          textColor={props.style.textColor}
          unit="%"
        />
      );
    }}
  </Query>
);

BarChartQuestion.propTypes = {
  id: PropTypes.string.isRequired,
  metric: PropTypes.string.isRequired,
  style: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
    orientation: PropTypes.string,
    textColor: PropTypes.string,
  }).isRequired,
};

export default BarChartQuestion;