import palette from '../palette';

export default {
  root: {
    height: '8px',
    borderRadius: '4px',
  },
  colorPrimary: {
    background: `${palette.disabled.main} !important`,
  },
  barColorPrimary: {
    background: `${palette.success.main} !important`,
  },
};