
const configurationKindsCharts = {
  profiling: {
    icon: 'person_pin',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  age: {
    icon: 'face',
    colsize: {
      xs: 12,
      sm: 6,
      md: 3,
    },
  },
  gender: {
    icon: 'wc',
    colsize: {
      xs: 12,
      sm: 6,
      md: 3,
    },
  },
  children: {
    icon: 'child_friendly',
    colsize: {
      xs: 12,
      sm: 6,
      md: 3,
    },
  },
  income: {
    icon: 'attach_money',
    colsize: {
      xs: 12,
      sm: 6,
      md: 5,
    },
  },
  occupation: {
    icon: 'person_pin',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  socialmedia: {
    icon: 'person_pin',
    colsize: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  maritalstatus: {
    icon: 'favorite',
    colsize: {
      xs: 12,
      sm: 6,
      md: 3,
    },
  },
  levelofeducation: {
    icon: 'school',
    colsize: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  brandrecall: {
    icon: 'record_voice_over',
    colsize: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  brandexposure: {
    icon: 'crop_free',
    colsize: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  fitwiththebrand: {
    icon: 'compare_arrows',
    colsize: {
      xs: 12,
      sm: 6,
      md: 4,
    },
  },
  statements: {
    icon: 'check_circle',
    colsize: {
      xs: 12,
      sm: 12,
      md: 12,
    },
  },
  messageexposure: {
    icon: 'person_add',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  purchaseintention: {
    icon: 'attach_money',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  shareability: {
    icon: 'share',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  messagerecall: {
    icon: 'record_voice_over',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  'socialmedia(rational)': {
    icon: 'smartphone',
    colsize: {
      xs: 12,
      sm: 12,
      md: 6,
    },
  },
  drivenactions: {
    icon: 'check_circle',
    colsize: {
      xs: 12,
      sm: 12,
      md: 12,
    },
  },
  generalrating: {
    icon: 'star',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  positiveemotionalimpact: {
    icon: 'sentiment_very_satisfied',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  attractiveness: {
    icon: 'photo_filter',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  negativeemotionalimpact: {
    icon: 'mood_bad',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  viralityscale: {
    icon: 'tap_and_play',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  contentattributes: {
    icon: 'style',
    colsize: {
      xs: 12,
      sm: 6,
      md: 6,
    },
  },
  likes: {
    icon: 'sentiment_very_dissatisfied',
    colsize: {
      xs: 12,
      sm: 12,
      md: 12,
    },
  },
  dislikes: {
    icon: 'sentiment_satisfied_alt',
    colsize: {
      xs: 12,
      sm: 12,
      md: 12,
    },
  },
  default: {
    icon: '',
    colsize: {
      xs: 12,
      sm: 12,
      md: 12,
    },
  },
};

export default configurationKindsCharts;