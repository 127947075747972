import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { useApolloClient } from '@apollo/client';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { GET_MAIN_MENU, SHOW_SIGN_OUT } from '../../Apollo/queries.gql';

const useStyles = makeStyles(theme => createStyles({
  menuContextRoot: {
    background: theme.palette.white,
    zIndex: '2',
    maxWidth: '25rem',
    minWidth: '25rem',
  },
  menuContext: {
    background: theme.palette.white,
    borderLeft: `2px solid ${theme.palette.divider}`,
    position: 'fixed',
    maxWidth: '25rem',
    minWidth: '25rem',
    top: 0,
    bottom: 0,
    overflow: 'auto',
  },
  content: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    position: 'absolute',
    zIndex: 3,
    boxSizing: 'content-box',
    overflow: 'auto',
    height: '100%',
    top: 0,
    right: 0,
    marginLeft: '25.2rem',
    width: 'calc(100% - 25rem)',
  },
}));

const WrapperMenuContext = ({ menu, children }) => {
  const classes = useStyles();
  const client = useApolloClient();

  useEffect(() => {
    client.writeQuery({
      query: GET_MAIN_MENU,
      data: {
        mainMenu: { isOpen: false },
      },
    });
    client.writeQuery({
      query: SHOW_SIGN_OUT,
      data: {
        showSignOut: false,
      },
    });
  }, []);

  return (
    <Box minHeight="100vh">
      <Box position="relative" className={classes.menuContextRoot}>
        <Box className={classes.menuContext}>
          {menu}
        </Box>
      </Box>
      <Box className={classes.content}>
        {children}
      </Box>
    </Box>
  );
};

WrapperMenuContext.propTypes = {
  children: PropTypes.node.isRequired,
  menu: PropTypes.node.isRequired,
};

export default WrapperMenuContext;