import { gql } from '@apollo/client';

const GET_CAMPAIGN_BY_ID = gql`
query allCampaigns($id: ID!){
  allCampaigns(filter: { id: $id}) {
    id
    code
    name
    objective
    description
    biometrical_analysis
  }
}
`;

export default GET_CAMPAIGN_BY_ID;