import React, { useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import moment from 'moment';

import { GET_CAMPAIGN_SYNC_STATUS, UPDATE_SYNC_ISSUES } from '../../graphql';
import {
  Button,
} from '../../../../components';

const SyncButton = ({ idCampaign, status }) => {
  const [hasErrorSync, setHasErrorSync] = useState(false);

  const {
    loading: isLoadingCampaignSync,
    error: errorCampaignSync,
    data: dataCampaignSync,
    refetch: refetchCampaignSync,
  } = useQuery(GET_CAMPAIGN_SYNC_STATUS, {
    variables: {
      campaignId: idCampaign,
    },
    skip: idCampaign === 'undefined',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [updateSyncIssues, { loading: isLoadingSyncIssue }] = useMutation(
    UPDATE_SYNC_ISSUES,
    {
      variables: {
        campaignId: idCampaign,
      },
      onCompleted: (result) => {
        if (!result.identifySyncIssues) {
          setHasErrorSync(true);
        }
        refetchCampaignSync();
      },
    },
  );

  const statusSync = dataCampaignSync?.campaignSyncStatus?.status;

  const disabledButton = useMemo(() => {
    if (status === 'DRAFT') return true;
    if (status === 'COMPLETED') return true;
    if (statusSync === 'Campaign in Data Collection, Differences Detected') return true;
    if (statusSync === 'Synchronization Required') return false;
    return true;
  }, [status, statusSync]);

  if (isLoadingCampaignSync) return <CircularProgress size={20} color="primary" />;
  if (errorCampaignSync) return <div style={{ color: 'red' }}>{errorCampaignSync.message}</div>;

  const synchronizedAt = dataCampaignSync.campaignSyncStatus?.synchronizedAt ?
    moment(new Date(dataCampaignSync.campaignSyncStatus?.synchronizedAt))
    : null;

  const date = synchronizedAt && synchronizedAt.isValid() ? synchronizedAt.format('DD/MM/YYYY HH:mm') : 'None';

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center" style={{ gap: 12 }}>
        <div>
          <div
            style={{
              fontSize: 12, textAlign: 'center', marginTop: 4, lineHeight: '16px',
            }}
          >
            Status: {dataCampaignSync.campaignSyncStatus.status}
          </div>
          <div style={{ fontSize: 12, textAlign: 'center', lineHeight: '16px' }}>Last Synchronized: {date}</div>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => updateSyncIssues()}
            loading={isLoadingSyncIssue}
            disabled={disabledButton || isLoadingSyncIssue}
          >
            Sync Campaign Links
          </Button>
          { hasErrorSync ? <div style={{ color: 'red', fontSize: 12, textAlign: 'center' }}>Error during synchronization</div> : undefined}
        </div>
      </Box>
    </div>
  );
};

export { SyncButton };

export default SyncButton;