import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const MetricChart = props => (
  <Grid container className="metric">
    <Grid item sm={12}>
      <div className="metric__header">{props.metric}</div>
    </Grid>
    <Grid item sm={12}>
      <div className="metric__question">{props.question}</div>
    </Grid>
    <Grid item sm={12}>{props.children}</Grid>
  </Grid>
);

MetricChart.propTypes = {
  metric: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default MetricChart;