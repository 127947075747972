import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { MiniDrawer } from '../../components';
import ErrorComponent from '../ErrorComponent';

const Layout = props => (
  <Fragment>
    <ErrorComponent />
    <MiniDrawer>
      {props.children}
    </MiniDrawer>
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;