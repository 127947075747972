import React from 'react';
import { withRouter, Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

import { LoaderLogoComponent } from '../../components/';

const SignInContainer = () => {
  const {
    isLoading, isAuthenticated, loginWithRedirect,
  } = useAuth0();

  if (isLoading) return (<div style={{ margin: '4rem 0 2rem 0' }}><LoaderLogoComponent /></div>);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  loginWithRedirect();

  return <div style={{ margin: '4rem 0 2rem 0' }}><LoaderLogoComponent /></div>;
};

SignInContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

export default withRouter(SignInContainer);