import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const GroupFormComponent = props => (
  <Grid container alignItems="flex-start" spacing={3}>
    <Grid item xs={12} md={2} className="form__label">{props.label}</Grid>
    <Grid item xs={12} md={10}>{props.children}</Grid>
  </Grid>
);

GroupFormComponent.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

GroupFormComponent.defaultProps = {
  label: '',
  children: '',
};

export default GroupFormComponent;