export default {
  root: {
    background: 'white',
  },
  option: {
    background: 'white',
    fontSize: '1.6rem',
    width: '100%',
    '&[aria-selected="true"]': {
      backgroundColor: 'white',
    },
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
};