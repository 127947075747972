import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const BoxGrayMetric = ({ messageOne, messageTwo }) => (
  <div className="time__container">
    <Fragment>
      <div className="time__container-hour">
        <div className="time__container-number">{messageOne}</div>
      </div>
      <div className="time__container-message">{messageTwo}</div>
    </Fragment>
  </div>);

BoxGrayMetric.propTypes = {
  messageOne: PropTypes.string,
  messageTwo: PropTypes.string,
};

BoxGrayMetric.defaultProps = {
  messageOne: '',
  messageTwo: '',
};

export default BoxGrayMetric;