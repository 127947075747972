import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonIcon: {
    color: theme.palette.primary.main,
  },
  button: {
    fontSize: '11px',
    fontweight: 500,
    lineHeight: '14px',
    fontStyle: 'normal',
    padding: '1.2rem',
  },
  cancelButton: {
    background: theme.palette.primary.contrastText,
    color: theme.palette.error.main,
    boxShadow: 'none',
    '&:hover': {
      background: 'transparent',
    },
  },
  duplicateButton: {
    background: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.success.light,
      color: theme.palette.success.main,
    },
    '&:active': {
      background: theme.palette.success.light,
      color: theme.palette.success.main,
    },
  },
  deleteButton: {
    background: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    '&:active': {
      background: theme.palette.error.light,
      color: theme.palette.error.main,
    },
  },
}));

export default useStyles;