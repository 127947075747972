import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getStrings } from '../../utils/i18n';

import imgLink from '../../../images/link.png';

const strTracker = getStrings('tracker', { returnObjects: true });

const LinkComponent = ({ link, label, type }) => {
  const [copied, useCopied] = useState(false);
  const setTimerOut = 1000;

  const onCopy = () => {
    useCopied(true);
    setTimeout(() => {
      useCopied(false);
    }, setTimerOut);
  };

  return (
    <div className={type === 'test' ? 'tracker__link tracker__link-test' : 'tracker__link tracker__link-production'} >
      <div className="tracker__link-content">
        <div className="tracker__link-content-text">
          <div className="tracker__link-icon" >
            <img src={imgLink} alt="link" />
          </div>
          <div className="tracker__link-text">
            <div>{label}</div>
          </div>
          <a href={`https://${link}`} target="_blank" rel="noopener noreferrer" className="tracker__link-link">
            {link}
          </a>
        </div>
        <div className="tracker__link-copy-content">
          <CopyToClipboard
            text={`https://${link}`}
            onCopy={onCopy}
          >
            <button className="tracker__link-copy">{strTracker.link.copy}</button>
          </CopyToClipboard>
          {copied ? <span className="tracker__link-copy-text">{strTracker.copied}</span> : null}
        </div>
      </div>
    </div>
  );
};

LinkComponent.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default LinkComponent;