import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const CampaignSummaryContent = props => (
  <Grid container justify="center">
    <Grid item xs={12}>
      {props.children}
    </Grid>
  </Grid>
);

CampaignSummaryContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CampaignSummaryContent;