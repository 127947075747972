import React, { Fragment } from 'react';
import PropType from 'prop-types';
import { ResponsiveSankey } from '@nivo/sankey';
import { Title } from '../../';

const setupColor = {
  clicked: '#261A7C',
  started_study: '#2541B2',
  browser_not_supported: '#CC0000',
  device_not_supported: '#CC0000',
  abandoned: '#CC0000',
  started_profile: '#4357AD',
  camera_not_allowed: '#CC0000',
  completed_profile: '#3066BE',
  started_task: '#3E78B2',
  started_exit: '#A3CEF1',
  completed: '#16A311',
};

const calcularColor = nodes => nodes.map((node) => {
  const name = node.id.split('_');
  if (`${name[0]}_${name[1]}` === 'started_task') return setupColor.started_task;
  return setupColor[node.id];
});

class SankeyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        nodes: [],
        links: [],
      },
    };
    this.nodes = [];
    this.links = [];
    this.activities = [];
    this.hasAbandoned = false;
  }

  componentDidMount = () => {
    this.buildActivityFunnel();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.data === this.props.data) return;
    this.buildActivityFunnel();
  }

  setStateValues = (values) => {
    this.setState(values);
  }

  getBackwardCompatibilityStartedStudy = () => {
    const { data } = this.props;
    if (data.started_study > 0) return data.started_study;

    if (data.started_profile <= data.started_study) return data.started_study;
    return data.started_profile;
  }

  buildActivityFunnel = () => {
    const data = { ...this.props.data };
    const keys = Object.keys(data);
    keys.push('abandoned');

    data.started_study = this.getBackwardCompatibilityStartedStudy();

    keys.map((activityKey) => {
      if (activityKey === 'clicked') this.handleClickedActivity();
      if (activityKey === 'started_study') this.handleStartedStudyActivity();
      if (activityKey === 'started_profile') this.handleStartedProfileActivity();
      if (activityKey === 'completed_profile') this.handleCompletedProfileActivity();
      if (activityKey === 'started_tasks') this.handleStartedTasksActivity();
      if (activityKey === 'started_exit') this.handleStartedExitActivity();

      return activityKey;
    });

    this.createActivityNodes();
    this.setStateValues({ data: { nodes: this.nodes, links: this.links } });
  }

  handleClickedActivity = () => {
    const clickedActivity = 'clicked';
    let testersWithActivity = 0;
    const { data } = this.props;

    if (data.browser_not_supported > 0) this.linkActivities(clickedActivity, 'browser_not_supported', data.browser_not_supported);
    if (data.device_not_supported > 0) this.linkActivities(clickedActivity, 'device_not_supported', data.device_not_supported);
    if (data.started_study > 0) this.linkActivities(clickedActivity, 'started_study', data.started_study);

    testersWithActivity =
    data.browser_not_supported + data.device_not_supported + data.started_study;
    this.handleAbandonedActivity(data.clicked, testersWithActivity, clickedActivity);
  }

  handleStartedStudyActivity = () => {
    const { data } = this.props;
    if (data.started_profile <= 0) return;

    const startedStudyActivity = 'started_study';
    let testersWithActivity = 0;

    this.linkActivities(startedStudyActivity, 'started_profile', data.started_profile);
    if (data.camera_not_allowed > 0) this.linkActivities(startedStudyActivity, 'camera_not_allowed', data.camera_not_allowed);

    testersWithActivity = data.started_profile + data.camera_not_allowed;
    this.handleAbandonedActivity(data.started_study, testersWithActivity, startedStudyActivity);
  }

  handleStartedProfileActivity = () => {
    const { data } = this.props;
    if (data.completed_profile <= 0) return;

    const startedProfileActivity = 'started_profile';
    let testersWithActivity = 0;

    this.linkActivities(startedProfileActivity, 'completed_profile', data.completed_profile);
    if (data.filtered > 0) this.linkActivities(startedProfileActivity, 'filtered', data.filtered);

    testersWithActivity = data.filtered + data.completed_profile;
    this.handleAbandonedActivity(data.started_profile, testersWithActivity, startedProfileActivity);
  }

  handleCompletedProfileActivity = () => {
    const { data } = this.props;
    if (data.started_tasks.length <= 0) return;

    const completedProfileActivity = 'completed_profile';

    if (data.started_tasks.length === 1) {
      const [testersWithActivity] = data.started_tasks;
      if (testersWithActivity === 0) return;

      this.linkActivities(completedProfileActivity, 'started_task', testersWithActivity);
      this.handleAbandonedActivity(
        data.completed_profile,
        testersWithActivity,
        completedProfileActivity,
      );
      return;
    }

    data.started_tasks.map((task, index) => {
      if (task <= 0) return false;
      const startedTaskActivity = `started_task_${index + 1}`;

      if (index === 0) {
        this.linkActivities(completedProfileActivity, startedTaskActivity, task);
        const testersWithActivity = data.started_tasks[index];
        this.handleAbandonedActivity(
          data.completed_profile,
          testersWithActivity,
          completedProfileActivity,
        );
        return true;
      }

      const sourceStartedTaskActivity = `started_task_${index}`;
      this.linkActivities(sourceStartedTaskActivity, startedTaskActivity, task);
      const testersWithActivity = data.started_tasks[index];
      this.handleAbandonedActivity(
        data.started_tasks[index - 1],
        testersWithActivity,
        completedProfileActivity,
      );
      return true;
    });
  }

  handleStartedTasksActivity = () => {
    const { data } = this.props;
    if (data.started_exit <= 0) {
      this.campaignWithoutExit();
      return;
    }

    let startedTaskActivity = 'started_task';
    const testersWithActivity = data.started_exit;
    if (data.started_tasks.length > 1) startedTaskActivity = `${startedTaskActivity}_${data.started_tasks.length}`;

    this.linkActivities(startedTaskActivity, 'started_exit', data.started_exit);

    this.handleAbandonedActivity(
      data.started_tasks[data.started_tasks.length - 1],
      testersWithActivity,
      startedTaskActivity,
    );
  }

  campaignWithoutExit = () => {
    const { data } = this.props;
    if (data.completed <= 0) return;

    let startedTaskActivity = 'started_task';
    const testersWithActivity = data.completed;
    if (data.started_tasks.length > 1) startedTaskActivity = `${startedTaskActivity}_${data.started_tasks.length}`;

    this.linkActivities(startedTaskActivity, 'completed', data.completed);
    this.handleAbandonedActivity(
      data.started_tasks[data.started_tasks.length - 1],
      testersWithActivity,
      startedTaskActivity,
    );
  }

  handleStartedExitActivity = () => {
    const { data } = this.props;
    if (data.completed <= 0) return;
    if (data.started_exit <= 0) return;

    const startedExitActivity = 'started_exit';
    const testersWithActivity = data.completed;

    this.linkActivities(startedExitActivity, 'completed', data.completed);

    this.handleAbandonedActivity(data.started_exit, testersWithActivity, startedExitActivity);
  }

  handleAbandonedActivity = (currentNodeTesters, testersWithActivity, activityKey) => {
    const abandonedTesters = currentNodeTesters - testersWithActivity;
    if (abandonedTesters <= 0) return;

    this.hasAbandoned = true;
    this.linkActivities(activityKey, 'abandoned', abandonedTesters);
  }

  linkActivities(sourceActivity, targetActivity, numberTester) {
    if (!this.activities.includes(sourceActivity)) this.activities.push(sourceActivity);
    if (!this.activities.includes(targetActivity)) this.activities.push(targetActivity);

    this.links.push({ source: sourceActivity, target: targetActivity, value: numberTester });
  }

  createActivityNodes = () => this.activities.map(node => this.nodes.push({ id: node }));

  render() {
    const { data } = this.state;
    return (
      <Fragment>
        <Title
          title={this.props.title}
          type="h5"
        />
        <div className="sankey__container">
          <ResponsiveSankey
            data={data}
            margin={{
              top: 20,
              right: 160,
              bottom: 20,
              left: 10,
            }}
            height={300}
            align="left"
            colors={calcularColor(data.nodes)}
            nodeOpacity={1}
            nodeWidth={18}
            nodeBorderColor="inherit:darker(0.8)"
            linkHoverOthersOpacity={0.1}
            enableLinkGradient
            labelPosition="outside"
            labelOrientation="vertical"
            labelPadding={16}
            labelTextColor="inherit:darker(1)"
            animate
            motionStiffness={120}
            motionDamping={11}
            nodePaddingY={87}
            enableLabels={false}
            legends={[
              {
                anchor: 'top-right',
                direction: 'column',
                translateX: 160,
                itemWidth: 200,
                itemHeight: 14,
                itemDirection: 'right-to-left',
                itemsSpacing: 2,
                itemTextColor: '#999',
                symbolSize: 10,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000',
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </Fragment>
    );
  }
}

SankeyComponent.propTypes = {
  data: PropType.shape({}).isRequired,
  title: PropType.string.isRequired,
};

export default SankeyComponent;
