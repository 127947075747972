import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LabelSummaryComponent from './Label';

const CampaignInfo = ({
  name, isEmpty, date, client, study,
}) => (
  <Box p={2}>
    <Grid container spacing={1}>
      <Grid item xs={6} >
        <Typography variant="overline" display="block" gutterBottom> Client </Typography>
        <LabelSummaryComponent
          isEmpty={isEmpty}
          emptyText="Client"
          text={client}
          variant="body2"
          align="left"
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="overline" display="block" gutterBottom align="right"> Created at </Typography>
        <LabelSummaryComponent
          isEmpty={isEmpty}
          text={date === null ? 'Not Launched' : date}
          emptyText="Date"
          variant="body2"
          align="right"
        />
      </Grid>
      <Grid item xs={12}>
        <LabelSummaryComponent
          isEmpty={isEmpty}
          emptyText="Campaign Name"
          text={name}
          variant="subtitle1"
          align="left"
        />
      </Grid>
      {
        study && (
          <Grid item xs={12}>
            <Typography variant="body2" display="inline" gutterBottom> {study.name} </Typography>
          </Grid>
        )
      }
    </Grid>
  </Box>
);

CampaignInfo.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  name: PropTypes.string,
  client: PropTypes.string,
  date: PropTypes.string,
  study: PropTypes.shape({
    name: PropTypes.string,
  }),
};

CampaignInfo.defaultProps = {
  name: '',
  client: '',
  date: '',
  study: null,
};

export default CampaignInfo;