import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import useStyles from './styles';

const InputSelect = ({
  required, disabled, placeholder, value, handleChange, data, name,
}) => {
  const [list, setList] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setList(data);
  }, [data]);

  return (
    <div className={classes.root}>
      <FormControl
        required={required}
        disabled={disabled}
        variant="outlined"
        className={classes.formControl}
      >
        <InputLabel id="select-label">{placeholder}</InputLabel>
        <Select
          labelId="select-label"
          id="demo-simple-select-outlined"
          value={value}
          onChange={handleChange}
          label="Age"
          name={name}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {list.map(opt => (
            <MenuItem
              key={opt.id}
              value={opt.id}
            >
              {opt.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{' '}</FormHelperText>
      </FormControl>
    </div>
  );
};

InputSelect.propTypes = {
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  })).isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  required: PropTypes.bool,
  editable: PropTypes.bool,
  name: PropTypes.string,
};

InputSelect.defaultProps = {
  placeholder: '',
  disabled: false,
  value: '',
  editable: false,
  required: false,
  name: '',
};

export default InputSelect;