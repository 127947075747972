import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.4,
    },
    '&.MuiFormControlLabel-labelPlacementStart': {
      marginLeft: '0px !important',
    },
  },
}));

const StyledCheckbox = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    '& svg': {
      fontSize: '2.5rem !important',
    },
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(props => <Checkbox color="default" {...props} />);

const CheckboxComponent = ({
  label, name, labelPlacement,
  handleChange, checked,
}) => {
  const classes = useStyles();

  return (
    <FormGroup row>
      <FormControlLabel
        className={classes.root}
        labelPlacement={labelPlacement}
        control={
          <StyledCheckbox
            checked={checked}
            onChange={handleChange}
            name={name}
          />}
        label={label}
      />
    </FormGroup>
  );
};

CheckboxComponent.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  labelPlacement: PropTypes.string,
};

CheckboxComponent.defaultProps = {
  label: '',
  labelPlacement: 'end',
};

export default CheckboxComponent;