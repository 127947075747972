import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  highPerformance: {
    fontSize: '60px',
    color: '#00CB31',
  },
  mediumPerformance: {
    fontSize: '60px',
    color: '#FF6B00',
  },
  lowPerformance: {
    fontSize: '60px',
    color: '#EC2A2A',
  },
});

export default useStyles;