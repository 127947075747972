import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Menu from './components/Menu';
import CampaignSummary from '../../components/CampaignSummary';

const CampaignMenuContext = ({ isCreate, selected }) => {
  const { idCampaign } = useParams();
  return (
    <>
      <CampaignSummary id={idCampaign} />
      <Menu isCreate={isCreate} selected={selected} />
    </>
  );
};

CampaignMenuContext.propTypes = {
  isCreate: PropTypes.bool,
  selected: PropTypes.string,
};

CampaignMenuContext.defaultProps = {
  isCreate: false,
  selected: '',
};

export default React.memo(
  CampaignMenuContext,
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
);