import React, { useState, useEffect, Fragment } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import SIGN_OUT_MUTATION from './graphql';
import ButtonActions from '../../../components/ButtonActions';
import { getStrings } from '../../../utils/i18n';

const modal = getStrings('modal', { returnObjects: true });

const MenuTopBarComponent = (props) => {
  const [menu, setMenu] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [
    signOut,
  ] = useMutation(
    SIGN_OUT_MUTATION,
    {
      variables: { authentication_token: localStorage.getItem('userToken') },
      onCompleted() {
        localStorage.removeItem('userToken');
        return props.history.push('/login');
      },
    },
  );

  const handleMobileMenuOpen = event => setMenu(event.currentTarget);

  const handleMobileMenuClose = () => setMenu(null);

  const isMobileMenuOpen = Boolean(menu);

  return (
    <Fragment>
      <Box style={{ position: 'relative' }}>
        <IconButton
          aria-haspopup="false"
          onClick={handleMobileMenuOpen}
        >
          <MoreIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={menu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <ButtonActions
          buttonText="Log out"
          icon="warning"
          title=" Log out"
          content={modal.closessesion.message}
          actionText="log out"
          handleMutation={signOut}
          close={handleMobileMenuClose}
        />
      </Menu>
    </Fragment>
  );
};


MenuTopBarComponent.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

export default withRouter(MenuTopBarComponent);
