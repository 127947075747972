import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

/** Languages */
import enStrings from './languages/en-strings';
import esStrings from './languages/es-strings';

i18n
  // .use(LanguageDetector)
  .init({
    resources: {
      en: enStrings,
      es: esStrings,
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};

const getStrings = (string = '', options = {}) => {
  let res = '';
  try {
    res = i18n.t(string, options);
  } catch (err) {
    res = '-- ERROR -- ';
  }
  return res;
};

export {
  i18n,
  changeLanguage,
  getStrings,
};