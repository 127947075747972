import DashboardPage from './pages/DashboardPage';
import CreateQuestionPage from './pages/CreateQuestionPage';
import EditQuestionPage from './pages/EditQuestionPage';
import TrackerPage from './pages/TrackerPage';
import NotFoundPage from './pages/NotFoundPage';
import CreateCampaignContext from './pages/CreateCampaignPage';
import EditCampaignWrapper from './pages/EditCampaignPage';
import PreviewCampaign from './containers/PreviewContainer';
import CreateTask from './pages/CreateTask';
import EditTask from './pages/EditTask';
import UsersPage from './pages/UsersPage';
import CreateUserPage from './pages/CreateUserPage';
import ClientsPage from './pages/ClientPage';
import CreateClientPage from './pages/CreateClientPage';
import EditClientPage from './pages/EditClientPage';
import TagsPage from './pages/TagsPage';
import CreateTagPage from './pages/CreateTagPage';
import EditTagPage from './pages/EditTagPage';
import CreateCategoryPage from './pages/CreateCategoryPage';
import CampaingSummaryPage from './pages/CampaingSummaryPage';
import UnifyTaskPage from './pages/UnifyTaskPage';
import ManagerStudiesPage from './pages/ManagerStudiesPage';
import EditStudy from './pages/EditStudy';
import ProfileCampaignPage from './pages/ProfileCampaignPage';
import IndividualResultPage from './pages/IndividualResultPage';
import SummaryKpiPage from './pages/SummaryKpiPage';
import BrandPerformancePage from './pages/BrandPerformancePage';
import MessagePerformancePage from './pages/MessagePerformancePage';
import EngagementPage from './pages/EngagementPage';
import StyleFormatPage from './pages/StyleFormatPage';
import ScenesPage from './pages/ScenesPage';
import ErrorPage from './pages/HoneyError';
import EditUserPage from './pages/EditUserPage ';
import OrganizationsPage from './pages/OrganizationsPage';
import CreateOrganizationPage from './pages/CreateOrganizationPage';
import EditOrganizationPage from './pages/EditOrganizationPage';
import EditCategoryPage from './pages/EditCategoryPage';

const routes = [
  {
    key: 1,
    path: '/dashboard',
    exact: true,
    component: DashboardPage,
  },
  {
    key: 2,
    path: '/campaign/create',
    exact: true,
    component: CreateCampaignContext,
  },
  {
    key: 3,
    path: '/campaign/edit/:idCampaign',
    exact: true,
    component: EditCampaignWrapper,
  },
  {
    key: 4,
    path: '/campaign/preview/:idCampaign',
    exact: true,
    component: PreviewCampaign,
  },
  {
    key: 5,
    path: '/campaign/:idCampaign/questionaire/:idQuestionnaire/create',
    exact: true,
    component: CreateQuestionPage,
  },
  {
    key: 6,
    path: '/campaign/:idCampaign/questionaire/:idQuestionnaire/question/edit/:id',
    exact: true,
    component: EditQuestionPage,
  },
  {
    key: 7,
    path: '/campaign/:idCampaign/task/:idTask/questionaire/:idQuestionnaire/create',
    exact: true,
    component: CreateQuestionPage,
  },
  {
    key: 8,
    path: '/campaign/:idCampaign/task/:idTask/questionaire/:idQuestionnaire/question/edit/:id',
    exact: true,
    component: EditQuestionPage,
  },
  {
    key: 9,
    path: '/campaign/:idCampaign/task/create',
    exact: true,
    component: CreateTask,
  },
  {
    key: 10,
    path: '/campaign/:idCampaign/task/edit/:id',
    exact: true,
    component: EditTask,
  },
  {
    key: 11,
    path: '/tracker/:idCampaign',
    exact: true,
    component: TrackerPage,
  },
  {
    key: 12,
    path: '/users',
    exact: true,
    component: UsersPage,
  },
  {
    key: 13,
    path: '/user/create',
    exact: true,
    component: CreateUserPage,
  },
  {
    key: 14,
    path: '/clients',
    exact: true,
    component: ClientsPage,
  },
  {
    key: 15,
    path: '/clients/create',
    exact: true,
    component: CreateClientPage,
  },
  {
    key: 16,
    path: '/client/edit/:id',
    exact: true,
    component: EditClientPage,
  },
  {
    key: 17,
    path: '/tags',
    exact: true,
    component: TagsPage,
  },
  {
    key: 18,
    path: '/tag/create',
    exact: true,
    component: CreateTagPage,
  },
  {
    key: 19,
    path: '/tag/edit/:id',
    exact: true,
    component: EditTagPage,
  },
  {
    key: 20,
    path: '/category/create',
    exact: true,
    component: CreateCategoryPage,
  },
  {
    key: 21,
    path: '/summary/:idCampaign',
    exact: true,
    component: CampaingSummaryPage,
  },
  {
    key: 22,
    path: '/study/unify/task',
    exact: true,
    component: UnifyTaskPage,
  },
  {
    key: 23,
    path: '/profiling/:idCampaign',
    exact: true,
    component: ProfileCampaignPage,
  },
  {
    key: 24,
    path: '/manager/studies',
    exact: true,
    component: ManagerStudiesPage,
  },
  {
    key: 25,
    path: '/study/edit/:idStudy',
    exact: true,
    component: EditStudy,
  },
  {
    key: 26,
    path: '/individual-results/brand-performance/:idCampaign',
    exact: true,
    component: BrandPerformancePage,
  },
  {
    key: 27,
    path: '/individual-results/summary/:idCampaign',
    exact: true,
    component: SummaryKpiPage,
  },
  {
    key: 28,
    path: '/individual-results/message-performance/:idCampaign',
    exact: true,
    component: MessagePerformancePage,
  },
  {
    key: 29,
    path: '/individual-results/engagement-storytelling/:idCampaign',
    exact: true,
    component: EngagementPage,
  },
  {
    key: 30,
    path: '/individual-results/style-format/:idCampaign',
    exact: true,
    component: StyleFormatPage,
  },
  {
    key: 31,
    path: '/individual-results/:idCampaign',
    exact: true,
    component: IndividualResultPage,
  },
  {
    key: 32,
    path: '/scenes/:idCampaign',
    exact: true,
    component: ScenesPage,
  },
  {
    key: 33,
    path: '/honeyerror',
    exact: true,
    component: ErrorPage,
  },
  {
    key: 34,
    path: '/user/edit/:id',
    exact: true,
    component: EditUserPage,
  },
  {
    key: 5,
    path: '/organizations',
    exact: true,
    component: OrganizationsPage,
  },
  {
    key: 36,
    path: '/organizations/create',
    exact: true,
    component: CreateOrganizationPage,
  },
  {
    key: 37,
    path: '/organizations/edit/:id',
    exact: true,
    component: EditOrganizationPage,
  },
  {
    key: 38,
    path: '/category/edit/:id',
    exact: true,
    component: EditCategoryPage,
  },
  {
    key: 39,
    component: NotFoundPage,
    path: '*',
  },
];

export default routes;
