
export default {
  maxWidthXl: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  maxWidthLg: {
    paddingLeft: 16,
    paddingRight: 16,
  },
};