import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Dialog,
} from '@material-ui/core';
import TitleComponent from '../../../components/Title';


const useStyles = makeStyles({
  title: {
    marginBottom: 0,
    '& > h2 > div > div': {
      margin: 0,
    },
  },
  content: {
    padding: '2.4rem 0 2.4rem 0',
    '& > p': {
      marginBottom: 0,
    },
    '& > p > div > div': {
      padding: 0,
    },
  },
});

const Transition = React.forwardRef((props, ref) => (<Slide direction="up" ref={ref} {...props} />));

const DialogComponent = ({
  open, icon, title, size, children, buttons,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={size}
      TransitionComponent={Transition}
      aria-labelledby="dialog-slide-title"
      aria-describedby="dialog-slide-description"
      PaperProps={{ elevation: 0 }}
    >
      <DialogTitle id="dialog-slide-title" className={classes.title}>
        <TitleComponent icon={icon} title={title} variant="h2" />
      </DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText component="div" id="dialog-slide-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttons}
      </DialogActions>
    </Dialog>
  );
};

DialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.node.isRequired,
};

export default DialogComponent;