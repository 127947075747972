import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const TagsAutocomplete = (props) => {
  const {
    tags,
    handleTag,
    tagDafaultIds,
  } = props;
  const handleAutocomplete = (e, valueTags) => {
    e.preventDefault();
    const tagIdsArray = [];
    valueTags.forEach((valueTag) => {
      tagIdsArray.push(Number(tags.filter(tag => valueTag === tag).shift().id));
    });
    const tagIds = [...new Set(tagIdsArray)];
    handleTag(tagIds);
  };
  return (
    <Autocomplete
      multiple
      options={tags}
      getOptionLabel={option => `${option.title} / ${option.category}`}
      value={tagDafaultIds}
      onChange={handleAutocomplete}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Tags"
          placeholder="Select multiple tags"
          margin="normal"
          fullWidth
        />
      )}
    />
  );
};

TagsAutocomplete.propTypes = {
  tagDafaultIds: PropTypes.arrayOf(PropTypes.object),
  handleTag: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
};


TagsAutocomplete.defaultProps = {
  tagDafaultIds: [],
};

export default TagsAutocomplete;
