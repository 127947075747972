import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#2A263D';
const blackDark = '#000';

export default {
  blackDark,
  black,
  white,
  type: 'light', // Switching the dark mode on is a single property value change.
  primary: {
    light: '#7F6DF0',
    main: '#0F28A9',
    dark: '#0D1E7C',
    lighter: '#AFB7E1',
    contrastText: white,
  },
  secondary: {
    light: '#FFCD02',
    main: '#FFCD02',
    dark: '#FFCD02',
    contrastText: '#26187C',
  },
  success: {
    contrastText: white,
    dark: '#049824',
    main: '#00CB31',
    light: '#ABEDB9',
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  disabled: {
    main: '#E7EBEF',
    contrastText: '#919098',
  },
  label: {
    main: 'pink',
  },
  error: {
    contrastText: white,
    dark: '#B41E1F',
    main: '#EC2A2A',
    light: '#F8B7B6',
  },
  text: {
    primary: black,
    secondary: '#919098',
    link: colors.blue[600],
  },
  background: {
    default: '#E5E5E5',
    paper: white,
  },
  rangeMetric: {
    low: '#EC2A2A',
    medium: '#FF6B00',
    high: '#00CB31',
  },
  icon: '#0F28A9',
  divider: colors.grey[200],
};