import React from 'react';
import PropTypes from 'prop-types';

const FormComponent = props => (
  <form onSubmit={props.onSubmit} >
    { props.children }
  </form>
);

FormComponent.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.node,
};

FormComponent.defaultProps = {
  onSubmit: () => {},
  children: '',
};

export default FormComponent;
