import React from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PieChart from 'highcharts-react-official';
import { COLOR_BLUE } from '../../../utils/colors';
import styleFonts from '../styles';

const useStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    '& .small-chart .highcharts-label tspan': {
      [theme.breakpoints.down('lg')]: {
        fontSize: 16,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
  },
  chart: {
    width: '100%',
    margin: 0,
  },
}));

const PieHighChart = (props) => {
  const {
    name, data, widthChart, result_type: resultType,
  } = props;
  const classes = useStyle();
  const dataAnswer = JSON.parse(data);
  const options = {
    chart: {
      type: 'pie',
      backgroundColor: null,
      className: classes.chart,
      width: widthChart > 0 ? widthChart - 20 : null,
      spacing: [5, 5, 5, 5],
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      // eslint-disable-next-line object-shorthand
      formatter: function () {
        return `${this.key}: <b>${dataAnswer[this.key]}</b>`;
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      bubbleLegend: {
        color: 'red',
      },
      itemStyle: styleFonts,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        colors: COLOR_BLUE,
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: (resultType === 'percentage') ? '{point.percentage:.1f} %' : '{point.y}',
          distance: '-50%',
          filter: {
            property: 'percentage',
            operator: '>',
            value: 4,
          },
        },
      },
    },
    series: [{
      name,
      colorByPoint: true,
      data: dataAnswer ?
        Object.keys(dataAnswer).map(key => ({ name: key, y: dataAnswer[key] })) : null,
      dataLabels: {
        style: {
          color: '#FFFFFF',
          align: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          fontFamily: 'Roboto',
          textOutline: false,
          textShadow: '-1px -1px 0 #0D1E7C,1px -1px 0 #0D1E7C,-1px 1px 0 #0D1E7C, 1px 1px 0 #0D1E7C;',
        },
      },
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          chart: {
            className: widthChart < 400 ? 'small-chart' : null,
          },
        },
      }],
    },
  };

  return (
    <PieChart
      highcharts={Highcharts}
      options={options}
      containerProps={{ className: classes.root }}
      oneToOne
    />
  );
};

PieHighChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  widthChart: PropTypes.number.isRequired,
};

export default PieHighChart;