import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

const styles = () => ({
  container: {
    margin: 0,
  },
  root: {
    flexGrow: 1,
  },
});

const ContainerComponent = props => (
  <Container
    maxWidth="xl"
    className={props.classes.container}
  >
    <Grid container className={props.classes.root} justify="center">
      <Grid item xs={12} sm={12} md={12} lg={12} >
        { props.children }
      </Grid>
    </Grid>
  </Container>
);

ContainerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(ContainerComponent);