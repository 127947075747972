
const styles = theme => ({
  divider: {
    background: theme.palette.white,
    borderBottom: `0.1rem solid ${theme.palette.divider}`,
  },
  mainmenuContainer: {
    backgroundColor: theme.palette.white,
    width: 'inherit',
    height: '100vh',
    overflowX: 'hidden',
  },
  mainmenuContainerClosed: {
    backgroundColor: theme.palette.white,
    width: 'inherit',
    height: '100vh',
  },
  headerMenuMainContainer: {
    padding: '1.6rem',
  },
  headerMenuMainContainerAvatarClosed: {
    padding: '1.6rem',
  },
  headerMenuMainContainerClosed: {
    display: 'none',
  },
  headerMenuPrimaryContainer: {
    minHeight: '3rem',
  },
  headerMenuAvatarContainer: {
    textAlign: 'center',
    width: '2.8rem',
    height: '2.8rem',
  },
  headerMenuAvatar: {
    fontSize: '1.6rem',
    fontWeight: 500,
    lineHeight: '1.9rem',
    width: '100%',
    height: '100%',
    background: theme.palette.primary.main,
  },
  headerMenuNameContainer: {
    marginBottom: '0.5rem',
    '& p': {
      textAlign: 'center',
      paddingTop: '0.3rem',
    },
  },
  headerMenuPointsList: {
    maxWidth: '2.4rem',
    height: '2.4rem',
    '& button,span': {
      padding: 0,
      width: '2.4rem',
      height: '2.4rem',
    },
  },
  headerMenuCompanyInfo: {
    textAlign: 'center',
    letterSpacing: 0,
  },
  link: {
    textDecoration: 'none',
    color: '#0F28A9',
    '&:active': {
      '& > div > div > li > span': {
        color: '#FFFFF !important',
      },
    },
  },
  mainPadding: {
    padding: '1.6rem',
  },
  icon: {
    color: '#0F28A9',
  },
  iconContainer: {
    minWidth: 0,
    width: '2.4rem',
    height: '2.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5rem',
  },
  listItemIcon: {
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    color: '#0F28A9',
    padding: '1.6rem',
    '& > &.MuiListItemText-root': {
      margin: 0,
    },
    '&.Mui-selected': {
      backgroundColor: '#0F28A9',
      '&:hover': {
        backgroundColor: '#0F28A9',
        pointerEvents: 'none !important',
      },
      '& > div': {
        color: 'white',
        '& > span': {
          color: 'white',
        },
        '& > li > span': {
          color: 'white',
        },
      },
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },
  constainerGeneral: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100vh',
  },
  imageFooter: {
    width: '5rem',
    height: '5rem',
  },
  containerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem 0',
    flexDirection: 'column',
  },
  listItemText: {
    padding: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    height: '3em',
  },
  openMenuOptions: {
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    textTransform: 'uppercase',
  },
});

export default styles;