import React from 'react';
import PropTypes from 'prop-types';
import loaderLogo from '../../../images/synapbox_loading.gif';

const LoaderImageComponent = props => (
  <div className="modal__container">
    <div className="modal__content">
      <div className="loaderLogo-container">
        <div className="loader" />
        <img className="loaderLogo loaderLogo-center" src={loaderLogo} alt="loader synapbox" />
        {
          props.porcentage && (
            <p className="taskLength__upload-message">{`Upload ${props.value} %`}</p>
          )
        }
      </div>
    </div>
  </div>
);
LoaderImageComponent.propTypes = {
  value: PropTypes.number,
  porcentage: PropTypes.bool,
};

LoaderImageComponent.defaultProps = {
  value: 0,
  porcentage: false,
};

export default LoaderImageComponent;
