import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import { Query } from '@apollo/client/react/components';
import MenuSideBar from '../MenuSideBar';
import styles from './styles';
import { GET_MAIN_MENU } from '../../../Apollo/queries.gql';


class MiniDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
    };
  }

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <Query query={GET_MAIN_MENU}>
        {({
          loading, error, data,
        }) => {
          if (loading) return 'loading...';
          if (error) return `Error: ${error.message}`;
          const { isOpen } = data.mainMenu;
          const drawer = (<MenuSideBar isOpen={isOpen} />);
          return (
            <div className={classes.root}>
              <nav className={[isOpen ? classes.drawer : classes.drawerClosed]}>
                <Hidden smUp implementation="css">
                  <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={this.state.mobileOpen}
                    onClose={this.handleDrawerToggle.bind(this)}
                    classes={{
                      paper: isOpen ? classes.drawerPaper : classes.drawerPaperLittle,
                    }}
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Drawer
                    classes={{
                      paper: isOpen ? classes.drawerPaper : classes.drawerPaperLittle,
                    }}
                    variant="permanent"
                    open
                  >
                    {drawer}
                  </Drawer>
                </Hidden>
              </nav>
              <main className={[isOpen ? `${classes.contentShadow}` : `${classes.content}`]}>
                {this.props.children}
              </main>
            </div>);
        }}
      </Query>
    );
  }
}

MiniDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    contentShadow: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
};

export default withStyles(styles, { withTheme: true })(MiniDrawer);
