import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div > label': {
      color: '#919098',
      fontSize: ' 1.2rem',
      lineHeight: '1.9rem',
    },
    '& > div > div': {
      fontSize: ' 1.6rem',
      lineHeight: '1.9rem',
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;