const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: '#F9FAFC',
    color: theme.palette.disabled.contrastText,
    padding: '0',
    marginBottom: '5rem',
  },
  itemTaskQuestion: {
    display: 'inline-block',
    pointerEvents: 'none',
    width: '100%',
  },
  itemTaskQuestionWrapper: {
    cursor: 'not-allowed',
  },
  icon: {
    color: theme.palette.disabled.contrastText,
    minWidth: '0px',
    marginRight: '0.5rem',
  },
  nested: {
    paddingLeft: theme.spacing(2),
    backgroundColor: 'white',
    textTrasform: 'uppercase',
    '& > div > svg , span': {
      color: theme.palette.disabled.contrastText,
      padding: '0px',
      textTransform: 'uppercase',
    },
  },
  mainListItem: {
    display: 'flex',
    justifyContent: 'start',
    textTransform: 'uppercase',
    color: theme.palette.disabled.contrastText,
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    fontWeight: 500,
  },
  ListItem: {
    paddingLeft: '0px',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '1.4rem',
    lineHeight: '1.6rem',
    color: theme.palette.disabled.contrastText,
    margin: 0,
  },
  navLink: {
    padding: '1.6rem',
    '&.Mui-selected': {
      backgroundColor: '#0F28A9',
      '&:hover': {
        backgroundColor: '#0F28A9',
      },
      '& > div > svg , span': {
        margin: 0,
        color: 'white',
      },
      '& > div': {
        margin: 0,
        color: 'white',
      },
    },
  },
  navLinkSecondary: {
    padding: '1.6rem',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.lighter,
      '&:hover': {
        background: theme.palette.primary.lighter,
      },
      '& > div > svg': {
        color: theme.palette.primary.main,
      },
      '& > div': {
        color: theme.palette.primary.main,
      },
      '& > div > div': {
        color: theme.palette.primary.main,
      },
    },
  },
  iconList: {
    minWidth: 0,
    width: '2.4rem',
    height: '2.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignitems: 'center',
    marginRight: '0.5rem',
    marginLeft: '2.4rem',
  },
});

export default styles;
