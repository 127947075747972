import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ErrorGeneral } from '../';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: '',
    };
  }

  componentDidCatch(error) {
    this.setState({
      hasError: true,
      errorMessage: error.message,
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorGeneral message={this.state.errorMessage} />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

ErrorBoundary.defaultValues = {
};

export default ErrorBoundary;