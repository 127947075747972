import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, Typography } from '@material-ui/core';
import { useStyles, ContainerTitle, TextInput, ModalTitle } from './styles';

const TitleComponent = ({
  title, subtitle, icon, variant, editTitle, modalTitle,
}) => {
  const classes = useStyles();

  const handleTitle = (e) => {
    editTitle(e.target.value);
  };

  return (
    <Box>
      {
        modalTitle
        ? (
          <ModalTitle>
            { icon && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor="secondary.main"
                color="primary.main"
                height="4.8rem"
                width="4.8rem"
                borderRadius="1rem"
                boxShadow={1}
                mr={2}
              >
                <Icon className={classes.icon}>{icon}</Icon>
              </Box>
          )}
            {
            subtitle && (
              <Typography
                className={classes.typography}
                color="primary"
                variant={variant}
                gutterBottom
              >
                {subtitle}
              </Typography>
            )
          }
            { editTitle
              ? <TextInput
                id="standard-basic"
                defaultValue={title}
                onChange={handleTitle}
                color="primary"
                size="medium"
                inputProps={{
                  className: classes.inputTitle,
                  color: 'primary',
                }}
              />
              : <Typography
                className={classes.typography}
                color="primary"
                variant={variant}
                gutterBottom
              >
                {title}
              </Typography>
          }
          </ModalTitle>
          )
          : (
            <ContainerTitle>
              { icon && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor="secondary.main"
                color="primary.main"
                height="4.8rem"
                width="4.8rem"
                borderRadius="1rem"
                boxShadow={1}
                mr={2}
              >
                <Icon className={classes.icon}>{icon}</Icon>
              </Box>
          )}
              {
            subtitle && (
              <Typography
                className={classes.typography}
                color="primary"
                variant={variant}
                gutterBottom
              >
                {subtitle}
              </Typography>
            )
          }
              { editTitle
              ? <TextInput
                id="standard-basic"
                defaultValue={title}
                onChange={handleTitle}
                color="primary"
                size="medium"
                inputProps={{
                  className: classes.inputTitle,
                  color: 'primary',
                }}
              />
              : <Typography
                className={classes.typography}
                color="primary"
                variant={variant}
                gutterBottom
              >
                {title}
              </Typography>
          }
            </ContainerTitle>
        )
      }
    </Box>
  );
};

TitleComponent.propTypes = {
  title: PropTypes.string.isRequired,
  editTitle: PropTypes.func,
  variant: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  modalTitle: PropTypes.bool,
};

TitleComponent.defaultProps = {
  subtitle: null,
  icon: undefined,
  variant: 'h2',
  editTitle: null,
  modalTitle: false,
};

export default TitleComponent;
