import React from 'react';
import loadable from '@loadable/component';
import { useQuery } from '@apollo/client';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Box } from '@material-ui/core';
import { GET_CAMPAIGN_ID } from './graphql';
import TitleComponent from '../../components/Title/index';
import {
  ContainerComponent,
  SummaryComponent,
  LoaderLogoComponent,
  ButtonsGeneral,
  WrapperMenuContext,
} from '../../components';
import CampaignMenuContext from '../CampaignMenuContext';
import { getStrings } from '../../utils/i18n';
import Loader from '../../components/Loader';
import { validationNextStep } from './utils';
import { SyncButton } from './components/SyncButton';

const ListQuestionnairesContainer = loadable(() => import('./components/ListQuestionnairesContainer'), { fallback: <Loader /> });

const { buttons, title, subtitle } = getStrings('campaignPreview', { returnObjects: true });
const { buttons: { next, back } } = getStrings('general', { returnObjects: true });

const PreviewContainer = ({ match, history }) => {
  const { idCampaign } = match.params;
  const {
    loading, error, data, client, refetch,
  } = useQuery(GET_CAMPAIGN_ID, {
    variables: {
      id: idCampaign,
    },
    skip: idCampaign === 'undefined',
    fetchPolicy: 'network-only',
  });
  if (idCampaign === 'undefined') return <Redirect to="/dashboard" />;
  if (loading) return <LoaderLogoComponent />;
  if (error) return `Error: ${error.message}`;

  const [campaign] = data.allCampaigns;
  if (campaign.questionnaires.length === 0) return null;
  const idQuestionnaireProfile = campaign.questionnaires.find(q => q.type === 'PROFILE')?.id;
  const idQuestionnaireExit = campaign.questionnaires.find(q => q.type === 'EXIT');
  const validatebtn = validationNextStep(campaign);
  return (
    <WrapperMenuContext menu={<CampaignMenuContext selected="preview" />}>
      <ContainerComponent>
        <Grid container spacing={2}>
          <Grid flex="true" item xs={12}>
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" style={{ justifyContent: 'space-between' }}>
              <TitleComponent subtitle={subtitle} title={title} flexShrink={1} style={{ flex: '1 1 0%' }} />
              <SyncButton idCampaign={idCampaign} status={campaign.status.name} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <SummaryComponent />
          </Grid>
          <Grid item xs={12}>
            <ListQuestionnairesContainer
              idCampaign={Number(campaign.id)}
              idExit={idQuestionnaireExit ? idQuestionnaireExit.id : undefined}
              idProfile={idQuestionnaireProfile}
              campaign={campaign}
              validateNext={() => refetch()}
              client={client}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonsGeneral
              handleEventBack={() => history.push(`/campaign/edit/${campaign.id}`)}
              textBack={back}
              textNext={buttons.nextTitle}
              textBold={next}
              handleNextStep={() => history.push(`/tracker/${campaign.id}`)}
              disabled={!validatebtn}
            />
          </Grid>
        </Grid>
      </ContainerComponent>
    </WrapperMenuContext>
  );
};

PreviewContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      idCampaign: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(PreviewContainer);