import React from 'react';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PieChart from 'highcharts-react-official';
import { COLOR_BLUE } from '../../../utils/colors';
import styleFonts from '../styles';

const useStyle = makeStyles({
  root: {
    width: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  chart: {
    width: '100%',
    margin: 0,
  },
});

const SemiDonutHighchartsComponent = (props) => {
  const { name, data, result_type: resultType } = props;
  const classes = useStyle();
  const dataAnswer = JSON.parse(data);

  const options = {
    chart: {
      backgroundColor: null,
      className: classes.chart,
      width: props.widthChart > 0 ? props.widthChart - 30 : null,
      spacing: [5, 5, 5, 5],
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      // eslint-disable-next-line object-shorthand
      formatter: function () {
        return `${this.key}: <b>${dataAnswer[this.key]}</b>`;
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: (resultType === 'percentage') ? '{point.percentage:.1f} %' : '{point.y}',
          distance: '-25%',
          style: {
            fontWeight: 'bold',
            color: 'white',
          },
          filter: {
            property: 'percentage',
            operator: '>',
            value: 4,
          },
        },
        startAngle: -90,
        endAngle: 90,
        center: ['50%', '75%'],
        size: '100%',
        allowPointSelect: true,
        cursor: 'pointer',
        colors: COLOR_BLUE,
        showInLegend: true,
      },
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      bubbleLegend: {
        color: 'red',
      },
      itemHiddenStyle: {
        width: '50px',
        height: '50px',
      },
      itemStyle: styleFonts,
    },
    series: [{
      type: 'pie',
      name,
      innerSize: '50%',
      colorByPoint: true,
      data: dataAnswer ?
        Object.keys(dataAnswer).map(key => ({ name: key, y: dataAnswer[key] }))
        : null,
      dataLabels: {
        style: {
          color: '#FFFFFF',
          align: 'center',
          fontSize: '16px',
          lineHeight: '22px',
          fontFamily: 'Roboto',
          textOutline: false,
          textShadow: '-1px -1px 0 #0D1E7C,1px -1px 0 #0D1E7C,-1px 1px 0 #0D1E7C, 1px 1px 0 #0D1E7C;',
        },
      },
    }],
  };
  return (
    <PieChart
      highcharts={Highcharts}
      options={options}
      containerProps={{ className: classes.root }}
    />
  );
};

SemiDonutHighchartsComponent.propTypes = {
  data: PropTypes.string.isRequired,
  widthChart: PropTypes.number.isRequired,
};

export default SemiDonutHighchartsComponent;