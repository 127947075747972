import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  boxEmpty: {
    border: `.1rem ${theme.palette.text.secondary} dashed`,
  },
}));


const LabelSummaryComponent = (props) => {
  const classes = useStyles();
  if (props.isEmpty) {
    return (
      <Box className={classes.boxEmpty} borderRadius={5} pt={1} pb={1}>
        <Typography variant="body2" align="center" color="textPrimary">{props.emptyText}</Typography>
      </Box>
    );
  }
  return <Typography variant={props.variant} align={props.align} color="textPrimary"> {props.text} </Typography>;
};

LabelSummaryComponent.propTypes = {
  variant: PropTypes.string.isRequired,
  align: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  emptyText: PropTypes.string.isRequired,
};

export default LabelSummaryComponent;