import React from 'react';
import PropTypes from 'prop-types';

import { getStrings } from '../../../utils/i18n';

const { components: { cuotaParticipants } } = getStrings('tracker', { returnObjects: true });

const CuotaComponent = ({
  sampleSize,
  testersLeft,
  testersCompleted,
  testersLeftPercentage,
  testersCompletedPercentage,
}) => {
  const leftStyle = {
    width: `${testersLeftPercentage}%`,
    height: '2.3rem',
  };
  const completedStyle = {
    width: `${+testersCompletedPercentage + 2}%`,
    height: '2.3rem',
  };
  return (
    <div className="cuota__container">
      <div className="cuota__container-number">
        <div className="cuota__container-number--title">{cuotaParticipants.sampleSize}</div>
        <div className="cuota__container-number--value">{sampleSize}</div>
      </div>
      <div className="cuota__container-chart">
        <div className="cuota__container-values">
          <div className="cuota__left-text">
            {
              testersLeftPercentage > 0 &&
              `${testersLeft} (${testersLeftPercentage}%)`
            }
          </div>
          <div className="cuota__right-text">
            {
              testersCompletedPercentage > 1 &&
              `${testersCompleted} (${testersCompletedPercentage}%)`
            }
          </div>
        </div>
        <div className="cuota__container-chart--line">
          <div className="cuota__container-chart--line-left" style={leftStyle} />
          <div className="cuota__container-chart--line-completed" style={completedStyle} />
        </div>
        <div className="cuota__container-chart--values">
          <div className="cuota__container-chart--point-green" />
          <div className="cuota__container-chart--text">{cuotaParticipants.participantsCompleted}</div>
          <div className="cuota__container-chart--point-salmon" />
          <div className="cuota__container-chart--text">
            {cuotaParticipants.participantsLeft}
          </div>
        </div>
      </div>
    </div>
  );
};

CuotaComponent.propTypes = {
  sampleSize: PropTypes.number.isRequired,
  testersCompleted: PropTypes.number.isRequired,
  testersLeft: PropTypes.number.isRequired,
  testersLeftPercentage: PropTypes.string.isRequired,
  testersCompletedPercentage: PropTypes.string.isRequired,
};

export default CuotaComponent;