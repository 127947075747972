import { makeStyles } from '@material-ui/core/styles';

const defaulStyleMain = {
  boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
};

const defaulStyleHover = {
  boxShadow: '0px 8px 22px rgba(0, 0, 0, 0.25)',
};

const setStyleDisable = disabled => ({
  background: disabled.main,
  color: disabled.contrastText,
  boxShadow: '0px 8px 22px rgba(0, 0, 0, 0.25)',
});

const useStyles = makeStyles(theme => ({
  'contained-positive': {
    ...defaulStyleMain,
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      ...defaulStyleHover,
      background: theme.palette.success.dark,
    },
    '&:disabled': setStyleDisable(theme.palette.disabled),
  },
  'contained-negative': {
    ...defaulStyleMain,
    background: theme.palette.error.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      ...defaulStyleHover,
      background: theme.palette.error.dark,
    },
    '&:disabled': setStyleDisable(theme.palette.disabled),
  },
  'outlined-positive': {
    background: 'none',
    color: theme.palette.success.main,
    '&:hover': {
      background: theme.palette.success.light,
    },
    '&:disabled': setStyleDisable(theme.palette.disabled),
  },
  'outlined-negative': {
    background: 'none',
    color: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.light,
    },
    '&:disabled': setStyleDisable(theme.palette.disabled),
  },
}));

export default useStyles;