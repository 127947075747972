import React from 'react';
import PropTypes from 'prop-types';
import { ContainerContentMedia, Image } from './styled';

const ViewImage = props => (
  <ContainerContentMedia>
    <Image
      src={props.src}
      alt={props.alt}
      crossOrigin="anonymous"
    />
  </ContainerContentMedia>
);

ViewImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default ViewImage;