const styles = () => (
  {
    Icon: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        width: '2.4rem',
        height: '2.4rem',
        color: '#2A263D',
        display: 'flex',
        alignItems: 'center',
      },
    },
    IconEmpty: {
      color: 'var(--bg-grey-one)',
    },
    IconText: {
      width: '100%',
      fontSize: '2rem',
      fontFamily: 'var(--font-primary-bold)',
      height: '100%',
      textAlign: 'center',
    },
    IconImage: {
      width: '2.4rem',
    },
    CampaignInfoField: {
      color: '#2A263D',
      fontFamily: 'var(--font-primary)',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
    },
    CampaignInfoTitle: {
      fontFamily: 'var(--font-primary-bold)',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '2.4rem',
      lineHeight: '2.8rem',
    },
    CampaignInfoTextRight: {
      textAlign: 'right',
    },
    CampaignInfoEmptyLabel: {
      fontSize: '1.4rem',
      color: 'var(--bg-grey-one)',
      fontFamily: 'var(--font-primary-bold)',
      border: '.1rem var(--bg-grey-one) dashed',
      textAlign: 'center',
      borderRadius: '.5rem',
    },
    CampaignInfoEmptyTitle: {
      fontSize: '1.4rem',
      color: 'var(--bg-grey-one)',
      fontFamily: 'var(--font-primary-bold)',
      border: '.1rem var(--bg-grey-one) dashed',
      textAlign: 'center',
      borderRadius: '.5rem',
      margin: '1rem 0',
      padding: '2rem 0',
    },
    Progress: {
      position: 'relative',
      minHeight: '2.5rem',
    },
    ProgressBar: {
      position: 'relative',
      width: '100%',
      height: '.5rem',
      background: '#E7EBEF;',
      borderRadius: '.5rem',
      margin: '0.8rem 0 0',
    },
    ProgressBarSucess: {
      background: '#00CB31',
      height: '.5rem',
      borderRadius: '.5rem',
      position: 'absolute',
      top: '0',
      left: '0',
    },
    ProgressUp: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
    },
    ProgressDown: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'right',
    },
    ProgressNumberContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0.5rem 0 1.6rem',
    },
  }
);

export default styles;