import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

const BarChartGeneralComponent = props => (
  <Fragment>
    <div className="barchart__title">{props.title}</div>
    <div className="bar__container">
      <ResponsiveBar
        data={props.data}
        keys={props.keys}
        margin={{
          top: 10,
          right: 5,
          bottom: 5,
          left: 5,
        }}
        padding={0.1}
        innerPadding={1}
        colorBy="id"
        borderWidth={0}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        layers="bars"
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        borderColor="inherit:darker(1.6)"
        labelTextColor={props.textColor}
        animate
        layout={props.layout || 'horizontal'}
        groupMode="grouped"
        colors={props.listColor.length > 0 ? props.listColor : props.colors}
        motionStiffness={90}
        motionDamping={15}
        label={d => `${d.value}${props.unit}`}
        /* legends={[
          {
            dataFrom: 'keys',
            anchor: 'left',
            direction: 'column',
            justify: false,
            translateX: -200,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 500,
            itemHeight: 10,
            itemOpacity: 0.85,
            symbolSize: 8,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]} */
      />
    </div>
  </Fragment>
);

BarChartGeneralComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  keys: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  colors: PropTypes.string,
  listColor: PropTypes.arrayOf(PropTypes.string),
  textColor: PropTypes.string,
  unit: PropTypes.string,
};

BarChartGeneralComponent.defaultProps = {
  colors: 'nivo',
  listColor: ['#261a7c', '#fdc321', '#f18701', '#1155cc', '#ffd966', '#3c78d8', '#ff9900', '#6d9eeb', '#ffe599', '#f6b26b'],
  textColor: '#fff',
  unit: '',
};

export default BarChartGeneralComponent;
