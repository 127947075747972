import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import useStyles from './styles';

const ButtonComponent = ({ children, loading, ...props }) => {
  const classes = useStyles();
  const styleVariant = classes[`${props.variant}-${props.extend}`] || '';
  return (
    <Button
      className={styleVariant}
      {...props}
    >
      {children}
      {
        loading && (
          <Box
            position="absolute"
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="white"
            style={{
              top: '0',
              right: '0',
              opacity: '.5',
            }}
          >
            <CircularProgress size={20} color="primary" />
          </Box>
        )
      }
    </Button>
  );
};

ButtonComponent.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  extend: PropTypes.string,
  loading: PropTypes.bool,
};

ButtonComponent.defaultProps = {
  loading: false,
  extend: undefined,
  variant: undefined,
};

export default ButtonComponent;
