import React from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  active: {
    background: theme.palette.primary.main,
    color: `${theme.palette.primary.contrastText} !important`,
  },
}));

const ButtonGroupComponent = (props) => {
  const { options, active, handleClick } = props;
  const classes = useStyles();
  return (
    <ButtonGroup {...props}>
      {options.map(option => (
        <Button
          key={option.id}
          onClick={() => handleClick(option.id)}
          className={active === option.id ? classes.active : ''}
        >
          { option.img_class && (
            <Icon>{option.img_class}</Icon>
          )}
          <Box ml={0.6}>{option.label}</Box>
        </Button>
      ))}
    </ButtonGroup>
  );
};

ButtonGroupComponent.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  active: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ButtonGroupComponent;