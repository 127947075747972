const MULTIPLE_COLORS = [
  '#261a7c',
  '#fdc321',
  '#f18701',
  '#1155cc',
  '#ffd966',
  '#3c78d8',
  '#ff9900',
  '#6d9eeb',
  '#ffe599',
  '#f6b26b',
];

const COLOR_YELLOW = [
  '#fdc321',
  '#ffd966',
  '#ffe599',
];

const COLOR_BLUE = [
  '#0F28A9',
  '#AFB7E1',
  '#0D1E7C',
  '#E3E7F6',
];

const COLOR_ORANGE = [
  '#f18701',
  '#ff9900',
  '#f6b26b',
];

const LIST_COLORS = [
  {
    orientation: 'vertical',
    colors: COLOR_YELLOW,
    textColor: 'black',
  },
  {
    orientation: 'vertical',
    colors: COLOR_BLUE,
    textColor: 'white',
  },
  {
    orientation: 'vertical',
    colors: COLOR_ORANGE,
    textColor: 'black',
  },
];


const COLORS_AOIS = [
  '#00CB31', '#FFCD02', '#6B83FF', '#63DFE7', '#FF4343', '#9BFFC9', '#FF6B00', '#A56BDE', '#FF0E9F', '#EECE7D', '#FFBC7E', '#63CBA5', '#A0D4F1', '#D5E51D', '#D0C139',
];

const getRandomColor = () => {
  const indexColor = Math.floor(Math.random() * Math.floor(COLORS_AOIS.length));
  return COLORS_AOIS[indexColor];
};

export {
  LIST_COLORS,
  MULTIPLE_COLORS,
  COLOR_BLUE,
  COLORS_AOIS,
  getRandomColor,
};