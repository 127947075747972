import { getStrings } from '../../../utils/i18n';

const strMenu = getStrings('mainMenu', { returnObjects: true });

const MENU_ORGANIZATION = [
  {
    id: 1,
    isOpen: true,
    route: '/organizations',
    icon: 'groups',
    text: strMenu.titleOrganization,
    organization: true,
  },
  {
    id: 2,
    isOpen: true,
    route: '/users',
    icon: 'people',
    text: strMenu.menuSectionUser,
  },
  {
    id: 3,
    isOpen: true,
    route: '/clients',
    icon: 'folder_shared',
    text: strMenu.menuSectionClient,
  },
  {
    id: 4,
    isOpen: true,
    route: '/tags',
    icon: 'bookmarks',
    text: strMenu.menuSectionTags,
  },
];

const MENU_CAMPAIGNS = [
  {
    id: 5,
    isOpen: true,
    route: '/dashboard',
    icon: 'dashboard',
    text: strMenu.menuSectionCampaigns.ItemCampaigns,
  },
  {
    id: 6,
    isOpen: true,
    route: '/manager/studies',
    icon: 'file_copy',
    text: strMenu.menuSectionCampaigns.ItemStudies,
  },
  {
    id: 7,
    isOpen: true,
    route: '/campaign/create',
    icon: 'add_circle',
    text: strMenu.menuSectionCampaigns.ItemNewCampaign,
  },
];

export {
  MENU_ORGANIZATION,
  MENU_CAMPAIGNS,
};