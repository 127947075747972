import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import {
  getIcon,
  validationQuestion,
} from '../../utils/common';

const ListDragItemsComponent = (props) => {
  const { t } = useTranslation();
  return (
    props.list.length > 0 ?
      (
        <DragDropContext onDragEnd={props.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => ( // eslint-disable-line
              <div
                ref={provided.innerRef}
              >
                { props.list.map((item, index) => (
                  <Draggable key={item.order} draggableId={item.order} index={index}>
                    {(provided, snapshot) => ( // eslint-disable-line
                      <div className="listItem__container">
                        <div
                          className={validationQuestion(item) ? 'listItem' : 'listItem listItem--danger'}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={props.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <div className={props.type === 'task' ? 'listItem__label listItem__label--task' : 'listItem__label listItem__label--question'} >{item.order} {props.type}</div>
                          <div className={`listItem__icon ${getIcon(item.type)}`} />
                          <div className="listItem__text">{item.title}</div>
                          <button className="listItem__edit" onClick={() => props.handleEdit(item.id)}>{t('general.buttons.edit')}</button>
                          <button className="listItem__delete ic_general_trash" onClick={e => props.handleDelete(e, item.id, props.clasification)} />
                        </div>
                        <div className="listItem__shadow" />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) :
      (
        <div className="listItem__empty">{t('campaignPreview.panels.notQuestion')}</div>
      )
  );
};

ListDragItemsComponent.propTypes = {
  type: PropTypes.string,
  onDragEnd: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  getItemStyle: PropTypes.func,
  handleEdit: PropTypes.func,
  clasification: PropTypes.string,
  handleDelete: PropTypes.func,
};

ListDragItemsComponent.defaultProps = {
  type: '',
  onDragEnd: () => {},
  list: [],
  getItemStyle: () => {},
  handleEdit: () => {},
  clasification: '',
  handleDelete: () => {},
};

export default ListDragItemsComponent;