import React from 'react';
import PropTypes from 'prop-types';
import PieHighcharts from '../Charts/PieHighchartsComponent';
import BarHighcharts from '../Charts/BarHighchartsComponent';
import NumberChartComponent from '../Charts/NumberChartComponent';
import IndicatorsChartsComponent from '../Charts/IndicatorsChartsComponent';
import DownloadCsvComponent from '../Charts/DownloadCsvComponent';
import SemiDonutHighchartsComponent from '../Charts/SemiDonutHighchartsComponent';

function TypeChart(props) {
  const {
    dataChart,
    widthChart,
    visualization,
    taskId,
  } = props;

  switch (visualization) {
    case 'pie':
      return (
        <PieHighcharts
          {...dataChart}
          visualization={visualization}
          widthChart={widthChart}
        />
      );
    case 'column':
    case 'bar':
      return (
        <BarHighcharts
          {...dataChart}
          visualization={visualization}
          widthChart={widthChart}
        />
      );
    case 'number':
      return (
        <NumberChartComponent
          value={dataChart.value}
          isMilliseconds={props.isMilliseconds}
          measure={dataChart.result_type}
          visualization={visualization}
        />
      );
    case 'number_icon':
      return (
        <IndicatorsChartsComponent {...dataChart} dataChart={dataChart.data} taskId={taskId} />
      );
    case 'table':
      return (
        <DownloadCsvComponent {...dataChart} />
      );
    case 'semi_donut':
      return (
        <SemiDonutHighchartsComponent
          {...dataChart}
          widthChart={widthChart}
          visualization={visualization}
        />
      );
    default:
      return null;
  }
}

TypeChart.propTypes = {
  dataChart: PropTypes.shape({}).isRequired,
  isMilliseconds: PropTypes.bool.isRequired,
};

export default TypeChart;

