import React from 'react';
import Proptypes from 'prop-types';
import { Typography, Box, LinearProgress, Tooltip } from '@material-ui/core';

const ProgressQuota = ({ percentage, value, total }) => (
  <Box>
    <Tooltip arrow title={`${percentage.toFixed(1)}%`}>
      <LinearProgress value={percentage} variant="determinate" color="primary" />
    </Tooltip>
    <Box display="flex" justifyContent="flex-end" pt={1}>
      <Typography variant="caption">{value} / {total}</Typography>
    </Box>
  </Box>
);

ProgressQuota.propTypes = {
  percentage: Proptypes.number.isRequired,
  value: Proptypes.number.isRequired,
  total: Proptypes.number.isRequired,
};

export default ProgressQuota;