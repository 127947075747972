import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  getIcon,
  validationQuestion,
} from '../../utils/common';

const ListDragItemsMultiComponent = props => (
  props.list.length > 0 ?
    (
      <DragDropContext
        onDragEnd={props.onDragEnd}
        onDragStart={this.onDragStart}
      >
        <Droppable
          droppableId="board"
          type="COLUMN"
          direction="horizontal"
          ignoreContainerClipping
        >
          {ParentProvided => (
            <div Ref={ParentProvided.innerRef} {...ParentProvided.droppableProps}>
              {props.list.map((item, index) => (
                <Draggable key={item.id} draggableId={index} index={index}>
                  {(provided, snapshot) => ( // eslint-disable-line
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      { /* item of task */}
                      <div
                        className="listItem__container"
                        style={props.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <div
                          className="listItem"
                          style={props.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                          {...provided.dragHandleProps}
                        >
                          <div className={props.type === 'task' ? 'listItem__label listItem__label--task' : 'listItem__label listItem__label--question'} >{item.order} {props.type}</div>
                          <div className={item.task_type !== null ?
                              `listItem__icon ${getIcon(item.task_type.name)}` :
                              `listItem__icon ${getIcon('UX_DESKTOP')}`}
                          />
                          <div className="listItem__text">{item.name}</div>
                          <div className="listItem__add">
                            <button
                              className="ButtonIcon ButtonIcon--hidden"
                              onClick={e => props.handleAdd(e, item.id, item)}
                            >
                              <div className="ButtonIcon__icon ButtonIcon__icon--hidden">+</div>
                              <div className="ButtonIcon__text ButtonIcon__text--hidden" >Add question</div>
                            </button>
                          </div>
                          <button className="listItem__edit" onClick={() => props.handleEdit(item.id)}>EDIT</button>
                          <button className="listItem__delete ic_general_trash" onClick={() => props.handleDelete(item.id)} />
                        </div>
                        <div className="listItem__shadow" />
                      </div>
                      { /* fin of task */}
                      { /* list questions */}
                      <Droppable
                        droppableId={item.id}
                        type="QUOTE"
                        ignoreContainerClipping
                        /* isDropDisabled={isDropDisabled} */
                      >
                        {(dropProvided, dropSnapshot) => (
                          <div
                            className="listItem__container--children"
                            /* style={style} */
                            style={props.getItemStyle(
                              dropSnapshot.isDraggingOver,
                              provided.draggableProps.style,
                            )}
                            /* isDropDisabled={isDropDisabled} */
                            {...dropProvided.droppableProps}
                          >
                            <div
                              ref={dropProvided.innerRef}
                            >
                              { props.list.map((question, qindex) => (
                                <Draggable
                                  key={question.id}
                                  draggableId={question.order}
                                  index={qindex}
                                >
                                  {(dragProvided, dragSnapshot) => ( // eslint-disable-line
                                    <div className="listItem__container">
                                      <div
                                        className={validationQuestion(question) ? 'listItem' : 'listItem listItem--danger'}
                                        isDragging={dragSnapshot.isDragging}
                                        ref={provided.innerRef}
                                        {...dragProvided.draggableProps}
                                        {...dragProvided.dragHandleProps}
                                      >
                                        <div className="listItem__label listItem__label--question" >{question.order} {question.type}</div>
                                        <div className={`listItem__icon ${getIcon(question.type)}`} />
                                        <div className="listItem__text">{question.title}</div>
                                        <button className="listItem__edit" onClick={() => props.handleEdit(question.id)}>EDIT</button>
                                        <button className="listItem__delete ic_general_trash" onClick={() => props.handleDelete(question.id)} />
                                      </div>
                                      <div className="listItem__shadow" />
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    ) :
    (
      <div className="listItem__empty">No exit questions yet created</div>
    )
);

ListDragItemsMultiComponent.propTypes = {
  type: PropTypes.string,
  onDragEnd: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.shape({})),
  getItemStyle: PropTypes.func,
  handleEdit: PropTypes.func,
  handleAdd: PropTypes.func,
  handleDelete: PropTypes.func,
};

ListDragItemsMultiComponent.defaultProps = {
  type: '',
  onDragEnd: () => {},
  list: [],
  getItemStyle: () => {},
  handleEdit: () => {},
  handleAdd: () => {},
  handleDelete: () => {},
};

export default ListDragItemsMultiComponent;