import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography } from '@material-ui/core';
import { getStrings } from '../../utils/i18n';

const summary = getStrings('summary', { returnObjects: true });

const PanelComponent = props => (
  <Fragment>
    {props.full ?
      (
        <Paper elevation={0} style={{ position: 'relative' }}>
          {props.summary ?
            (
              <div
                className={
                  props.collapse ?
                    'panel__content panel__colapse--content' :
                    'panel__content panel__colapse--content panel__colapse--content--top'
                }
              >
                <div className="panel__summary-text">
                  <div className="panel__summary-text--bold">{summary.campaignId} </div>{props.id}
                </div>
                <div className={
                  props.collapse ?
                    'panel__summary-title' :
                    'panel__summary-title panel__summary-title--top'
                }
                >
                  {props.title}
                </div>
                <div className="panel__summary-text">
                  <div className="panel__summary-text--bold">{summary.objective} </div>{props.objective}
                </div>
                <div className="panel__summary-text">
                  <div className="panel__summary-text--bold">{summary.description} </div>{props.description}
                </div>
                <div className="panel__summary-text">
                  <div className="panel__summary-text--bold">{summary.biometrical} </div>{props.biometrical ? summary.biometricSurvey : summary.survey}
                </div>
                <div className="panel__colapse">
                  <button className={props.collapse ? 'panel__colapse--icon ic_general_arrowUp' : 'panel__colapse--icon ic_general_arrowDown'} onClick={props.handleCollapse} />
                </div>
              </div>
            ) : props.children
          }
        </Paper>
      ) :
      (
        <Paper elevation={0}>
          <Typography variant="subtitle2" color="primary">
            {props.title}
          </Typography>
          {props.children}
          {
            props.notes && (
              <div className="panel__notes">
                {
                  props.notes.map(note => (
                    <div key={note.id} className="panel__note-content">
                      <div className="panel__note-label">{note.label}</div>
                      <div className="panel__note-text">{note.text}</div>
                    </div>
                  ))
                }
              </div>
            )
          }
        </Paper>
      )
    }
  </Fragment>
);

PanelComponent.propTypes = {
  children: PropTypes.node,
  full: PropTypes.bool,
  id: PropTypes.string,
  title: PropTypes.string,
  objective: PropTypes.string,
  description: PropTypes.string,
  biometrical: PropTypes.bool,
  collapse: PropTypes.bool,
  summary: PropTypes.bool,
  handleCollapse: PropTypes.func,
  notes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    text: PropTypes.string,
  })),
};

PanelComponent.defaultProps = {
  title: '',
  children: '',
  full: false,
  id: '',
  biometrical: false,
  objective: '',
  description: '',
  collapse: false,
  summary: false,
  handleCollapse: () => { },
  notes: [],
};

export default PanelComponent;