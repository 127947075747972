import { ApolloClient, InMemoryCache, createHttpLink,
  ApolloLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import React from 'react';

import {
  ERROR_QUERY, GET_MAIN_MENU, STATUS_DIALOG, GET_STUDY_CAMPAIGNS,
  GET_STATUS_CAMPAIGN, SHOW_SIGN_OUT,
} from './queries.gql';

const getInitialLocalStorage = key => localStorage.getItem(key);
const uri = process.env.API_URL;
const cache = new InMemoryCache({
  addTypename: false,
});

export const MyApolloProvider = ({ children }) => {
  const { getIdTokenClaims } = useAuth0();

  const errorLink = onError(({
    graphQLErrors, networkError, response, operation,
  }) => {
    if (networkError) {
      if (networkError.statusCode === 401) {
        // window.location.href = '/login';
        return;
      }
      Sentry.captureException(response.errors, {
        fingerprint: ['saveAois'],
        tags: {
          name: 'Graphql Error connection',
          component: 'Apollo client',
        },
        extra: {
          message: networkError,
          params: operation.variables,
        },
      });
      return;
    }
    if (graphQLErrors) {
      const { definitions, loc } = operation.query;
      graphQLErrors.forEach(({
        message,
        extensions,
        // eslint-disable-next-line camelcase
        validation_messages,
      }) => {
        switch (extensions.code) {
          case 'INVALID_RECORD':
            validation_messages.forEach((errorMessage) => {
              cache.writeQuery({
                query: ERROR_QUERY,
                data: {
                  error: {
                    open: true,
                    type: 'warning',
                    message: errorMessage,
                  },
                },
              });
            });
            break;
          case 'INVALID_DATABASE_OPERATION':
            if (extensions.status === 422) {
              cache.writeQuery({
                query: ERROR_QUERY,
                data: {
                  error: {
                    open: true,
                    type: 'warning',
                    message: validation_messages.map(errrorMsg => errrorMsg),
                  },
                },
              });
            }
            break;
          default:
            cache.writeQuery({
              query: ERROR_QUERY,
              error: {
                open: true,
                type: 'warning',
                message,
              },
            });
            Sentry.captureException(response.errors[0], {
              fingerprint: ['myApolloProvider'],
              tags: {
                name: `Graphql error ${operation.operationName}`,
                component: 'Apollo client',
              },
              extra: {
                message,
                params: {
                  variables: operation.variables,
                  query: {
                    source: loc.source.body.replace(/\n/g, ''),
                    type: definitions[0].kind,
                    operation: definitions[0].operation,
                    name: operation.operationName,
                  },
                },
              },
            });
            break;
        }
      });
    }
  });

  const httpLink = createHttpLink({
    uri,
    credentials: 'same-origin',
  });

  const authLink = setContext(async (_, { headers = {} }) => {
    const tokenId = await getIdTokenClaims();
    return ({
      headers: {
        ...headers,
        // eslint-disable-next-line no-underscore-dangle
        Authorization: `Bearer ${tokenId.__raw}`,
      },
    });
  });

  const link = ApolloLink.from([errorLink, authLink, httpLink]);

  cache.writeQuery({
    query: ERROR_QUERY,
    data: {
      error: {
        open: false,
        type: 'error',
        message: '',
      },
    },
  });

  cache.writeQuery({
    query: GET_MAIN_MENU,
    data: {
      mainMenu: {
        isOpen: false,
      },
    },
  });

  cache.writeQuery({
    query: STATUS_DIALOG,
    data: {
      opendialogFiltersVisualization: false,
    },
  });

  cache.writeQuery({
    query: GET_STUDY_CAMPAIGNS,
    data: {
      study: JSON.parse(getInitialLocalStorage('study')) || null,
    },
  });

  cache.writeQuery({
    query: GET_STATUS_CAMPAIGN,
    data: {
      campaign: {
        id: null,
        status: 'DRAFT',
      },
    },
  });

  cache.writeQuery({
    query: SHOW_SIGN_OUT,
    data: {
      showSignOut: true,
    },
  });

  const client = new ApolloClient({
    link,
    cache,
  });
  return (
    <ApolloProvider client={client}>
      { children }
    </ApolloProvider>
  );
};

export default MyApolloProvider;
