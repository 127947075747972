
import palette from '../../theme/palette';

const styleFonts = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '14px',
  color: palette.text.primary,
};

export default styleFonts;