import { gql } from '@apollo/client';

const GET_CAMPAIGN_ID = gql`
query allCampaigns($id: ID!){
  allCampaigns(filter: { id: $id}) {
    id
    code
    name
    objective
    description
    tasks {
      id
      name
      order
      questionnaire {
        id
        type
        questions {
          id
          type
          required
          title
          order
          open {
            id
            jumpQuestionId
          }
          multiple {
            id
            options {
              label
            }
          }
          importance {
            id
            elements
          }
          scale{
            id
            scale_data {
              type
              label
              value
            }
          }
          matrix {
            id
            columns
            rows
          }
        }
      }
      task_media {
        id
      }
      task_type {
        id
        name
      }
    }
    status {
      name
    }
    randomize_tasks
    questionnaires{
      id
      type
      questions {
        id
        type
        title
        order
        required
        open {
          id
          jumpQuestionId
        }
        multiple {
          id
          options {
            label
          }
        }
        importance {
          id
          elements
        }
        scale{
          id
          scale_data {
            type
            label
            value
          }
        }
        matrix {
          id
          columns
          rows
        }
      }
    }
  }
}
`;

const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($id: ID!){
    deleteCampaign(id: $id)
  }
`;

const CREATE_QUESTIONNAIRES = gql`
  mutation SaveQuestionnaries($questionnaire: QuestionnaireInput!){
    createQuestionnaire(questionnaire_attributes: $questionnaire){
      id,
    }
  }
`;

const ORDER_QUESTIONS = gql`
  mutation orderQuestions($id: ID!, $ids_questions: [ID!]!){
    orderQuestions(questionnaire_id: $id, questions_order: $ids_questions)
  }
`;

const UPDATE_TASK = gql`
  mutation updateTask($id: ID!, $order: Int){
    updateTask(id: $id, task: {
      order: $order
    }){
      id
    }
  } 
`;

const DELETE_QUESTION = gql`
  mutation deleteQuestion($id: ID!){
    deleteQuestion(id: $id)
  }
`;

const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($id: ID!, $campaign: CampaignInput){
      updateCampaign(id: $id, campaign: $campaign){
      id
      randomize_tasks
    }
  } 
`;

const DELETE_EXIT_QUESTIONNAIRE = gql`
  mutation deleteQuestionnaire($id: ID!){
    deleteQuestionnaire(id: $id)
  }
`;

const GET_CAMPAIGN_SYNC_STATUS = gql`
  query getCampaignSyncStatus($campaignId: ID!) {
    campaignSyncStatus(campaignId: $campaignId){
      status
      synchronizedAt
    }
  }
`;

const UPDATE_SYNC_ISSUES = gql`
mutation identifySyncIssues($campaignId: ID!) {
  identifySyncIssues(campaignId: $campaignId)
}
`;

export {
  GET_CAMPAIGN_ID,
  DELETE_CAMPAIGN,
  CREATE_QUESTIONNAIRES,
  ORDER_QUESTIONS,
  UPDATE_TASK,
  DELETE_QUESTION,
  UPDATE_CAMPAIGN,
  DELETE_EXIT_QUESTIONNAIRE,
  GET_CAMPAIGN_SYNC_STATUS,
  UPDATE_SYNC_ISSUES,
};