import palette from '../palette';

export default {
  root: {
    margin: '10px 0',
  },
  mark: {
    background: `${palette.primary.dark} !important`,
    height: '10px',
    width: '10px',
    left: '-10px',
    borderRadius: '50%',
  },
  markActive: {
    background: `${palette.disabled.main} !important`,
    '&:first-child': {
      display: 'none',
    },
  },
  valueLabel: {
    color: `${palette.primary.main} !important`,
    fontSize: '17px',
    top: '-30px',
    left: 'calc(50% - 16px)',
    width: '40px',
    height: '40px',
  },
  track: {
    background: `${palette.primary.main} !important`,
    height: '10px',
    borderRadius: '10px',
  },
  rail: {
    background: `${palette.primary.main} !important`,
    height: '10px',
    width: '101%',
    borderRadius: '15px',
  },
  thumb: {
    height: '18px',
    width: '18px',
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgba(15, 40, 169, 0.36)',
    },
    '&:active': {
      boxShadow: '0px 0px 0px 14px rgba(15, 40, 169, 0.36)',
    },
  },
};