import React from 'react';
import Highcharts from 'highcharts';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import palette from '../../../theme/palette';
import styleFonts from '../styles';

const useStyle = makeStyles({
  root: {
    width: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  chart: {
    width: '100%',
    margin: 0,
  },
});

const columnHighchart = (props) => {
  const {
    name, data, visualization, result_type: resultType, widthChart,
  } = props;
  const dataAnswer = JSON.parse(data);

  const classes = useStyle();

  const options = {
    chart: {
      type: visualization,
      className: classes.chart,
      width: widthChart > 0 ? widthChart - 25 : null,
      spacing: [5, 5, 5, 5],
    },
    title: {
      text: null,
    },
    xAxis: {
      type: 'category',
      gridLineColor: 'transparent',
      tooltipValueFormat: '{point.formattedValue}%',
      labels: {
        style: styleFonts,
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      gridLineColor: 'transparent',
      labels: {
        format: `{value} ${resultType === 'percentage' ? '%' : ''}`,
        style: styleFonts,
      },
    },
    tooltip: {
      // eslint-disable-next-line object-shorthand
      formatter: function () {
        return `${this.key}: <b>${dataAnswer[this.key]}</b>`;
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        name,
        colorByPoint: true,
        data: dataAnswer ? Object.keys(dataAnswer).map(key => ({
          name: key,
          y: dataAnswer[key],
          color: palette.primary.main,
        })) : [],
        dataLabels: {
          enabled: true,
          rotation: 0,
          color: palette.disabled.contrastText,
          align: 'center',
          // eslint-disable-next-line object-shorthand
          formatter: function () {
            return `${parseFloat(this.y).toFixed(1)}${resultType === 'percentage' ? '%' : ''}`;
          },
          y: 0,
          x: visualization === 'bar' ? 13 : 0,
          style: styleFonts,
        },
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ className: classes.root }}
      oneToOne
    />
  );
};

columnHighchart.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  widthChart: PropTypes.number.isRequired,
};

export default columnHighchart;