import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  button: props => ({
    color: theme.palette.primary.main,
    border: 'none',
    background: 'none',
    padding: '1rem',
    borderRadius: '.3rem',
    opacity: props.disabled ? '.5' : '1',
    cursor: props.disabled ? 'not-allowed' : 'pointer',
  }),
  icon: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    border: `.2rem solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    height: '1.5rem',
    width: '1.5rem',
    lineHeight: '1.5rem',
    textAlign: 'center',
    margin: '0rem 1rem 0rem 0rem;',
  },
}));

const ButtonIconComponent = (props) => {
  const clasess = useStyles({ disabled: props.disabled });
  return (
    <button
      onClick={props.handleClick}
      disabled={props.disabled}
      className={clasess.button}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box className={clasess.icon}>
          <Typography variant="body2" color="primary">+</Typography>
        </Box>
        <Typography variant="body2" color="primary">{props.label}</Typography>
      </Box>
    </button>
  );
};

ButtonIconComponent.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  handleClick: PropTypes.func,
};

ButtonIconComponent.defaultProps = {
  disabled: false,
  label: '',
  handleClick: () => {},
};

export default ButtonIconComponent;