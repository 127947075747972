import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Divider, Grid, Container, Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import CampaignSummaryContent from './components/CampaignSummaryContent';
import Icons from './components/Icons';
import CampaignInfo from './components/CampaignInfo';
import Progress from './components/Progress';
import { GET_CAMPAIGN } from './graphql';
import { GET_STATUS_CAMPAIGN } from '../../Apollo/queries.gql';


const CampaignSummary = (props) => {
  const {
    loading, error, data, client,
  } = useQuery(GET_CAMPAIGN, {
    variables: { id: Number(props.id) },
    skip: props.id === undefined,
    onCompleted: (tData) => {
      if (data !== undefined) {
        const [tCampaign] = tData.allCampaigns;
        client.writeQuery({
          query: GET_STATUS_CAMPAIGN,
          data: { campaign: { id: tCampaign.id, status: tCampaign.status.name } },
        });
      }
    },
  });

  if (loading) {
    return (
      <Container>
        <Box pt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={50} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" height={100} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }
  if (error) return `Error: ${error.message}`;
  let { campaign } = props;

  if (data !== undefined) {
    [campaign] = data.allCampaigns;
  }

  if (!data && !campaign) {
    return (
      <CampaignSummaryContent>
        <Icons isEmpty />
        <Divider />
        <CampaignInfo isEmpty />
      </CampaignSummaryContent>
    );
  }

  let progress = null;

  if (campaign !== undefined) {
    if (campaign.status.name !== 'DRAFT') progress = (<Progress id={campaign.id} />);
  }

  return (
    <CampaignSummaryContent>
      <Icons campaign={campaign} isEmpty={false} />
      <Divider />
      <CampaignInfo
        name={campaign.name}
        client={campaign.client.name}
        date={campaign.launch_date}
        study={campaign.study}
        isEmpty={false}
      />
      {progress}
    </CampaignSummaryContent>
  );
};

CampaignSummary.propTypes = {
  id: PropTypes.string,
  campaign: PropTypes.shape({}),
};

CampaignSummary.defaultProps = {
  id: undefined,
  campaign: undefined,
};

export default React.memo(
  CampaignSummary,
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
);