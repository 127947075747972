import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TitleComponent from '../../Title';

const Transition = React.forwardRef((props, ref) => (<Slide direction="up" ref={ref} {...props} />));

const DialogComponent = (props) => {
  const {
    open, icon, title, size, children, scroll,
  } = props;
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={size}
      TransitionComponent={Transition}
      scroll={scroll}
      aria-labelledby="dialog-slide-title"
      aria-describedby="dialog-slide-description"
      PaperProps={{ elevation: 0 }}
    >
      <DialogTitle id="dialog-slide-title" style={{ marginBottom: 0 }}>
        <TitleComponent icon={icon} title={title} variant="h2" />
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <DialogContentText component="div" id="dialog-slide-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.buttons}
      </DialogActions>
    </Dialog>
  );
};

DialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  scroll: PropTypes.string,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.node.isRequired,
};

DialogComponent.defaultProps = {
  scroll: 'paper',
};

export default DialogComponent;