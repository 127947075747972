import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { withRouter, NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Divider, ListItem, List, ListItemIcon, ListItemText, Grid } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import ItemMenu from './ItemMenu';
import AnalyzeMenu from './AnalyzeMenu';
import styles from './styles';
import { analyzeItems, editItems } from './itemsList';
import { GET_STATUS_CAMPAIGN } from '../../../Apollo/queries.gql';

const CampaignMenuContext = (props) => {
  const { loading, error, data } = useQuery(GET_STATUS_CAMPAIGN);
  if (error) return `Error: ${error.message}`;
  if (loading) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}><Skeleton height={50} variant="rect" /></Grid>
        <Grid item xs={12}><Skeleton height={50} variant="rect" /></Grid>
        <Grid item xs={12}><Skeleton height={50} variant="rect" /></Grid>
      </Grid>
    );
  }
  const { id, status } = data.campaign;
  const { match: { url } } = props;

  const selectedinItemMenu = () => {
    if (`/campaign/preview/${id}` === url ||
     `/summary/${id}` === url ||
     `/campaign/edit/${id}` === url ||
     url === '/campaign/create'
    ) return true;
    return false;
  };

  const selectedinItemMenuAnalyze = () => {
    if (`/profiling/${id}` === url ||
     `/general/${id}` === url ||
     `/individual-results/summary/${id}` === url ||
     `/individual-results/brand-performance/${id}` === url ||
     `/individual-results/message-performance/${id}` === url ||
     `/individual-results/engagement-storytelling/${id}` === url ||
     `/individual-results/style-format/${id}` === url ||
     `/individual-results/${id}` === url ||
     `/scenes/${id}` === url
    ) return true;
    return false;
  };

  const setPathItem = (item) => {
    if (item.title !== 'Setup') return `/${item.path}/${id}`;
    if (props.isCreate) {
      return `/${item.path.create}`;
    }
    return `/${item.path.edit}/${id}`;
  };

  return (
    <List
      component="section"
      className={props.classes.root}
    >
      {/* edit component */}
      <ItemMenu
        selected={selectedinItemMenu(id, url)}
        open={selectedinItemMenu(id, url)}
        listItemText={props.isCreate ? 'Create' : 'edit'}
      >
        <List component="div" disablePadding >
          {
            editItems.map(item => (
              <NavLink
                to={setPathItem(item)}
                className={id ? '' : props.classes.itemTaskQuestion}
                key={item.title}
              >
                <ListItem
                  button
                  // eslint-disable-next-line no-nested-ternary
                  selected={item.title !== 'Setup' ?
                    `/${item.path}/${id}` === url :
                    props.isCreate ? `/${item.path.create}/${id}` === url : `/${item.path.edit}/${id}` === url
                  }
                  className={`${props.classes.nested} ${props.classes.navLinkSecondary}`}
                >
                  <ListItemIcon className={props.classes.iconList}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    inset
                    disableTypography
                    primary={item.title}
                    className={props.classes.ListItem}
                  />
                </ListItem>
              </NavLink>
              ))
          }
        </List>
        <Divider />
      </ItemMenu>
      {
        status !== 'DRAFT' && (
          <Fragment>
            {/* track */}
            <NavLink to={`/tracker/${id}`}>
              <ListItem button selected={`/tracker/${id}` === url} className={props.classes.navLink}>
                <ListItemIcon className={props.classes.icon}>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText disableTypography primary="Track" className={props.classes.mainListItem} />
              </ListItem>
            </NavLink>
            <Divider />
            {/* A N A L Y Z E  wrapper */}
            <AnalyzeMenu
              open={selectedinItemMenuAnalyze(id, url)}
              listItemText="Analyze"
              selected={selectedinItemMenuAnalyze(id, url)}
            >
              <List component="div" disablePadding >
                {
                  analyzeItems.map(analizeItem => (
                    <NavLink to={`/${analizeItem.path}/${id}`} key={analizeItem.title}>
                      <ListItem
                        button
                        disabled={props.isCreate}
                        selected={`/${analizeItem.path}/${id}` === url}
                        className={`${props.classes.nested} ${props.classes.navLinkSecondary}`}
                      >
                        { analizeItem.icon ?
                          <ListItemIcon className={props.classes.iconList}>
                            {analizeItem.icon}
                          </ListItemIcon> :
                          <div className={props.classes.iconList} />
                        }
                        <ListItemText
                          disableTypography
                          primary={analizeItem.title}
                          className={props.classes.ListItem}
                        />
                      </ListItem>
                      <Divider />
                    </NavLink>
                  ))
                }
              </List>
            </AnalyzeMenu>
            <Divider />
          </Fragment>
        )
      }
    </List>
  );
};

CampaignMenuContext.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    icon: PropTypes.string,
    nested: PropTypes.string,
    mainListItem: PropTypes.string,
    ListItem: PropTypes.string,
    navLink: PropTypes.string,
    navLinkSecondary: PropTypes.string,
    itemTaskQuestionWrapper: PropTypes.string,
    itemTaskQuestion: PropTypes.string,
    iconList: PropTypes.string,
  }).isRequired,
  isCreate: PropTypes.bool.isRequired,
  status: PropTypes.string,
  selected: PropTypes.string.isRequired,
  match: PropTypes.shape({}).isRequired,
};

CampaignMenuContext.defaultProps = {
  status: 'DRAFT',
};

export default withRouter(withStyles(styles)(CampaignMenuContext));
