import { gql } from '@apollo/client';

const GET_CAMPAIGN = gql`
  query allCampaings($id: ID!){
    allCampaigns(filter: { id: $id }){
      id
      name
      launch_date
      biometrical_analysis
      language {
        name
        short
      }
      country {
        name
        abbreviation
      }
      status {
        name
      }
      tasks {
        task_type {
          name
        }
      }
      platform {
        name
        id
      }
      client {
        name
      }
    }
    sampleDistribution(campaign_id: $id, link_type: "production") {
      sample_size
      testers_completed
    }
  }
`;

const SAMPLE_DISTRIBUTION = gql`
  query sampleDistribution($id: ID!){
    sampleDistribution(campaign_id: $id, link_type: "production") {
      sample_size
      testers_completed
    }
  }
`;

export {
  GET_CAMPAIGN,
  SAMPLE_DISTRIBUTION,
};