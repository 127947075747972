import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Icon, Button, Box, Typography, Tooltip } from '@material-ui/core';
import Dialog from '../../components/Feedbacks/DialogActionComponent';
import useStyles from './ButtonActions.styles';

const ButtonActions = ({
  close,
  buttonText,
  icon,
  title,
  subtitle,
  content,
  actionText,
  handleMutation,
  duplicate,
}) => {
  const classes = useStyles();

  const [openValue, setOpenValue] = useState(false);
  return (
    <div>
      <Tooltip arrow title={title}>
        {
          buttonText ?
            <Button
              onClick={() => {
                setOpenValue(true);
              }}
            >
              {buttonText}
            </Button> :
            <IconButton onClick={() => setOpenValue(true)}>
              <Icon className={classes.buttonIcon}>{icon}</Icon>
            </IconButton>
        }
      </Tooltip>
      <Dialog
        open={openValue}
        title={title}
        icon={icon}
        size="sm"
        buttons={
          <>
            <Box>
              <Button
                variant="contained"
                extend="positive"
                size="small"
                className={classes.button}
                onClick={() => {
                  setOpenValue(false);
                  close();
                }}
                color="primary"
              >
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                className={`${duplicate ?
                  classes.duplicateButton :
                  classes.deleteButton} ${classes.button}`
                }
                color="primary"
                onClick={
                  (event) => {
                    event.preventDefault();
                    handleMutation();
                    setOpenValue(false);
                    close();
                  }
                }
              >
                {actionText}
              </Button>
            </Box>
          </>
        }
      >
        <Typography variant="body1">{content}</Typography>
        <Typography variant="body1">{subtitle}</Typography>
      </Dialog>
    </div>
  );
};

ButtonActions.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleMutation: PropTypes.func.isRequired,
  close: PropTypes.func,
  classes: PropTypes.shape({
    buttonIcon: PropTypes.string,
    button: PropTypes.string,
    cancelButton: PropTypes.string,
    duplicateButton: PropTypes.string,
    deleteButton: PropTypes.string,
  }),
};

ButtonActions.defaultProps = {
  close: () => {},
  classes: {},
};

export default ButtonActions;